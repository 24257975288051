import React, { useEffect, useState } from 'react';
import { Button, Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import host from '../../global.jsx';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Loader from '../Loader.jsx';

const CreateMCQSet = () => {
  const [setsData, setSetsData] = useState({});
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/fetch_all_mcqs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSetsData(response.data.sets["0"] || {});
      setIsLoading(false);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();      // eslint-disable-next-line
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSetQuestion = async (e) => {
    e.preventDefault();
    const { question, option1, option2, option3, option4, answer } = formData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }

    const setName = selectedSet || newSetName;

    if (!setName) {
      toast.error("You must select or create a set.");
      return;
    }

    if (newSetName && Object.keys(setsData).includes(newSetName)) {
      toast.error("A set with this name already exists.");
      return;
    }
    console.log(formData);
    try {
      await axios.post(`${host}/add_mcq_to_set`, formData, {
        params: {
          set_name: setName
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
      toast.success("Question Inserted");

      if (newSetName) {
        setSelectedSet(newSetName);
      }

      setFormData({
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: ''
      });
      setNewSetName('');
      fetchData();
    } catch (error) {
      toast.error("Error adding question.");
    }
  };

  const handleEditQuestion = (setName, index) => {
    setEditIndex({ setName, index });
    setEditFormData(setsData[setName][index]);
  };

  const handleSaveQuestion = async (setName, index) => {
    const { question, option1, option2, option3, option4, answer } = editFormData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }
    console.log(editFormData);
    try {
      await axios.put(`${host}/editmcq`, editFormData, {
        params: {
          set_name: setName,
          index: index
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      toast.success("Question Updated Successfully!");
      setEditIndex(null);
      fetchData();
    } catch (error) {
      toast.error("Error updating question.");
    }
  };

  const handleDeleteQuestion = async (setName, index) => {
    try {
      await axios.delete(`${host}/deletemcq`, {
        params: {
          set_name: setName,
          index: index,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      toast.success("Question Deleted Successfully!");
      fetchData();
    } catch (error) {
      toast.error("Error deleting question.");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Authenticator>
        <div className="flex flex-col md:flex-row justify-center gap-8 p-10 bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
          {/* Create Your Own Set */}
          <div className="flex flex-col w-full lg:w-[30%] h-[calc(100vh-120px)] overflow-y-auto">
            <h1 className="text-xl font-bold text-gray-800 mb-7 text-left">Create Your Own Set</h1>
            <form class="space-y-4">
              <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <label class="text-gray-600 w-full sm:w-1/3 mt-1" for="setSelect">Select a Set</label>
                <select
                  id="setSelect"
                  class="text-md border border-gray-300 rounded-full px-3 py-2 w-full sm:w-2/3 backdrop-blur-[10px] bg-white/30"
                  value={selectedSet}
                  onChange={(e) => setSelectedSet(e.target.value)}
                >
                  <option className="text-md" value="">Create New Set</option>
                  {Object.keys(setsData).map((setName) => (
                    <option key={setName} value={setName}>
                      SET {setName}
                    </option>
                  ))}
                </select>
              </div>

              <div class="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                <label class="text-gray-600 w-full sm:w-1/3 whitespace:nowrap" for="newSetName">
                  Create New
                </label>
                <input
                  id="newSetName"
                  name="newSetName"
                  class="text-md border border-gray-300 rounded-full px-3 py-2 w-full sm:w-2/3 backdrop-blur-[10px] bg-white/30"
                  placeholder="Enter New Set Name"
                  value={newSetName}
                  onChange={(e) => setNewSetName(e.target.value)}
                  disabled={!!selectedSet}
                />
              </div>
              
              <input
                name="question"
                placeholder="Enter Question"
                class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                value={formData.question}
                onChange={handleFormChange}
              />

              <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <input
                  name="option1"
                  placeholder="Enter Option 1"
                  class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                  value={formData.option1}
                  onChange={handleFormChange}
                />
                <input
                  name="option2"
                  placeholder="Enter Option 2"
                  class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                  value={formData.option2}
                  onChange={handleFormChange}
                />
              </div>

              <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <input
                  name="option3"
                  placeholder="Enter Option 3"
                  class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                  value={formData.option3}
                  onChange={handleFormChange}
                />
                <input
                  name="option4"
                  placeholder="Enter Option 4"
                  class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                  value={formData.option4}
                  onChange={handleFormChange}
                />
              </div>

              <input
                name="answer"
                placeholder="Enter Answer"
                class="text-md border border-gray-300 rounded-full w-full px-4 py-2 backdrop-blur-[10px] bg-white/30"
                value={formData.answer}
                onChange={handleFormChange}
              />

              <div class="flex justify-center">
              <Button
                onClick={handleSetQuestion}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '10px',
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(5px)',
                  borderRadius: '5px',
                  color: 'black',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  fontWeight: 'normal',
                  padding: '10px 20px',
                  fontSize: '16px',
                  width: 'auto',
                  maxWidth: '300px',
                  boxSizing: 'border-box',
                }}
              >
                Add Question
              </Button>
              </div>
            </form>
          </div>

          <div className="flex flex-col w-full lg:w-[70%] border border-gray-300 rounded-2xl p-4 h-[calc(100vh-120px)]">
          <h3 className="text-md font-semibold text-gray-700 mb-2 text-left">Questions</h3>
          <div className="overflow-y-auto flex-grow">
          {selectedSet ? (
            <div class="displayQuestions">
              {setsData[selectedSet] && (
                <div class="questionsContainer">
                 
                  {Object.entries(setsData[selectedSet]).map(([key, question], index) => (
                    <div
                      key={key}
                      class="displayedQuestions border-b border-gray-300 py-4"
                    >
                      {editIndex &&
                      editIndex.setName === selectedSet &&
                      editIndex.index === Number(key) ? (
                        <>
                          <div className="flex items-center mb-4">
                            <label className="font-medium mr-4 text-gray-700">
                              {editIndex.index + 1}.
                            </label>
                            <input
                              name="question"
                              placeholder="Enter Question"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2 font-medium"
                              value={editFormData.question}
                              onChange={handleEditFormChange}
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="font-medium text-gray-700 mr-4">a</label>
                            <input
                              name="option1"
                              placeholder="Enter Option A"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2"
                              value={editFormData.option1}
                              onChange={handleEditFormChange}
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="font-medium text-gray-700 mr-4">b</label>
                            <input
                              name="option2"
                              placeholder="Enter Option B"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2"
                              value={editFormData.option2}
                              onChange={handleEditFormChange}
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="font-medium text-gray-700 mr-4">c</label>
                            <input
                              name="option3"
                              placeholder="Enter Option C"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2"
                              value={editFormData.option3}
                              onChange={handleEditFormChange}
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="font-medium text-gray-700 mr-4">d</label>
                            <input
                              name="option4"
                              placeholder="Enter Option D"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2"
                              value={editFormData.option4}
                              onChange={handleEditFormChange}
                            />
                          </div>

                          <div className="flex items-center mb-4">
                            <label className="font-medium text-gray-700 mr-4">Answer</label>
                            <input
                              name="answer"
                              placeholder="Enter Answer"
                              className="text-gray-700 border border-gray-300 rounded w-full px-4 py-2"
                              value={editFormData.answer}
                              onChange={handleEditFormChange}
                            />
                          </div>
                          <div class="flex space-x-4 mt-2">
                            <button
                              type="button"
                              onClick={() =>
                                handleSaveQuestion(selectedSet, Number(key))
                              }
                              className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-500"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={() => setEditIndex(null)}
                              className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500"
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p class="displayedQuestion text-gray-700 font-medium">
                            <div className="text-base font-medium text-gray-700 text-left mb-2"
                              style={{
                                fontFamily: "Poppins",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                              }}
                            >
                              {Number(index) + 1}.{" "}
                              {setsData[selectedSet][key].question}
                            </div>
                          </p>
                          <ul class="list-none text-gray-700 text-base">
                            <li>a. {setsData[selectedSet][key].option1}</li>
                            <li>b. {setsData[selectedSet][key].option2}</li>
                            <li>c. {setsData[selectedSet][key].option3}</li>
                            <li>d. {setsData[selectedSet][key].option4}</li>
                          </ul>
                          <p class="text-gray-700 mt-2">
                            Correct Answer:{" "}
                            <span class="font-medium">
                              {setsData[selectedSet][key].answer}
                            </span>
                          </p>
                          <div class="flex space-x-4 mt-2">
                            <button
                              type="button"
                              class="border border-yellow-300 editBtn py-1 px-4 rounded text-gray-700 mb-2"
                              onClick={() =>
                                handleEditQuestion(selectedSet, Number(key))
                              }
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              class="border border-red-300 deleteBtn py-1 px-4 rounded text-gray-700 mb-2"
                              onClick={() =>
                                handleDeleteQuestion(selectedSet, Number(key))
                              }
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div> 
              {/* <img
                  className="mx-auto w-1/2 h-auto"
                  src="/fetch-question.jpg"
                  alt="Question"
                /> */}
                <p className="mt-10 text-center text-xs text-gray-600">
                  Feels empty! Select or Create a Set for questions to appear.
                </p>
            </div>
             
          )}
          </div>
          </div>
        </div>
      </Authenticator>
    </>
  );
};

export default CreateMCQSet;
