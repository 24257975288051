import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import host from '../global';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  console.log(token);
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [email, setEmail] = useState('');
  const [isRegistration, setIsRegistration] = useState(false);

  useEffect(() => {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      console.log('Token data:', tokenData); // Add this
      setEmail(tokenData.sub);
      setIsRegistration(tokenData.type === 'registration');
    } catch (err) {
      console.error('Token parsing error:', err); // Add this
      setError('Invalid token');
    }
  }, [token]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (formData.newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const endpoint = isRegistration 
        ? `${host}/api/candidate/complete-registration`
        : `${host}/api/candidate/reset-password`;

      console.log('Token being sent:', token);
      const response = await axios.post(endpoint, {
        token: token,
        new_password: formData.newPassword,
        confirm_password: formData.confirmPassword,
      });

      setSuccess(isRegistration 
        ? 'Registration successful! Redirecting to login...'
        : 'Password reset successful! Redirecting to login...'
      );
      setTimeout(() => navigate('/candidate-login'), 2000);
    } catch (err) {
      setError(err.response?.data?.detail || 
        (isRegistration ? 'Error completing registration' : 'Error resetting password'));
    }
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] p-8">
      <div className="bg-white rounded-2xl shadow-xl p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-[#3ECFDA] to-[#0952DA]">
          {isRegistration ? 'Complete Registration' : 'Reset Password'}
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-600 mb-2">Email</label>
            <input
              type="email"
              value={email}
              disabled
              className="w-full p-3 border rounded-full bg-gray-50"
            />
          </div>

          <div>
            <label className="block text-gray-600 mb-2">
              {isRegistration ? 'Set Password' : 'New Password'}
            </label>
            <input
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
              placeholder="Enter password"
              className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <p className="text-xs text-gray-500 mt-1">
              Password must be at least 8 characters long
            </p>
          </div>

          <div>
            <label className="block text-gray-600 mb-2">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm password"
              className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {error && <div className="text-red-500 text-sm">{error}</div>}
          {success && <div className="text-green-500 text-sm">{success}</div>}

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white p-3 rounded-full hover:opacity-90 transition-opacity font-semibold"
          >
            {isRegistration ? 'Complete Registration' : 'Reset Password'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;