import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {CheckCircleIcon,LightbulbIcon,SparklesIcon,ArrowRightIcon,PlusIcon,ClockIcon,Minus,SettingsIcon,X,Users,MapPin,AlertCircle,} from "lucide-react";
import host from "../../global";
import { toast } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import AIProcessingLoader from "../AIProcessingLoader";
import Loader from "../Loader";
import JobDescriptionLoader from "../JobDescriptionLoader";
import { ChevronDown, ChevronUp } from 'lucide-react';

const ItemType = "ITEM";

function CreateJD() {
  const [isAI, setIsAI] = useState(false);
  const [aiSuggestedSkills, setAiSuggestedSkills] = useState([]);
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [optionalSkills, setOptionalSkills] = useState([]);
  const [jobRole, setJobRole] = useState("");
  const [experience, setExperience] = useState(0);
  const [minExp, setMinExp] = useState(0);
  const [maxExp, setMaxExp] = useState(1);
  const [responsibilities, setResponsibilities] = useState("");
  const [generatedJD, setGeneratedJD] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [isAnalyzed, setIsAnalyzed] = useState(false);
  const [isFineTuned, setIsFineTuned] = useState(false);
  const [customRequiredSkill, setCustomRequiredSkill] = useState("");
  const [customOptionalSkill, setCustomOptionalSkill] = useState("");
  const [showInterviewConfig, setShowInterviewConfig] = useState(false);
  const [interview_timelimit, setInterviewtimelimit] = useState(0);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [CodingJudgingCriteria, setCodingJudgingCriteria] = useState("NA");
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedCodingSet, setSelectedCodingSet] = useState("");
  const [sets, setSets] = useState([]);
  const [codingSets, setCodingSets] = useState([]);
  const [isCustomQuestionsIncluded, setIsCustomQuestionsIncluded] =useState(false);
  const [customQuestions, setCustomQuestions] = useState("");
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const [isAddSkillOpen, setIsAddSkillOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillsLoading, SetSkillsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [candidatesRequired,setCandidatesRequired] = useState(5);
  const [searchLocations, setSearchLocations] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [sections, setSections] = useState({
    sourcing: {
      isExpanded: false,
      isChecked: false,
      content: "Configure your candidate sourcing preferences, channels, and automation settings."
    },
    screening: {
      isExpanded: false,
      isChecked: false,
      content: "Set up your screening criteria, questionnaires, and filtering parameters."
    },
    assessment: {
      isExpanded: false,
      isChecked: false,
      content: "Customize your assessment methods, scoring criteria, and evaluation process."
    }
  });
const [noticePeriod, setNoticePeriod] = useState(0); // Default: Immediate
const [noticeStrictness, setNoticeStrictness] = useState('strict'); // Default: Strict
const [maxCTC, setMaxCTC] = useState(''); // No default value for max CTC
const [ctcStrictness, setCtcStrictness] = useState('strict'); // Default: Strict
  const candidateRanges = [
    { value: "5", label: "5 candidates" },
    { value: "25", label: "25 candidates" },
    { value: "50", label: "50 candidates" },
    { value: "75", label: "75 candidates" },
    { value: "100", label: "100 candidates" },
    { value: "150", label: "150 candidates" },
    { value: "200", label: "200 candidates" },
    { value: "400", label: "400 candidates" },
  ];
  const [companyName, setCompanyName] = useState('');
const [companyDescription, setCompanyDescription] = useState('');
const [jobType, setJobType] = useState('fulltime');
const [workLocationType, setWorkLocationType] = useState('office');
const [workLocationInput, setWorkLocationInput] = useState('');
const [workLocations, setWorkLocations] = useState([]);

const handleWorkLocationInputChange = (e) => {
  setWorkLocationInput(e.target.value);
};

const handleWorkLocationAdd = (e) => {
  if (e.key === 'Enter' && workLocationInput.trim()) {
    setWorkLocations((prev) => [...prev, workLocationInput.trim()]);
    setWorkLocationInput('');
  }
};

const handleWorkLocationRemove = (location) => {
  setWorkLocations((prev) => prev.filter((loc) => loc !== location));
};

  const toggleSection = (sectionKey) => {
    if (!sections[sectionKey].isChecked) return;
    
    setSections(prev => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        isExpanded: !prev[sectionKey].isExpanded
      }
    }));
  };

  const handleCheckboxChange = (sectionKey) => {
    setSections(prev => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        isChecked: !prev[sectionKey].isChecked,
        isExpanded: !prev[sectionKey].isChecked
      }
    }));
  };

  const DraggableItem = ({ item, sourceBoxId }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: { item, sourceBoxId },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });
  
    return (
      <div
        ref={drag}
        className={`cursor-pointer px-4 py-2 bg-gradient-to-br from-blue-400/80 to-blue-600/80 text-white rounded-full text-sm inline-flex items-center space-x-2 transition-all duration-300 ${
          isDragging ? "opacity-50 scale-95" : "opacity-100 hover:scale-105"
        }`}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <span>{item}</span>
        {sourceBoxId !== "aiSuggestedSkills" && (
          <button
            onClick={(e) => (e.stopPropagation(), deleteItem(item, sourceBoxId))}
            className="ml-2 group relative"
          >
            <div className="absolute inset-0 bg-red-500/20 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"></div>
            <X
              className="relative z-10 text-red-500 group-hover:text-red-700 transition-transform group-hover:scale-110"
              strokeWidth={2.5}
            />
          </button>
        )}
      </div>
    );
  };  

  const DraggableBox = ({id,name,items,handleDrop,bgColor = "bg-blue-50",textColor = "text-blue-600",icon: Icon = CheckCircleIcon,}) => {
    const [{ canDrop }, drop] = useDrop({
      accept: ItemType,
      drop: (draggedItem, monitor) =>
        id !== "aiSuggestedSkills" &&
        handleDrop(draggedItem, id, calculateTargetIndex(monitor.getClientOffset())),
      canDrop: () => id !== "aiSuggestedSkills",
    });
  
    const calculateTargetIndex = (clientOffset) => {
      if (!clientOffset) return items.length;
      const bounds = document.getElementById(id).getBoundingClientRect();
      const offsetY = clientOffset.y - bounds.top;
      return items.reduce(
        (acc, _, i) => (offsetY <= 30 * (i + 1) ? acc : i + 1),
        0
      );
    };
  
    return (
      <div
        id={id}
        ref={drop}
        className={`border-2 border-dashed rounded-xl p-4 transition-all duration-300 ${bgColor} ${textColor} ${
          canDrop ? "border-blue-400 bg-blue-100 scale-101" : "border-gray-200"
        }`}
        style={{ width: "100%", minHeight: "150px", maxHeight: "200px" }}
      >
        <div className="flex items-center space-x-3 mb-3">
          <Icon className="w-6 h-6" />
          <h3 className="font-semibold text-lg">{name}</h3>
        </div>
        <div className="overflow-y-auto" style={{ maxHeight: "85px" }}>
          {items.length === 0 && name !== "AI Suggested Skills" ? (
            <p className="text-gray-400 italic">Drop skills here</p>
          ) : (
            <ul className="flex flex-wrap gap-[5px]">
              {items.map((item, index) => (
                <li key={index} className="flex-shrink-0 p-[1px]">
                  <DraggableItem item={item} index={index} sourceBoxId={id} deleteItem={deleteItem} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };
  
  const deleteItem = (item, sourceBoxId) => {
    if (sourceBoxId === "required") {
      setRequiredSkills((prevSkills) => prevSkills.filter((skill) => skill !== item));
    } else {
      setOptionalSkills((prevSkills) => prevSkills.filter((skill) => skill !== item));
    }
  };

  useEffect(() => {
    const fetchCombinedData = async () => {
      try {
        const response = await fetch(`${host}/orgadmin/get_jd_data`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSets(data.set_names);
        setCodingSets(Object.keys(data.coding_sets));
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        setIsLoading(false);
      }
    };

    fetchCombinedData();
  }, [accessToken]);

  const handleDrop = (draggedItem, targetBoxId, targetIndex) => {
    const removeItemFromSourceBox = (sourceBoxId, item) => {
      if (sourceBoxId === "required") {
        setRequiredSkills((prev) => prev.filter((skill) => skill !== item));
      } else if (sourceBoxId === "optional") {
        setOptionalSkills((prev) => prev.filter((skill) => skill !== item));
      } else if (sourceBoxId === "aiSuggestedSkills") {
        setAiSuggestedSkills((prev) => prev.filter((skill) => skill !== item));
      }
    };

    const addItemToTargetBox = (targetBoxId, item, targetIndex) => {
      if (targetBoxId === "required") {
        setRequiredSkills((prev) => {
          const updated = [...prev];
          updated.splice(targetIndex, 0, item);
          return updated;
        });
      } else if (targetBoxId === "optional") {
        setOptionalSkills((prev) => {
          const updated = [...prev];
          updated.splice(targetIndex, 0, item);
          return updated;
        });
      } else if (targetBoxId === "aiSuggestedSkills") {
        setAiSuggestedSkills((prev) => {
          const updated = [...prev];
          updated.splice(targetIndex, 0, item);
          return updated;
        });
      }
    };

    // If dragged within the same box and reordering:
    if (draggedItem.sourceBoxId === targetBoxId) {
      removeItemFromSourceBox(targetBoxId, draggedItem.item);
      addItemToTargetBox(targetBoxId, draggedItem.item, targetIndex);
      return;
    }

    // Moving items between boxes:
    removeItemFromSourceBox(draggedItem.sourceBoxId, draggedItem.item);
    if (targetBoxId === "required" && requiredSkills.includes(draggedItem.item))
      return;
    if (targetBoxId === "optional" && optionalSkills.includes(draggedItem.item))
      return;
    if (
      targetBoxId === "aiSuggestedSkills" &&
      aiSuggestedSkills.includes(draggedItem.item)
    )
      return;

    addItemToTargetBox(targetBoxId, draggedItem.item, targetIndex);
  };

  const handleJobRoleChange = (event) => {
    setJobRole(event.target.value);
  };

  const handleExperienceChange = (event) => {
    setExperience(parseInt((minExp + maxExp)/2));
  };

  const handleResponsibilitiesChange = (event) => {
    setResponsibilities(event.target.value);
  };

  const handleLocationAdd = (e) => {
    if (e.key === 'Enter' && searchInput.trim() !== '') {
      setSearchLocations((prevLocations) => [
        ...prevLocations,
        searchInput.trim(),
      ]);
      setSearchInput(''); // Reset input field after adding
    }
  };

  // Handle input change
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // Handle removing a location
  const handleLocationRemove = (location) => {
    setSearchLocations((prevLocations) =>
      prevLocations.filter((loc) => loc !== location)
    );
  };

  const handleGenerateJD = async () => {
    if (requiredSkills.length < 4) {
      toast.warn("Please add atleast 5 required skills");
      return;
    }
    setIsLoading2(true);
    const skills_list = {
      Hard: [],
      Medium: [],
      Easy: [],
    };

    requiredSkills.forEach((skill) => {
      if (experience < 4) {
        skills_list.Medium.push(skill);
      } else {
        skills_list.Hard.push(skill);
      }
    });

    optionalSkills.forEach((skill) => {
      if (experience < 4) {
        skills_list.Easy.push(skill);
      } else {
        skills_list.Medium.push(skill);
      }
    });

    const jd = `jobrole: ${jobRole}
                MinExperience: ${minExp},
                MaxExperience: ${maxExp}
                SkillsRequired:${JSON.stringify(skills_list)}
               `;

    const dataToSend = { description: jd };
    console.log(dataToSend)
    try {
      toast.success("Job description successfully sent for fine-tuning!");
      const response = await fetch(`${host}/orgadmin/fineTuneJD`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      console.log(result.fineTunedJD);
      setGeneratedJD(result.fineTunedJD);
      setIsFineTuned(true);
      if (!response.ok) {
        toast.error(
          result.message || "Something went wrong with the fine-tuning process."
        );
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      console.error("Error connecting to the server:", error);
      toast.error("Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading2(false);
    }
  };

  const handleAnalyze = async () => {
    if (isAI === "false" && responsibilities.trim() === "") {
      toast.warn("Please describe the role and responsibilities!");
      return;
    }
    console.log(typeof experience);
    if (jobRole === "" || experience === "") {
      toast.warn("Please add Position and Experience");
      return;
    }
    SetSkillsLoading(true);
    const dataToSend = {
      jobRole,
      minExp,
      maxExp,
      responsibilities: responsibilities.trim() === "" ? "Not Provided" : responsibilities,
    };
    console.log(dataToSend)
    try {
      const response = await axios.post(`${host}/analyze`, {
          jobRole: jobRole,
          minExp: minExp,
          maxExp: maxExp,
          responsibilities: responsibilities.trim() === "" ? "Not Provided" : responsibilities,},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        });      
        setAiSuggestedSkills(response.data.essential_skills);
        setRequiredSkills(response.data.skills_mentioned_in_JD);
        setIsAnalyzed(true);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error sending data to backend:", error);
      toast.error("Failed to connect to the server. Please try again.");
    } finally {
      SetSkillsLoading(false);
    }
  };

  const handleIncludeMCQ = () => {
    if (isMCQIncluded) {
      setIsMCQIncluded(false);
      setSelectedSet("NA");
      setMCQTimeLimit(0);
    } else {
      setIsMCQIncluded(true);
    }
  };

  const handleIncludeCoding = () => {
    if (isCodingIncluded) {
      setIsCodingIncluded(false);
      setSelectedCodingSet("NA");
      setCodingJudgingCriteria("NA");
      setCodingTimeLimit(0);
    } else {
      setIsCodingIncluded(true);
    }
  };

  const handleQuestionChange = (index, newValue) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[index] = newValue;
    setEditedQuestions(updatedQuestions);

    const updatedOriginalQuestions = [...questions];
    updatedOriginalQuestions[index] = newValue;
    setQuestions(updatedOriginalQuestions);
  };

  const handleAddSkills = () => {
    if (customRequiredSkill) {
      if (experience > 4) {
        generatedJD.Required_Skills.Hard.push(customRequiredSkill);
      } else {
        generatedJD.Required_Skills.Medium.push(customRequiredSkill);
      }
      setCustomRequiredSkill("");
    }

    if (customOptionalSkill) {
      if (experience > 4) {
        generatedJD.Required_Skills.Medium.push(customOptionalSkill);
      } else {
        generatedJD.Required_Skills.Easy.push(customOptionalSkill);
      }
      setCustomOptionalSkill("");
    }
  };

  const handleSaveCustomQuestions = async () => {
    if(customQuestions.trim() === ''){
      toast.warn("Please enter Custom Questions!")
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${host}/upload-custom-questions/`,
        {
          questions: customQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.message);
      setQuestions(response.data.questions);
      setEditedQuestions(response.data.questions);
    } catch (error) {
      console.error("Error saving custom questions:", error);
      alert("Failed to save custom questions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMode = () => {
    setIsAnalyzed(false);
    setAiSuggestedSkills([]);
    setRequiredSkills([]);
    setOptionalSkills([]);
    setIsAI(!isAI);
  };
  const handleUpdateJD = async () => {
    if(!sections.assessment.isChecked && !sections.sourcing.isChecked && !sections.screening.isChecked){
      toast.warn("Please select atleast one service!")
      return;
    }
    if (sections.assessment.isChecked) {
    if (interview_timelimit < 5) {
      toast.warn("Interview Should be of Atleast 5 mins!");
      return;
    }

    if (isCustomQuestionsIncluded && customQuestions.trim() === "") {
      toast.warn("Custom Questions cannot be Empty!");
      return;
    }
    if (isMCQIncluded) {
      if (selectedSet === "") {
        toast.warn("Please select a set for the MCQ tests.");
        return;
      }
      if (MCQTimeLimit < 5) {
        toast.warn("MCQ tests should be at least 5 minutes long.");
        return;
      }
    }
    if (isCodingIncluded) {
      if (selectedCodingSet === "") {
        toast.warn("Please select a set for the Coding tests.");
        return;
      }
      if (CodingTimeLimit < 10) {
        toast.warn("Coding tests should be at least 10 minutes long.");
        return;
      }
      if (CodingJudgingCriteria === "") {
        toast.warn("Select a Judging Criteria for Coding");
        return;
      }
    }
    setQuestions([...editedQuestions]);
  }
  if(!sections.sourcing.isChecked){
      setCandidatesRequired(0);
      setSearchLocations([]);
  }else{
      if(candidatesRequired <= 0){
        toast.warn("Please specify candidates required from sourcing!")
        return;
      }
      if(searchLocations.length == 0){
        toast.warn("Please specify at least one location to search candidates!")
        return;
      }
  }
  if(sections.screening.isChecked){
    if(companyName.trim() === ''){
      toast.warn("Please enter Company Name")
      return;
    }
    if(companyDescription.trim() === ''){
      toast.warn("Please enter a short description about Company")
      return;
    }
    if(workLocations.length ==0 && workLocationType !== "remote"){
      toast.warn("Please enter atleast 1 Work Location")
      return;
    }
    if(maxCTC <= 0){
      toast.warn("Please Enter the Max CTC")
    }
  }else{
    setCompanyName('');
    setCompanyDescription('');
    setWorkLocations([]);
    setMaxCTC(0);
  }
    const dataToSend = {
      description: {
        jobrole: jobRole,
        minExp: parseInt(minExp),
        maxExp: parseInt(maxExp),
        Required_Skills: {
          Easy: generatedJD.Required_Skills?.Easy || [],
          Medium: generatedJD.Required_Skills?.Medium || [],
          Hard: generatedJD.Required_Skills?.Hard || [],
        },
        GoodtoHave: generatedJD.GoodtoHave || [],
        Tools_and_Technologies: generatedJD["Tools & Technologies"] || [],
        Roles_and_Responsibilities : generatedJD["Roles & Responsibilities"] || [],
        selectedInterviewType: "Technical Interview",
        interview_timelimit: parseInt(interview_timelimit ),
        selectedSet: selectedSet || "NA",
        MCQTimeLimit: parseInt(MCQTimeLimit ),
        selectedCodingSet: selectedCodingSet || "NA",
        CodingTimeLimit: parseInt(CodingTimeLimit ),
        CodingJudgingCriteria: CodingJudgingCriteria || "NA",
        customQuestions: questions || [],
        selectedServices: {
          sourcing : sections.sourcing.isChecked,
          screening: sections.screening.isChecked,
          assessment: sections.assessment.isChecked
        },
        jobType:jobType,
        companyName:companyName,
        companyDescription:companyDescription,
        candidatesRequired:parseInt(candidatesRequired),
        searchLocations : searchLocations,
        workLocationType:workLocationType,
        workLocations: workLocationType === "remote" ? "Remote" : workLocations,
        noticePeriod:parseInt(noticePeriod),
        noticeStrictness:noticeStrictness,
        maxCTC:parseInt(maxCTC),
        ctcStrictness:ctcStrictness
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch(`${host}/store_final_jd`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      if (!response.ok) {
        const errorMessage = result.message || (await response.text());
        console.error(
          "Error updating JD:",
          errorMessage,
          "Status Code:",
          response.status
        );

        alert(
          errorMessage ||
            "Something went wrong while updating the job description."
        );
      } else {
        console.log("Success response:", result);
        toast.success("Job description updated successfully.");
        setTimeout(() => {
          window.location.reload();
          navigate(`/org-admin/created-jds`);
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error connecting to the server:", error);
      toast.error("Failed to connect to the server. Please try again.");
    } finally {
      setIsLoading(false);
    }

  };

  if (isLoading) {
    return (
      <Loader/>
    )};
  if (isSkillsLoading) {
    return (
      <AIProcessingLoader/>
    )};
 if (isLoading2) {
    return (
      <JobDescriptionLoader/>
    )};

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-white h-full w-full p-8 space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold text-blue-600 flex items-center space-x-3">
            <span>Create Job Vacancy</span>
          </h1>
          {!isFineTuned && !isAnalyzed && (
            <div className={`relative w-[261px] h-12 rounded-full cursor-pointer transition-all duration-500
              ${isAI ? "bg-violet-300 " : "bg-blue-300"}`}
              onClick={toggleMode}>
              <div className={`absolute top-1 left-1 h-10 w-[128px] bg-white rounded-full shadow-md flex items-center justify-center 
                text-sm font-semibold transition-transform duration-500 ${
                  isAI ? "translate-x-[126px]" : ""}`}>
                {isAI ? "Add Custom JD" : "Generate with AI"}
              </div>
            </div>
          )}
          {isAnalyzed && !isFineTuned && (
            <button className="flex items-center space-x-2 bg-gradient-to-r from-green-500 to-green-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform w-full sm:w-auto"
              onClick={handleGenerateJD}>
              <ArrowRightIcon className="w-5 h-5" />
              <span>Generate JD</span>
            </button>
          )}
        </div>
        <div className="flex justify-between gap-10 mt-0">
          {!isFineTuned ? (
            <>
              {!isAnalyzed && (
                <div className={`space-y-6 ${isAnalyzed ? "w-[30%]" : "w-full"}`}>
                  <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
                    <div className="space-y-4 w-3/5">
                      <label className="block text-blue-600 font-semibold">
                        Job Role
                      </label>
                      <input
                        type="text"
                        placeholder="e.g., Senior Software Engineer"
                        className="w-full px-4 py-3 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                        value={jobRole}
                        onChange={handleJobRoleChange}
                      />
                    </div>
                    <div className="space-y-4 w-1/5">
                      <label className="block text-blue-600 font-semibold">
                        Min Exp
                      </label>
                      <input
                        type="number"
                        placeholder="Years of experience"
                        className="w-full px-4 py-3 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                        value={minExp}
                        onChange={(event) => {setMinExp(parseInt(event.target.value));
                          handleExperienceChange();
                          }}
                      />
                    </div>
                    <div className="space-y-4 w-1/5">
                      <label className="block text-blue-600 font-semibold">
                        Max Exp
                      </label>
                      <input
                        type="number"
                        placeholder="Years of experience"
                        className="w-full px-4 py-3 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                        value={maxExp}
                        onChange={(event) => {
                          const newMaxExp = parseInt(event.target.value);
                          if (newMaxExp > minExp) {
                            setMaxExp(newMaxExp);
                            handleExperienceChange();
                          } else {
                            toast.warn("Maximum experience must be greater than minimum experience.");
                          }
                        }}                        
                      />
                    </div>
                  </div>
                  {isAI && (
                    <div className="space-y-4">
                      <label className="block text-blue-600 font-semibold">
                        Roles and Responsibility
                      </label>
                      <textarea
                        placeholder="Describe the role and responsibilities..."
                        className="w-full h-40 px-4 py-3 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                        value={responsibilities}
                        onChange={handleResponsibilitiesChange}
                      ></textarea>
                    </div>
                  )}
                  <div className="flex flex-wrap space-x-4 justify-center gap-4">
                    <button className="flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform w-full sm:w-auto"
                      onClick={handleAnalyze} >
                      <LightbulbIcon className="w-5 h-5" />
                      <span>Analyze</span>
                    </button>
                  </div>
                </div>
              )}
              <div className={`space-y-6 w-full`}>
                {aiSuggestedSkills.length > 0 && (
                  <DraggableBox
                    id="aiSuggestedSkills"
                    name="AI Suggested Skills"
                    items={aiSuggestedSkills}
                    bgColor="bg-purple-50"
                    textColor="text-purple-600"
                    icon={SparklesIcon}
                    setItems={setAiSuggestedSkills}
                    handleDrop={handleDrop}
                  />
                )}
                <DraggableBox
                  id="required"
                  name="Required Skills"
                  items={requiredSkills}
                  setItems={setRequiredSkills}
                  handleDrop={handleDrop}
                />
                <DraggableBox
                  id="optional"
                  name="Optional Skills"
                  items={optionalSkills}
                  setItems={setOptionalSkills}
                  handleDrop={handleDrop}
                  icon={LightbulbIcon}
                  bgColor="bg-green-50"
                  textColor="text-green-600"
                />
              </div>
            </>
          ) : (
            <div className="flex space-x-6 w-full gap-5 p-5">
              <div className="space-y-6 w-[50%]">
                <div className="space-y-4">
                  <div className="flex items-center space-x-3 mb-3">
                    <SparklesIcon className="w-6 h-6 text-purple-600" />
                    <h3 className="text-lg font-semibold text-purple-800">
                      Generated Job Description
                    </h3>
                  </div>
                  <pre
                    className="font-sans text-md whitespace-pre-wrap break-words p-6 bg-purple-50 border border-purple-200/50 rounded-2xl max-h-[500px] overflow-y-auto shadow-inner"
                  >
                    {`Position: ${jobRole}\nExperience Range: ${minExp} - ${maxExp} years \n\nRequired Skills:\n${
                      experience < 4
                        ? generatedJD.Required_Skills?.Medium?.length
                          ? generatedJD.Required_Skills.Medium.map((skill, i) => `${i + 1}. ${skill}`).join("\n")
                          : "- None"
                        : generatedJD.Required_Skills?.Hard?.length
                        ? generatedJD.Required_Skills.Hard.map((skill, i) => `${i + 1}. ${skill}`).join("\n")
                        : "- None"
                    }\n\nOptional Skills:\n${
                      experience < 4
                        ? generatedJD.Required_Skills?.Easy?.join(", ") || "None"
                        : generatedJD.Required_Skills?.Medium?.join(", ") || "None"
                    }\n\n${
                      generatedJD["Roles & Responsibilities"]?.length
                        ? `Roles & Responsibilities:\n${generatedJD["Roles & Responsibilities"].map((item, i) => `${i + 1}. ${item}`).join("\n")}`
                        : ""
                    }\n\n${
                      generatedJD.GoodtoHave?.length
                        ? `Good to Have:\n  ${generatedJD.GoodtoHave.join(", ")}\n\n`
                        : ""
                    }${
                      generatedJD["Tools & Technologies"]?.length
                        ? `Tools & Technologies:\n  ${generatedJD["Tools & Technologies"].join(", ")}\n\n`
                        : ""
                    }`}
                  </pre>
                </div>
              </div>
              {/* Right Side: Add New Skills and Interview Configuration */}
              <div className="space-y-6 w-[50%]">
                {!showInterviewConfig ? (
                  <>
                    <div className="bg-blue-50 rounded-2xl p-4 mt-10">
                      <div
                        className="flex items-center space-x-3 mb-3 cursor-pointer"
                        onClick={() => setIsAddSkillOpen(!isAddSkillOpen)}
                      >
                        {isAddSkillOpen ? (
                          <Minus className="w-6 h-6 text-blue-600" />
                        ) : (
                          <PlusIcon className="w-6 h-6 text-blue-600" />
                        )}
                        <h3 className="text-lg font-semibold text-blue-800">
                          {isAddSkillOpen ? "Close Add Skills" : "Add New Skills"}
                        </h3>
                      </div>
                      {isAddSkillOpen && (
                        <div className="flex justify-between gap-2">
                          <input
                            type="text"
                            placeholder="Add Required Skill"
                            value={customRequiredSkill}
                            onChange={(e) => setCustomRequiredSkill(e.target.value)}
                            className="w-full px-3 py-2 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                          />
                          <input
                            type="text"
                            placeholder="Add Optional Skill"
                            value={customOptionalSkill}
                            onChange={(e) => setCustomOptionalSkill(e.target.value)}
                            className="w-full px-3 py-2 rounded-xl border-2 border-blue-200 focus:border-blue-500 transition"
                          />
                          <button
                            onClick={handleAddSkills}
                            className="w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform flex items-center justify-center space-x-2"
                          >
                            <span>Add Skill</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => setShowInterviewConfig(!showInterviewConfig)}
                      className="w-full bg-gradient-to-r from-green-500 to-green-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform flex items-center justify-center space-x-2"
                    >
                      <SettingsIcon className="w-5 h-5" />
                      <span>Finalize</span>
                    </button>
                  </>
                ) : (
                  <div className="space-y-4">
                    {/* <div className="w-full max-w-md mx-auto space-y-4"> */}
                    <div className="flex items-center space-x-3 mb-3">
          <ClockIcon className="w-6 h-6 text-purple-600" />
          <h3 className="text-lg font-semibold text-purple-800">
            Select the Services you want to Use -
          </h3>
        </div>
      {/* Candidate Sourcing Section */}
      <div className="border rounded-lg shadow-sm w-full">
        <div className="flex items-center justify-between p-2">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={sections.sourcing.isChecked}
              onChange={() => handleCheckboxChange('sourcing')}
              className="rounded"
            />
            <span className="font-medium">Candidate Sourcing</span>
          </label>
          <button
            onClick={() => toggleSection('sourcing')}
            className={`p-1 rounded transition-colors ${
              sections.sourcing.isChecked
                ? 'hover:bg-gray-100 text-gray-500'
                : 'text-gray-300 cursor-not-allowed'
            }`}
            disabled={!sections.sourcing.isChecked}
          >
            {sections.sourcing.isExpanded ? (
              <ChevronUp className="h-5 w-5" />
            ) : (
              <ChevronDown className="h-5 w-5" />
            )}
          </button>
        </div>

        {sections.sourcing.isExpanded && (
          <div className="p-4 flex gap-2 bg-purple-50 border border-purple-200/50 shadow-inner p-6">
            <div className="w-1/3">
              <label className="flex items-center text-sm font-medium text-gray-700">
                <Users className="w-4 h-4 text-blue-600 mr-2 mb-2" />
                Candidates Required
              </label>
              <select
                name="candidatesRequired"
                value={candidatesRequired}
                onChange={(e) => setCandidatesRequired(e.target.value)}
                className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
              >
                {candidateRanges.map((range) => (
                  <option key={range.value} value={range.value}>
                    {range.label}
                  </option>
                ))}
              </select>
            </div>
              
            <div className="w-2/3">
              <label className="flex items-center text-sm font-medium text-gray-700">
                <MapPin className="w-4 h-4 text-blue-600 mr-2" />
                Search Locations
              </label>
              <input
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyPress={handleLocationAdd}
                className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                placeholder="Add location and press Enter"
              />
              <div className="flex flex-wrap gap-2 mt-2">
                {searchLocations.map((location) => (
                  <span
                    key={location}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-green-100 text-green-800"
                  >
                    {location}
                    <button
                      onClick={() => handleLocationRemove(location)}
                      className="ml-2 text-green-600 hover:text-green-800 transition-colors"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
          </div>
          </div>
        )}
      </div>

      {/* Candidate Screening Section */}
      <div className="border rounded-lg shadow-sm w-full">
        <div className="flex items-center justify-between p-2">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={sections.screening.isChecked}
              onChange={() => handleCheckboxChange('screening')}
              className="rounded"
            />
            <span className="font-medium">Candidate Screening</span>
          </label>
          <button
            onClick={() => toggleSection('screening')}
            className={`p-1 rounded transition-colors ${
              sections.screening.isChecked 
                ? 'hover:bg-gray-100 text-gray-500' 
                : 'text-gray-300 cursor-not-allowed'
            }`}
            disabled={!sections.screening.isChecked}
          >
            {sections.screening.isExpanded ? 
              <ChevronUp className="h-5 w-5" /> : 
              <ChevronDown className="h-5 w-5" />
            }
          </button>
        </div>
        
        {sections.screening.isExpanded && (
          <div className="p-4 bg-purple-50 border border-purple-200/50 shadow-inner rounded-lg">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Company Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Company Name
          </label>
          <input
            type="text"
            required
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
            placeholder="Enter company name"
          />
        </div>

        {/* Company Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Company Description
          </label>
          <textarea
            value={companyDescription}
            required
            onChange={(e) => setCompanyDescription(e.target.value)}
            className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
            placeholder="Enter job description"
            rows="4"
          ></textarea>
        </div>

        {/* Job Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Job Type
          </label>
          <select
            value={jobType}
            required
            onChange={(e) => setJobType(e.target.value)}
            className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
          >
            <option value="fulltime">Full-Time</option>
            <option value="internship">Internship</option>
            <option value="parttime">Part-Time</option>
          </select>
        </div>

        {/* Work Location Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Work Location Type
          </label>
          <select
            value={workLocationType}
            onChange={(e) => setWorkLocationType(e.target.value)}
            className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
          >
            <option value="office">Office</option>
            <option value="remote">Remote</option>
            <option value="hybrid">Hybrid</option>
          </select>
        </div>
      </div>

      {/* Conditional Fields */}
      {workLocationType !== 'remote' && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Work Locations
          </label>
          <input
            type="text"
            required
            value={workLocationInput}
            onChange={handleWorkLocationInputChange}
            onKeyPress={handleWorkLocationAdd}
            className="w-full mt-2 px-4 py-2.5 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
            placeholder="Add location and press Enter"
          />
          <div className="flex flex-wrap gap-2 mt-2">
            {workLocations.map((location) => (
              <span
                key={location}
                className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-green-100 text-green-800"
              >
                {location}
                <button
                  onClick={() => handleWorkLocationRemove(location)}
                  className="ml-2 text-green-600 hover:text-green-800 transition-colors"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="bg-gray-50 p-2 rounded-lg border border-gray-200 mt-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">
            Notice Period
          </label>
  <div className="flex gap-4">
    {/* Notice Period Dropdown */}
    <select
      name="noticePeriod"
      value={noticePeriod}
      onChange={(e) => setNoticePeriod(parseInt(e.target.value))}
      className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
    >
      {[0, 1, 2, 3].map((month) => (
        <option key={month} value={month}>
          {month === 0 ? "Immediate" : `${month} ${month === 1 ? "Month" : "Months"}`}
        </option>
      ))}
    </select>

    {/* Notice Strictness Dropdown */}
    <select
      name="noticeStrictness"
      value={noticeStrictness}
      onChange={(e) => setNoticeStrictness(e.target.value)}
      className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
    >
      <option value="strict">Strict</option>
      <option value="negotiable">Negotiable</option>
    </select>
  </div>
</div>

<div className="bg-gray-50 p-2 rounded-lg border border-gray-200 mt-4">
<label className="block text-sm font-medium text-gray-700 mb-2">
            CTC Details
          </label>
  <div className="flex gap-4">
    {/* Maximum CTC Input */}
    <input
      type="number"
      name="maxCTC"
      placeholder="Maximum CTC"
      value={maxCTC}
      onChange={(e) => setMaxCTC(e.target.value)}
      className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
    />

    {/* CTC Strictness Dropdown */}
    <select
      name="ctcStrictness"
      value={ctcStrictness}
      onChange={(e) => setCtcStrictness(e.target.value)}
      className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
    >
      <option value="strict">Strict</option>
      <option value="negotiable">Negotiable</option>
    </select>
  </div>
</div>

    </div>
        )}
      </div>

      {/* Candidate Assessment Section */}
      <div className="border rounded-lg shadow-sm w-full ">
        <div className="flex items-center justify-between p-2">
          <label className="flex items-center space-x-3">
            <input
              type="checkbox"
              checked={sections.assessment.isChecked}
              onChange={() => handleCheckboxChange('assessment')}
              className="rounded"
            />
            <span className="font-medium">Assessments</span>
          </label>
          <button
            onClick={() => toggleSection('assessment')}
            className={`p-1 rounded transition-colors ${
              sections.assessment.isChecked 
                ? 'hover:bg-gray-100 text-gray-500' 
                : 'text-gray-300 cursor-not-allowed'
            }`}
            disabled={!sections.assessment.isChecked}
          >
            {sections.assessment.isExpanded ? 
              <ChevronUp className="h-5 w-5" /> : 
              <ChevronDown className="h-5 w-5" />
            }
          </button>
        </div>
        
        {sections.assessment.isExpanded && (
          <>
        <div className="bg-purple-50 border border-purple-200/50 shadow-inner p-6 space-y-4">
          {/* Interview Time Limit */}
          <div className="space-y-2 flex gap-2 items-center justify-start">
            <label className="block text-black-700">
              Interview Time Limit (Mins):
            </label>
            <input
              type="number"
              min="1"
              value={interview_timelimit}
              onChange={(e) =>
                setInterviewtimelimit(e.target.value)
              }
              className="
            px-3 py-2 
            rounded-xl border-2 border-black-200 
            focus:border-black-500 
            transition w-full sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/2
          "
            />
          </div>

          {/* Custom Questions */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isCustomQuestionsIncluded}
                onChange={() => {
                  setIsCustomQuestionsIncluded(
                    !isCustomQuestionsIncluded
                  );
                  if (questions.length > 0) {
                    setQuestions([]);
                  }
                }}
                className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500"
              />
              <label className="text-black-700">
                Upload Custom Interview Questions
              </label>
            </div>
            {isCustomQuestionsIncluded && questions.length === 0 ? (
              <div className="space-y-2">
                <textarea
                  placeholder="Enter custom interview questions..."
                  value={customQuestions}
                  onChange={(e) =>
                    setCustomQuestions(e.target.value)
                  }
                  rows="5"
                  className="
                w-full px-4 py-3 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                ></textarea>
                <button
                  onClick={handleSaveCustomQuestions}
                  className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transformflex items-center justify-center space-x-2"
                >
                  Save Questions
                </button>
              </div>
            ) : (
              <>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {questions.map((question, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        value={question}
                        onChange={(e) =>
                          handleQuestionChange(
                            index,
                            e.target.value
                          )
                        }
                        style={{
                          flexGrow: 1,
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid #ddd",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      />
                      <button
                        onClick={() =>
                          setQuestions((prevQuestions) =>
                            prevQuestions.filter(
                              (_, i) => i !== index
                            )
                          )
                        }
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                {questions.length > 0 && (
                  <button
                    onClick={() => setQuestions([...questions, ""])}
                    className="bg-gradient-to-r from-green-500 to-green-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform flex items-center justify-center space-x-2"
                  >
                    Add Question
                  </button>
                )}
              </>
            )}
          </div>

          {/* MCQ Test */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isMCQIncluded}
                onChange={handleIncludeMCQ}
                className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500"
              />
              <label className="text-black-700">
                Include MCQ Test
              </label>
            </div>
            {isMCQIncluded && (
              <div className="flex gap-2">
                <select
                  value={selectedSet}
                  onChange={(e) => setSelectedSet(e.target.value)}
                  className="
                w-full px-3 py-2 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                >
                  <option>Select MCQ Set</option>
                  {sets.map((set, index) => (
                    <option key={index} value={set}>
                      {set}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  value={MCQTimeLimit}
                  onChange={(e) => setMCQTimeLimit(e.target.value)}
                  placeholder="MCQ Time Limit (Mins)"
                  className="
                w-full px-3 py-2 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                />
              </div>
            )}
          </div>

          {/* Coding Test */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isCodingIncluded}
                onChange={handleIncludeCoding}
                className="w-[15px] h-[15px] rounded text-black-600 focus:ring-black-500"
              />
              <label className="text-black-700">
                Include Coding Test
              </label>
            </div>
            {isCodingIncluded && (
              <div className="space-y-2">
                <div className="flex gap-2">
                  <select
                    value={selectedCodingSet}
                    onChange={(e) =>
                      setSelectedCodingSet(e.target.value)
                    }
                    className="
                w-full px-3 py-2 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                  >
                    <option value="">Select Coding Set</option>
                    {codingSets.map((set, index) => (
                      <option key={index} value={set}>
                        {set}
                      </option>
                    ))}
                  </select>
                  <input
                    type="number"
                    value={CodingTimeLimit}
                    onChange={(e) =>
                      setCodingTimeLimit(e.target.value)
                    }
                    placeholder="Coding Time Limit (Mins)"
                    className="
                w-full px-3 py-2 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                  />
                </div>
                <select
                  value={CodingJudgingCriteria}
                  onChange={(e) =>
                    setCodingJudgingCriteria(e.target.value)
                  }
                  className="
                w-full px-3 py-2 
                rounded-xl border-2 border-black-200 
                focus:border-black-500 
                transition
              "
                >
                  <option value="">Select Judging Criteria</option>
                  <option value="0">Judge 0</option>
                  <option value="1">Judge 1</option>
                </select>
              </div>
            )}
            </div>
            </div>
            </>
        )}
      </div>
      {(!sections.sourcing.isChecked && !sections.assessment.isChecked && sections.screening.isChecked) ? (
            <div className="mt-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
              <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium text-red-800">
                  Invalid combination selected
                </p>
              </div>
            </div>
          ):(
      <button 
       className="bg-gradient-to-r from-green-500 to-green-700 text-white px-6 py-3 rounded-full hover:scale-105 transition-transform w-full sm:w-auto" onClick={handleUpdateJD}>
        Submit 
      </button>
    )}           
    </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
}

export default CreateJD;
