import React, { useState, useEffect, useCallback } from 'react';
import { Brain, Sparkles, Cpu, Database, Code, LineChart, Workflow, GitBranch, Server } from 'lucide-react';

const AIAnalysisLoader = () => {
  const [currentPhase, setCurrentPhase] = useState(0);
  const [progress, setProgress] = useState(0);
  const [particles, setParticles] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  
  const phases = [
    {
      title: 'Requirements Analysis',
      icon: Brain,
      color: 'text-purple-600',
      bgColor: 'bg-purple-600',
      description: 'Analyzing position requirements',
      details: [
        'Extracting key job requirements',
        'Understanding role expectations',
        'Identifying qualification needs'
      ],
      duration: 3000
    },
    {
      title: 'Market Data Collection',
      icon: Code,
      color: 'text-indigo-600',
      bgColor: 'bg-indigo-600',
      description: 'Collecting role-specific data',
      details: [
        'Gathering position data',
        'Analyzing job descriptions',
        'Compiling skill requirements'
      ],
      duration: 3000
    },
    {
      title: 'Market Intelligence',
      icon: LineChart,
      color: 'text-cyan-600',
      bgColor: 'bg-cyan-600',
      description: 'Analyzing industry trends',
      details: ['Scanning market data', 'Evaluating demand patterns', 'Assessing skill relevance'],
      duration: 3000
    },
    {
      title: 'Skill Generation',
      icon: Sparkles,
      color: 'text-indigo-600',
      bgColor: 'bg-indigo-600',
      description: 'Creating personalized recommendations',
      details: ['Matching skills to role', 'Ranking importance', 'Finalizing suggestions'],
      duration: 3000
    }
  ];

  // Generate random floating icons
  const floatingIcons = [GitBranch, Server, Workflow, Database, Cpu];
  
  useEffect(() => {
    // Progress and phase management
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          setCurrentPhase(currentPhase => 
            currentPhase < phases.length - 1 ? currentPhase + 1 : 0
          );
          return 0;
        }
        return prev + 2;
      });
    }, 60);

    return () => clearInterval(interval);
  }, []);

  // Particle effect management
  useEffect(() => {
    const createParticle = () => {
      const angle = Math.random() * Math.PI * 2;
      const radius = 80;
      return {
        id: Math.random(),
        x: Math.cos(angle) * radius,
        y: Math.sin(angle) * radius,
        size: Math.random() * 4 + 2,
        velocity: Math.random() * 2 + 1,
        angle,
        opacity: Math.random(),
      };
    };

    const particleInterval = setInterval(() => {
      setParticles(prev => {
        const newParticles = [...prev, createParticle()];
        if (newParticles.length > 15) {
          newParticles.shift();
        }
        return newParticles;
      });
    }, 200);

    return () => clearInterval(particleInterval);
  }, []);

  const getCurrentPhase = () => phases[currentPhase];
  const Icon = getCurrentPhase().icon;

  return (
    <div className="w-full max-w-2xl mx-auto p-8 space-y-8">
      <div className="relative" 
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}>
        {/* Main visualization container */}
        <div className="relative w-64 h-64 mx-auto">
          {/* Outer ring with gradient */}
          <div className="absolute inset-0 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 animate-spin-slow opacity-20" />
          
          {/* Progress circle */}
          <div className="absolute inset-2 bg-white rounded-full flex items-center justify-center">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-full h-full rounded-full border-4 border-gray-100" />
              <div 
                className={`absolute inset-0 rounded-full border-4 ${getCurrentPhase().color} transition-all duration-300`}
                style={{
                  clipPath: `polygon(0% 0%, ${progress}% 0%, ${progress}% 100%, 0% 100%)`,
                }}
              />
            </div>

            {/* Center icon with pulse effect */}
            <div className={`relative z-10 ${getCurrentPhase().color} transform transition-transform duration-300 ${isHovered ? 'scale-110' : ''}`}>
              <Icon size={48} className="animate-pulse" />
            </div>

            {/* Floating particles */}
            {particles.map((particle, index) => (
              <div
                key={particle.id}
                className={`absolute ${getCurrentPhase().bgColor} rounded-full transition-opacity duration-300`}
                style={{
                  width: `${particle.size}px`,
                  height: `${particle.size}px`,
                  transform: `translate(${particle.x}px, ${particle.y}px)`,
                  opacity: particle.opacity,
                }}
              />
            ))}

            {/* Floating technology icons */}
            {floatingIcons.map((FloatingIcon, index) => (
              <div
                key={index}
                className={`absolute ${getCurrentPhase().color} transition-all duration-500`}
                style={{
                  transform: `rotate(${(360 / floatingIcons.length) * index}deg) translateY(-100px) rotate(-${(360 / floatingIcons.length) * index}deg)`,
                }}
              >
                <FloatingIcon size={20} className="animate-bounce" style={{ animationDelay: `${index * 200}ms` }} />
              </div>
            ))}
          </div>
        </div>

        {/* Phase information with enhanced details */}
        <div className="text-center mt-8 space-y-4">
          <h3 className={`text-2xl font-bold ${getCurrentPhase().color} transition-colors duration-300`}>
            {getCurrentPhase().title}
          </h3>
          <p className="text-gray-600 font-medium">
            {getCurrentPhase().description}
          </p>
          
          {/* Detailed progress steps */}
          <div className="space-y-2">
            {getCurrentPhase().details.map((detail, index) => (
              <div 
                key={index}
                className={`text-sm ${index === Math.floor((progress / 100) * 3) ? getCurrentPhase().color : 'text-gray-400'}`}
              >
                {detail}
              </div>
            ))}
          </div>

          {/* Phase progress indicator */}
          <div className="flex items-center justify-center space-x-2 mt-4">
            {phases.map((phase, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index === currentPhase ? phase.bgColor : 'bg-gray-200'
                } ${index === currentPhase ? 'scale-125' : 'scale-100'}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIAnalysisLoader;