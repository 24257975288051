import React, { useState } from 'react';
import { Editor } from '@monaco-editor/react';

const HostCodingProblem = ({
  formData,
  setFormData,
  selectedLanguage,
  setSelectedLanguage,
  code,
  setCode,
  handleBoilerplateCodeChange,
  handleSubmit: submitForm,
  handleExamplesChange,
  handleTestCasesChange,
  handleFinalTestCasesChange
}) => {
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const stripHtmlTags = (str) => {
    return str.replace(/<[^>]*>/g, '');
  };

  const isOnlyWhitespace = (str) => {
    return str.trim().length === 0;
  };

  const validateTitle = (value) => {
    if (!value || isOnlyWhitespace(value)) return 'Title is required';
    if (value.trim().length < 3) return 'Title must be at least 3 characters long';
    if (value.trim().length > 150) return 'Title must not exceed 150 characters';
    return '';
  };

  const validateDescription = (value) => {
    if (!value || isOnlyWhitespace(value)) return 'Description is required';
    if (value.trim().length < 20) return 'Description must be at least 20 characters long';
    if (value.trim().length > 2000) return 'Description must not exceed 2000 characters';
    if (value !== stripHtmlTags(value)) return 'HTML tags are not allowed in description';
    return '';
  };

  const validateFormat = (value, field) => {
    if (!value || isOnlyWhitespace(value)) return `${field} is required`;
    if (value.trim().length > 500) return `${field} must not exceed 500 characters`;
    if (value !== stripHtmlTags(value)) return `HTML tags are not allowed in ${field.toLowerCase()}`;
    return '';
  };

  const validateExamples = (examples) => {
    if (!examples || examples.length === 0) return 'At least one example is required';
    
    const exampleErrors = examples.map(example => {
      const errors = {};
      if (!example.input || isOnlyWhitespace(example.input)) errors.input = 'Example input is required';
      if (!example.output || isOnlyWhitespace(example.output)) errors.output = 'Example output is required';
      if (!example.explanation || isOnlyWhitespace(example.explanation)) {
        errors.explanation = 'Example explanation is required';
      } else if (example.explanation.trim().length < 10) {
        errors.explanation = 'Explanation must be at least 10 characters long';
      } else if (example.explanation.trim().length > 2000) {
        errors.explanation = 'Explanation must not exceed 2000 characters';
      }
      return Object.keys(errors).length > 0 ? errors : null;
    });

    return exampleErrors.some(error => error !== null) ? exampleErrors : '';
  };

  const findDuplicateInputs = (testCases, finalTestCases) => {
    const testInputs = testCases.map(tc => tc.input.trim());
    const finalInputs = finalTestCases.map(tc => tc.input.trim());
    
    const duplicates = testInputs.filter(input => 
      input && finalInputs.includes(input)
    );
    
    return duplicates.length > 0;
  };

  const validateTestCases = (testCases, isFinal = false) => {
    const type = isFinal ? 'final test case' : 'test case';
    if (!testCases || testCases.length === 0) return `At least one ${type} is required`;

    const testCaseErrors = testCases.map(testCase => {
      const errors = {};
      if (!testCase.input || isOnlyWhitespace(testCase.input)) errors.input = `${type} input is required`;
      if (!testCase.output || isOnlyWhitespace(testCase.output)) errors.output = `${type} output is required`;
      return Object.keys(errors).length > 0 ? errors : null;
    });

    if (!testCaseErrors.some(error => error !== null)) {
      if (!isFinal && findDuplicateInputs(testCases, formData.final_test_cases)) {
        return testCases.map(() => ({ input: 'Test case input must be different from final test cases' }));
      }
      if (isFinal && findDuplicateInputs(formData.test_cases, testCases)) {
        return testCases.map(() => ({ input: 'Final test case input must be different from regular test cases' }));
      }
    }

    return testCaseErrors.some(error => error !== null) ? testCaseErrors : '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    const titleError = validateTitle(formData.title);
    if (titleError) newErrors.title = titleError;

    const descriptionError = validateDescription(formData.description);
    if (descriptionError) newErrors.description = descriptionError;

    const inputFormatError = validateFormat(formData.input_format, 'Input Format');
    if (inputFormatError) newErrors.input_format = inputFormatError;

    const outputFormatError = validateFormat(formData.output_format, 'Output Format');
    if (outputFormatError) newErrors.output_format = outputFormatError;

    const constraintsError = validateFormat(formData.constraints, 'Constraints');
    if (constraintsError) newErrors.constraints = constraintsError;

    const examplesError = validateExamples(formData.examples);
    if (examplesError) newErrors.examples = examplesError;

    const testCasesError = validateTestCases(formData.test_cases);
    if (testCasesError) newErrors.test_cases = testCasesError;

    const finalTestCasesError = validateTestCases(formData.final_test_cases, true);
    if (finalTestCasesError) newErrors.final_test_cases = finalTestCasesError;

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      submitForm(e);
      handleScrollToTop();
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(prevState => !prevState);
  };

  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    let error = '';
    
    switch (field) {
      case 'title':
        error = validateTitle(value);
        break;
      case 'description':
        error = validateDescription(value);
        break;
      case 'input_format':
      case 'output_format':
      case 'constraints':
        error = validateFormat(value, field.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase()));
        break;
      default:
        break;
    }
    
    setErrors(prev => ({ ...prev, [field]: error }));
  };

  return (
    <form onSubmit={handleSubmit} className="createProblemForm createOwnProblem p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md space-y-6">
      {/* Title field */}
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          <h2>Title:</h2>
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={(e) => handleFieldChange('title', e.target.value)}
          placeholder="Title of the problem"
          className={`w-full p-3 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
      </div>

      {/* Description field */}
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          <h2>Description:</h2>
        </label>
        <textarea
          name="description"
          value={formData.description}
          onChange={(e) => handleFieldChange('description', e.target.value)}
          placeholder="Description of the problem"
          rows={3}
          className={`w-full p-3 border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
      </div>

      {/* Input Format field */}
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          <h2>Input Format:</h2>
        </label>
        <textarea
          name="input_format"
          value={formData.input_format}
          onChange={(e) => handleFieldChange('input_format', e.target.value)}
          placeholder="Input Format"
          rows={2}
          className={`w-full p-3 border ${errors.input_format ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.input_format && <p className="text-red-500 text-sm mt-1">{errors.input_format}</p>}
      </div>

      {/* Output Format field */}
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          <h2>Output Format:</h2>
        </label>
        <textarea
          name="output_format"
          value={formData.output_format}
          onChange={(e) => handleFieldChange('output_format', e.target.value)}
          placeholder="Output Format"
          rows={2}
          className={`w-full p-3 border ${errors.output_format ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.output_format && <p className="text-red-500 text-sm mt-1">{errors.output_format}</p>}
      </div>

      {/* Constraints field */}
      <div>
        <label className="block text-lg font-semibold text-gray-700 mb-1">
          <h2>Constraints:</h2>
        </label>
        <textarea
          name="constraints"
          value={formData.constraints}
          onChange={(e) => handleFieldChange('constraints', e.target.value)}
          placeholder="Constraints"
          rows={2}
          className={`w-full p-3 border ${errors.constraints ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
        />
        {errors.constraints && <p className="text-red-500 text-sm mt-1">{errors.constraints}</p>}
      </div>

      {/* Examples section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800">Examples</h2>
        {formData.examples.map((example, index) => (
          <div key={index} className="example-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
            <div>
              <label className="block text-sm font-medium text-gray-700">Example Input:</label>
              <textarea
                name={`example_input_${index}`}
                value={example.input}
                onChange={(e) => handleExamplesChange(index, 'input', e.target.value)}
                placeholder="Example input"
                className={`w-full p-3 border ${errors.examples?.[index]?.input ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.examples?.[index]?.input && <p className="text-red-500 text-sm mt-1">{errors.examples[index].input}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Example Output:</label>
              <textarea
                name={`example_output_${index}`}
                value={example.output}
                onChange={(e) => handleExamplesChange(index, 'output', e.target.value)}
                placeholder="Example output"
                className={`w-full p-3 border ${errors.examples?.[index]?.output ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.examples?.[index]?.output && <p className="text-red-500 text-sm mt-1">{errors.examples[index].output}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Explanation:</label>
              <textarea
                name={`example_explanation_${index}`}
                value={example.explanation}
                onChange={(e) => handleExamplesChange(index, 'explanation', e.target.value)}
                placeholder="Explanation"
                className={`w-full p-3 border ${errors.examples?.[index]?.explanation ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.examples?.[index]?.explanation && <p className="text-red-500 text-sm mt-1">{errors.examples[index].explanation}</p>}
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setFormData({
            ...formData,
            examples: [...formData.examples, { input: '', output: '', explanation: '' }]
          })}
          className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Add Example
        </button>
      </div>

      {/* Test Cases Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800">Test Cases</h2>
        {formData.test_cases.map((testCase, index) => (
          <div key={index} className="test-case-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
            <div>
              <label className="block text-sm font-medium text-gray-700">Test Case Input:</label>
              <textarea
                name={`test_case_input_${index}`}
                value={testCase.input}
                onChange={(e) => handleTestCasesChange(index, 'input', e.target.value)}
                placeholder="Test case input"
                className={`w-full p-3 border ${errors.test_cases?.[index]?.input ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.test_cases?.[index]?.input && <p className="text-red-500 text-sm mt-1">{errors.test_cases[index].input}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Test Case Output:</label>
              <textarea
                name={`test_case_output_${index}`}
                value={testCase.output}
                onChange={(e) => handleTestCasesChange(index, 'output', e.target.value)}
                placeholder="Test case output"
                className={`w-full p-3 border ${errors.test_cases?.[index]?.output ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.test_cases?.[index]?.output && <p className="text-red-500 text-sm mt-1">{errors.test_cases[index].output}</p>}
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setFormData({
            ...formData,
            test_cases: [...formData.test_cases, { input: '', output: '' }],
          })}
          className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Add Test Case
        </button>
      </div>

      {/* Final Test Cases Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800">Final Test Cases</h2>
        {formData.final_test_cases.map((testCase, index) => (
          <div key={index} className="test-case-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
            <div>
              <label className="block text-sm font-medium text-gray-700">Final Test Case Input:</label>
              <textarea
                name={`final_test_case_input_${index}`}
                value={testCase.input}
                onChange={(e) => handleFinalTestCasesChange(index, 'input', e.target.value)}
                placeholder="Final test case input"
                className={`w-full p-3 border ${errors.final_test_cases?.[index]?.input ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.final_test_cases?.[index]?.input && <p className="text-red-500 text-sm mt-1">{errors.final_test_cases[index].input}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Final Test Case Output:</label>
              <textarea
                name={`final_test_case_output_${index}`}
                value={testCase.output}
                onChange={(e) => handleFinalTestCasesChange(index, 'output', e.target.value)}
                placeholder="Final test case output"
                className={`w-full p-3 border ${errors.final_test_cases?.[index]?.output ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:border-blue-500`}
              />
              {errors.final_test_cases?.[index]?.output && <p className="text-red-500 text-sm mt-1">{errors.final_test_cases[index].output}</p>}
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={() => setFormData({
            ...formData,
            final_test_cases: [...formData.final_test_cases, { input: '', output: '' }],
          })}
          className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Add Final Test Case
        </button>
      </div>

      {/* Boilerplate Code Section */}
      <div className="boilerplate-section">
          <button type="button" onClick={toggleEditorVisibility} className="w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mb-4">
            {isEditorVisible ? 'Hide Boiler Plate' : 'Add Boiler Plate'}
          </button>
 
          {isEditorVisible && (
            <>
              <div className="testcase-item">
                <select id="language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">
                  <option value="javascript">JavaScript</option>
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="cpp">C++</option>
                  <option value="c">C</option>
                  <option value="go">Go</option>
                  <option value="cs">C#</option>
                  <option value="apex">Apex</option>
                </select>
              </div>
 
              <div className="editor bg-gray-100 p-4 rounded-lg shadow-inner mt-4">
                <Editor
                  height="60vh"
                  language={selectedLanguage}
                  value={code}
                  onChange={(value) => {
                    setCode(value);
                    handleBoilerplateCodeChange(selectedLanguage, value);
                  }}
                />
                {errors.boilerplate_code && <p className="text-red-500 text-sm mt-1">{errors.boilerplate_code}</p>}
              </div>
            </>
          )} 
        </div>

      <button
        type="submit"
        className="submitButton w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 font-semibold"
      >
        Create Problem
      </button>
    </form>
  );
};

export default HostCodingProblem;
