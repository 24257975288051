import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global.jsx";
import {
  BrainCog,
  Code2,
  Users,
  Rocket,
  Trophy,
  Timer,
  Lock,
  CheckCircle,
  ChevronRight,
  Target,
} from "lucide-react";
import { toast } from "react-toastify";

export default function Component() {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const [mcqStatus, setMCQStatus] = useState("Incomplete");
  const [codingStatus, setCodingStatus] = useState("Incomplete");
  const [interviewStatus, setInterviewStatus] = useState("Incomplete");
  const [mcqTimeLimit, setMCQTimeLimit] = useState(0);
  const [codingTimeLimit, setCodingTimeLimit] = useState(0);
  const [interviewTimeLimit, setInterviewTimeLimit] = useState(0);
  const totalAssessments = [mcqStatus, codingStatus, interviewStatus].filter(
    (status) => status !== "NA"
  ).length;
  const progress = [
    mcqStatus === "Complete" || mcqStatus === "Passed",
    codingStatus === "Complete",
    interviewStatus === "Complete" || interviewStatus === "Passed",
  ].filter(Boolean).length;

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        if (response) {
          setMCQStatus(response.data.mcq_status);
          setCodingStatus(response.data.coding_status);
          setInterviewStatus(response.data.interview_status);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setCodingTimeLimit(response.data.coding_time_limit);
          setInterviewTimeLimit(response.data.interview_time_limit);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkStatus();
  }, [interview_id]);

  const navigateMCQtest = async () => {
    try {
      const response = await axios.get(
        `${host}/check_test_progress/${interview_id}`
      );

      if (response.data.mcq_status === "Incomplete") {
        localStorage.setItem(
          `timeLeft_${interview_id}_mcq`,
          response.data.mcq_time_limit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/mcqinstructions`);
        }, 1000);
      } else {
        toast.warn("MCQ Test is already Completed!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateCodingtest = async () => {
    try {
      const response = await axios.get(
        `${host}/check_test_progress/${interview_id}`
      );
      if (
        (response.data.mcq_status === "Passed" ||
          response.data.mcq_status === "NA") && 
          response.data.interview_status === "Complete" &&
          response.data.coding_status !== "Complete"
      ) {
        localStorage.setItem(
          `timeLeft_${interview_id}_coding`,
          response.data.coding_time_limit * 60
        );
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/codinginstructions`);
        }, 1000);
      } else if (response.data.interview_status !== "Complete") {
        toast.warn("You Must Complete the Technical Interview First!");
      } else {
        toast.error("Coding Test already Completed!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const navigateInterview = async () => {
    try {
      const response = await axios.get(
        `${host}/check_test_progress/${interview_id}`
      );
      if (
        (response.data.mcq_status === "Passed" ||
          response.data.mcq_status === "NA") 
        // (response.data.coding_status === "Complete" ||
        //   response.data.coding_status === "NA")
      ) {
        navigate(`/candidate/${interview_id}/interviewinstructions`);
      } else {
        toast.warn("You Must Pass Previous Tests To Start Interview!");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  function formatTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0
      ? `${String(hours).padStart(2, "0")} H ${String(mins).padStart(
          2,
          "0"
        )} mins`
      : `${String(mins).padStart(2, "0")} mins`;
  }

  const CardComponent = ({
    title,
    icon: Icon,
    onNavigate,
    description,
    status,
    stepNumber,
  }) => {
    const isCompleted = status === "Complete" || status === "Passed";
    let isLocked;
    let isInProgress = true;

    if (title === "Technical Interview") {
      isLocked = mcqStatus !== "Passed" || mcqStatus !== "NA";
      isInProgress = mcqStatus === "Passed" || mcqStatus === "NA";
    } else if (title === "Coding Challenge") {                           // eslint-disable-next-line
      isLocked = interviewStatus !== "Complete";     
      isInProgress =
        (mcqStatus === "Complete" ||
          mcqStatus === "Passed" ||
          mcqStatus === "NA") &&
        interviewStatus === "Complete";
    }

    return (
      <div
        className={`
        relative rounded-3xl transition-all duration-500
        ${
          !isInProgress
            ? "bg-gray-100 opacity-75"
            : "bg-white/30 shadow-md hover:shadow-[0_20px_60px_-15px_rgba(13,110,253,0.3)]"
        }
      `}
      >
        <div className="absolute -left-6 top-1/2 -translate-y-1/2 flex items-center">
          <div
            className={`
            w-12 h-12 rounded-full flex items-center justify-center text-md font-bold
            ${
              isCompleted
                ? "bg-green-400 text-white"
                : isInProgress
                ? "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white"
                : "bg-gray-200 text-gray-500"
            }
          `}
          >
            {isCompleted ? <CheckCircle className="w-6 h-6" /> : stepNumber}
          </div>
        </div>

        {stepNumber < 3 && title !== "Technical Interview" &&(
          <div
            className={`absolute -bottom-12 left-0 w-[2px] h-12 -z-10 ${
              isCompleted ? "bg-[#4ade80]" : "bg-gray-200"
            }`}
            style={{ left: "19px" }}
          />
        )}

        <div className=" p-4 pl-12 h-full">
          <div className="flex items-start justify-between gap-10">
            <div className="flex-1">
              <div className="flex items-center gap-4 mb-4">
                <div
                  className={`
                  p-4 rounded-2xl
                  ${
                    !isInProgress
                      ? "bg-gray-200 text-gray-400"
                      : "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white"
                  }
                `}
                >
                  <Icon className="w-7 h-7" />
                </div>
                <div>
                  <h2
                    className={`
                    text-xl font-bold font-['Poppins'] tracking-tight
                    ${!isInProgress ? "text-gray-400" : "text-gray-800"}
                  `}
                  >
                    {title}
                  </h2>
                  <div className="flex items-center gap-2 mt-1">
                    {!isInProgress && (
                      <Lock className="w-4 h-4 text-gray-400" />
                    )}
                    <span
                      className={`
                      text-sm font-medium
                      ${
                        isCompleted
                          ? "text-green-500"
                          : isInProgress
                          ? "text-blue-500"
                          : "text-gray-400"
                      }
                    `}
                    >
                      {isCompleted
                        ? "Completed"
                        : isInProgress
                        ? "In Progress"
                        : "Locked"}
                    </span>
                  </div>
                </div>
              </div>
              <p
                className={`
                text-md
                ${!isInProgress ? "text-gray-400" : "text-gray-500"}
              `}
              >
                {description}
              </p>
            </div>
            {!isCompleted && (
              <div className="flex items-center justify-center h-full">
                <button
                  onClick={onNavigate}
                  disabled={!isInProgress}
                  className={`
                  px-6 py-3 rounded-xl font-semibold transition-all duration-300 
                  flex items-center gap-2
                  ${
                    !isInProgress
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white hover:gap-4"
                  }
                `}
                >
                  Start Now
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-h-screen h-full bg-gray-50/50 overflow-auto px-8 py-8 bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
      <div className="flex flex-wrap justify-between items-start">
      {/* Left Column - Stats */}
          <div className="w-full lg:w-5/12 space-y-8 py-4">
            <div className="bg-white/30 backdrop-blur-lg p-6 shadow-xl rounded-3xl shadow-xl p-4 text-black relative overflow-hidden">
              <div className="relative z-10 space-y-6">
                <div className="flex items-center gap-3">
                  <Rocket className="w-6 h-6" />
                  <h1 className="text-2xl font-bold">Assessment Flow</h1>
                </div>

                <p className="text-lg opacity-90">
                  Complete each step to unlock the next challenge
                </p>

                <div className="grid grid-cols-1 gap-4">
                  {(mcqStatus === "NA" || codingStatus === "NA" || mcqStatus === "Passed" || codingStatus === "Complete" || mcqStatus === "Incomplete")  ? (
                    <>
                      <div className="backdrop-blur-lg rounded-2xl p-4 m-4 shadow-lg">
                        <div className="flex items-center gap-4">
                          <Trophy className="w-6 h-6 mb-1 text-black" />
                          <div className="text-2xl font-semibold text-black">
                            {progress}/{totalAssessments}
                          </div>
                        </div>
                        <div className="text-sm text-black opacity-90 mt-2">
                          {progress !== totalAssessments ? (
                            <span>Progress</span>
                          ) : (
                            <span className="text-black-400">
                              All assessments are complete!
                            </span>
                          )}
                        </div>
                        <div className="w-full h-1 mt-4 bg-gray-300 rounded-full">
                          <div
                            className="h-full bg-green-500 rounded-full"
                            style={{
                              width: `${(progress / totalAssessments) * 100}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="max-w-md mx-auto transform">
                      <div className="relative overflow-hidden rounded-2xl shadow-xl">
                        {/* Gradient border effect using pseudo-element */}
                        <div className="absolute inset-0" />

                        {/* Inner content with glass effect */}
                        <div className="relative m-[2px] bg-white/10 backdrop-blur-xl p-8 rounded-2xl">
                          {/* Decorative elements */}
                          <div className="absolute top-0 left-0 w-24 h-24 bg-[#3ECFDA] rounded-full opacity-10 blur-2xl" />
                          <div className="absolute bottom-0 right-0 w-32 h-32 bg-[#0952DA] rounded-full opacity-10 blur-2xl" />

                          {/* Content */}
                          <div className="relative z-10 text-center">
                            <h2 className="text-lg font-bold text-black">
                              Unfortunately, Your Journey Ends Here!
                            </h2>
                            <p className="mt-4 text-xs text-white-400 opacity-75  text-center">
                              Keep growing! Every end is a new beginning ✨
                            </p>
                            <div className="mt-4 h-[2px] w-16 mx-auto bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] rounded-full" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Decorative elements */}
              <div className="absolute top-0 right-0 w-64 h-64 bg-white/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/2" />
              <div className="absolute -bottom-8 -left-8 w-48 h-48 bg-white/10 rounded-full blur-2xl" />
            </div>

            {/* Progress Tracker */}
            <div className="bg-white/30 backdrop-blur-sm rounded-3xl p-6 shadow-lg">
              <div className="flex items-center gap-3 mb-6">
                <Target className="w-6 h-6 text-[#0952DA]" />
                <h2 className="text-xl font-semibold text-gray-800">
                  Assessment Details
                </h2>
              </div>
              <div className="space-y-4">
              {mcqStatus !== "NA" && (
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-gray-700">
                      Knowledge Assessment
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Timer className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">
                      {formatTime(mcqTimeLimit)}
                    </span>
                  </div>
                </div>
                )}
                {codingStatus !== "NA" && (
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-gray-700">
                      Coding Challenge
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Timer className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">
                      {formatTime(codingTimeLimit)}
                    </span>
                  </div>
                </div>
                )}
                <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-gray-700">
                      Technical Interview
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <Timer className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">
                      {formatTime(interviewTimeLimit)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Assessment Cards */}
          <div className="space-y-8 lg:w-6/12 relative pl-6 py-4">
            {mcqStatus !== "NA" && (
              <CardComponent
                title="Knowledge Assessment"
                icon={BrainCog}
                description="Begin our adaptive assessment to test your knowledge."
                onNavigate={navigateMCQtest}
                status={mcqStatus}
                stepNumber={1}
              />
            )}

            <CardComponent
              title="Technical Interview"
              icon={Users}
              description="Join an AI interview to discuss your expertise."
              onNavigate={navigateInterview}
              status={interviewStatus}
              // stepNumber={
              //   (mcqStatus !== "NA" && codingStatus !== "NA") ? 3 : ((mcqStatus === "NA" && codingStatus !== "NA") || (mcqStatus !== "NA" && codingStatus === "NA")) 
              //     ? 2  : 1}
              stepNumber={mcqStatus !== "NA" ? 2 : 1}            
            />

            {codingStatus !== "NA" && (
              <CardComponent
                title="Coding Challenge"
                icon={Code2}
                description="Solve real-world problems to showcase your coding skills."
                onNavigate={navigateCodingtest}
                status={codingStatus}
                stepNumber={mcqStatus !== "NA" ? 3 : 2}
              />
            )}
          </div>
        </div>
    </div>
  );
}
