import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Linkedin, UserCircle2, X, Mail, Phone, Link, ChevronDown, AlertCircle, Filter, Download,Users } from "lucide-react";
import { useLocation } from 'react-router-dom';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,Card, CardHeader, CardContent
} from "@mui/material";
import {toast } from 'react-toastify';
const host = process.env.REACT_APP_API_HOST;
const CandidateList = () => {
    const [filterCriteria, setFilterCriteria] = useState('');
    const location = useLocation();
    const { jobId, services , jobrole } = location.state || {};
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentCandidate, setCurrentCandidate] = useState(null);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const accessToken = Cookies.get('accessToken');
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [activeTab, setActiveTab] = useState('all');
    const [manualReviewCandidates, setManualReviewCandidates] = useState([]);
    const [screeningFailedCandidates, setScreeningFailedCandidates] = useState([]);
    const statusOptions = ['sourced', 'screening', 'interviewing', 'selected', 'rejected'];
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedScreeningStatus, setSelectedScreeningStatus] = useState('');
    const generatePDF = () => {
        const printWindow = window.open('', '', 'height=600,width=1000');
        printWindow.document.write(`
            <html>
                <head>
                    <title>${currentCandidate?.first_name} ${currentCandidate?.last_name} - Profile</title>
                    <style>
                        body { font-family: Arial, sans-serif; padding: 20px; }
                        .header { margin-bottom: 20px; }
                        .section { margin-bottom: 15px; }
                        .title { font-size: 24px; font-weight: bold; margin-bottom: 5px; }
                        .subtitle { color: #666; margin-bottom: 10px; }
                        .skills-container { display: flex; flex-wrap: wrap; gap: 5px; }
                        .skill-tag { background-color: #dbeafe; color: #1e40af; padding: 4px 8px; border-radius: 4px; }
                        .experience-item, .education-item { margin-bottom: 15px; padding-bottom: 15px; border-bottom: 1px solid #eee; }
                    </style>
                </head>
                <body>
                    <div class="header">
                        <div class="title">${currentCandidate?.first_name} ${currentCandidate?.last_name}</div>
                        <div class="subtitle">${currentCandidate?.occupation || ''}</div>
                        ${currentCandidate?.personal_emails ? `<div>${currentCandidate.personal_emails}</div>` : ''}
                        ${currentCandidate?.personal_numbers ? `<div>${currentCandidate.personal_numbers[0]}</div>` : ''}
                    </div>
                    ${currentCandidate?.education && currentCandidate.education.length > 0 ? `
                        <div class="section">
                            <h2>Education</h2>
                            ${currentCandidate.education.map(edu => `
                                <div class="education-item">
                                    <div><strong>${edu.school}</strong></div>
                                    <div>${edu.degree_name || edu.field_of_study}</div>
                                    <div>${edu.starts_at?.year || 'N/A'} - ${edu.ends_at?.year || 'Present'}</div>
                                </div>
                            `).join('')}
                        </div>
                    ` : ''}
                    ${currentCandidate?.skills && currentCandidate.skills.length > 0 ? `
                        <div class="section">
                            <h2>Skills</h2>
                            <div class="skills-container">
                                ${currentCandidate.skills.map(skill => `
                                    <span class="skill-tag">${skill}</span>
                                `).join('')}
                            </div>
                        </div>
                    ` : ''}
                    ${currentCandidate?.experiences && currentCandidate.experiences.length > 0 ? `
                        <div class="section">
                            <h2>Experience</h2>
                            ${currentCandidate.experiences.map(exp => `
                                <div class="experience-item">
                                    <div><strong>${exp.title}</strong></div>
                                    <div>${exp.company}</div>
                                    <div>${exp.location}</div>
                                    <div>${exp.starts_at?.year || 'N/A'} - ${exp.ends_at?.year || 'Present'}</div>
                                </div>
                            `).join('')}
                        </div>
                    ` : ''}
                    ${currentCandidate?.certifications && currentCandidate.certifications.length > 0 ? `
                        <div class="section">
                            <h2>Certifications</h2>
                            ${currentCandidate.certifications.map(cert => `
                                <div class="certification-item">
                                    <div><strong>${cert.name}</strong></div>
                                    <div>${cert.authority}</div>
                                </div>
                            `).join('')}
                        </div>
                    ` : ''}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 250);
    };

    const fetchCandidates = async () => {
        try {
            setLoading(true);
            const params = {
                job_id: jobId,
                sort_by: filterCriteria,
                status_filter: selectedStatus,
                screening_status_filter: selectedScreeningStatus
            };
            const response = await axios.get(`${host}/getCandidateListData`, {
                params,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response && response.data) {
                const pendingCandidates = response.data.filter(candidate => candidate.screeningStatus === 'Awaiting Approval');
                const failCandidates = response.data.filter(candidate => candidate.screeningStatus === 'Failed');
                setManualReviewCandidates(pendingCandidates);
                setScreeningFailedCandidates(failCandidates);
                setCandidates(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch candidates");
        } finally {
            setLoading(false);
        }
    };

    const handleScreeningStatusChange = (e) => {
        setSelectedScreeningStatus(e.target.value);
    };
    const handleDropdownChange = (e) => {
        const status = e.target.value;
        setSelectedStatus(status);
    };
    const handleFilterChange = (e) => {
        const criteria = e.target.value;
        setFilterCriteria(criteria);
    };
    const ViewCandidateProfile = async (candidate_id) => {
        try {
            setLoading(true);
            console.log('Fetching candidates...');
            const response = await axios.get(`${host}/getCandidateProfileData`, {
                params: {
                    _id: candidate_id
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            setCurrentCandidate(response.data)
        } catch (error) {
            toast.error("Failed to fetch profile data");
        } finally {
            setLoading(false);
        }
    };
    
    const updateScreeningStatus = async (job_id, candidateId, newStatus) => {
        try {
            const response = await axios.put(
                `${host}/updateScreeningStatus`,
                null,
                {
                    params: {
                        job_id: job_id,
                        candidateId: candidateId,
                        screeningStatus: newStatus,
                    },
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );
            setCandidates(candidates.map(candidate =>
                candidate.candidateId === candidateId
                    ? { ...candidate, screeningStatus: newStatus }
                    : candidate
            ));
            setOpenDropdownId(null)
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };
    const updateCandidateStatus = async (candidateId, newStatus) => {
        try {
            const response = await axios.put(
                `${host}/updateCandidateStatus`,
                null,
                {
                    params: {
                        candidateId: candidateId,
                        status: newStatus,
                    },
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );
            const updatedCandidates = candidates.map(candidate =>
                candidate.candidateId === candidateId
                    ? { ...candidate, status: newStatus }
                    : candidate
            );
            setCandidates(updatedCandidates);
            setOpenDropdownId(null);
            setOpenDropdownId(null)
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const StatusDropdown = ({ candidate, services }) => {
        if (services?.length !== 1 || services[0] !== 'sourcing') {
            return null;
        }
        return (
            <div className="relative" data-dropdown>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenDropdownId(openDropdownId === candidate.candidateId ? null : candidate.candidateId);
                    }}
                    className="flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium"
                    style={{
                        backgroundColor: 'transparent', 
                        color: candidate.status === 'sourced' ? '#166534' :
                            candidate.status === 'pending' ? '#854d0e' : '#1f2937'
                    }}
                >
                    {candidate.status}
                    <ChevronDown className="w-4 h-4" />
                </button>
                {openDropdownId === candidate.candidateId && (
                     <div className="absolute right-0 mt-2 w-48 bg-white/30 rounded-md shadow-lg z-50 border border-gray-200">
                    

                        {statusOptions.map(status => (
                            <button
                                key={status}
                                onClick={() => updateCandidateStatus(candidate.candidateId, status)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 first:rounded-t-md last:rounded-b-md"
                            >
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (selectedStatus) {
            const filteredCandidates = candidates.filter(
                (candidate) => candidate.status === selectedStatus
            );
            setCandidates(filteredCandidates);
        } else {
            setCandidates(candidates);
        }
    }, [selectedStatus, candidates]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isDropdownClick = event.target.closest('[data-dropdown]');
            if (!isDropdownClick && openDropdownId) {
                setOpenDropdownId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdownId]);

    useEffect(() => {
        if (jobId) {
            fetchCandidates();
        }
    }, [jobId, filterCriteria, selectedStatus, selectedScreeningStatus]);
    
    const FilterDropdown = () => (
        <Box mt={1.7} display="flex" alignItems="center" gap={2}>
            <FormControl
                variant="outlined"
                size="small"
                style={{
                    minWidth: 150,
                    maxWidth: 300,
                }}
            >
                <InputLabel htmlFor="filter-dropdown-label">Sort By</InputLabel>
                <Select
                    labelId="filter-dropdown-label"
                    value={filterCriteria}
                    onChange={handleFilterChange}
                    label="Sort By"
                >
                    <MenuItem value="score">Profile Matching Score</MenuItem>
                    <MenuItem value="status">Status</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    const ApplicationStatusDropdown = () => (
        <Box mt={1.7} display="flex" alignItems="center" gap={2}>
            <FormControl
                variant="outlined"
                size="small"
                style={{
                    minWidth: 150,
                }}
            >
                <InputLabel htmlFor="status-filter">Status</InputLabel>
                <Select
                    value={selectedStatus}
                    onChange={handleDropdownChange}
                    label="Status"
                    id="status-filter"
                    className="bg-white/30"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="sourced">Sourced</MenuItem>
                  <MenuItem value="screening">Screening</MenuItem>
                  <MenuItem value="selected">Selected</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                  <MenuItem value="interviewing">Interviewing</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );

    const ScreeningStatusDropdown = () => (
        <Box mt={1.7} display="flex" alignItems="center" gap={2}>
            <FormControl
                variant="outlined"
                size="small"
                style={{
                    minWidth: 170,
                }}
            >
            <InputLabel htmlFor="screening-status-filter">Screening Status</InputLabel>
            <Select
              value={selectedScreeningStatus}
              onChange={handleScreeningStatusChange}
              label="Screening Status"
              id="screening-status-filter"
              className="bg-white/30"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="passed">Passed</MenuItem>
              <MenuItem value="failed">Failed</MenuItem>
              <MenuItem value="awaiting Approval">Awaiting Approval</MenuItem>
            </Select>
            </FormControl>
        </Box>
    );
    const ManualReviewCard = ({ candidate }) => (
        <div className="border border-black bg-transparent rounded-lg p-4 mb-4 shadow-md">
            <div className="flex justify-between items-start mb-4">
                <div>
                    <div className="flex items-center gap-2">
                        <AlertCircle className="text-yellow-600 w-5 h-5" />
                        <h3 className="font-semibold text-lg text-gray-800">{candidate.name}</h3>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">Failed Screening Criteria</p>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => updateScreeningStatus(jobId, candidate.candidateId, "Passed")}
                        className="px-4 py-2 bg-transparent text-black border-2 border-black rounded-md shadow-md hover:bg-gray-100 hover:text-green-600"
                    >
                        Pass
                    </button>
                    {candidate.screeningStatus !== "Failed" && (
                        <button
                            onClick={() => updateScreeningStatus(jobId, candidate.candidateId, "Failed")}
                            className="px-4 py-2 bg-transparent text-black border-2 border-black rounded-md shadow-md hover:bg-gray-100 hover:text-red-600"
                        >
                            Fail
                        </button>
                    )}
                </div>
            </div>
            <div className="space-y-2">
                <div>
                    <h4 className="font-medium text-sm text-gray-700">Screening Failure Reason:</h4>
                    <p className="text-sm text-gray-600">{candidate.screeningFailureReason}</p>
                </div>
                <div className='flex gap-5'>
                          <div>
                            <span className="text-gray-500 text-xs">Notice Period</span>
                            <p className="font-medium text-sm">{candidate.notice_period} {candidate.notice_period === 1 ? 'month' : 'months'}</p>
                          </div>
                          <div>
                            <span className="text-gray-500 text-xs">Expected CTC</span>
                            <p className="font-medium text-sm">₹{candidate.expected_ctc} INR</p>
                          </div>
                          </div>
                <div className="grid  gap-4">
                    <div>
                        <h4 className="font-medium text-sm text-gray-700">Profile Matching Score:</h4>
                        <div className="flex items-center mt-1">
                            <div className="flex-1 bg-transparent border-2 border-black rounded-full h-2 ">
                                <div
                                    className="bg-[#9ca3af] h-2 rounded-full"
                                    style={{
                                        width: `${candidate.profileMatchingScore}%`,
                                        backgroundColor: '#9ca3af',
                                        height: '100%',
                                        borderRadius: '9999px',
                                    }}
                                ></div>
                            </div>
                            <span className="ml-2 text-sm text-gray-600">{candidate.profileMatchingScore}%</span>
                        </div>
                    </div>
                    {candidate.expectedCTC && (
                        <div>
                            <h4 className="font-medium text-sm text-gray-700">Expected CTC:</h4>
                            <p className="text-sm text-gray-600">₹{candidate.expected_ctc} INR</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const ProfileModal = ({ candidate, onClose }) => (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center ">
            <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto relative p-8">
                <div className="flex justify-end gap-5 mb-4">
                <button
                onClick={generatePDF}
                className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md flex items-center gap-2"
                title="Download PDF"
                >
                    <Download className="w-6 h-6" />
                    <span>Download PDF</span>
                </button>
                <button
                    onClick={onClose}
                    className="text-gray-600 hover:text-gray-900 hover:bg-none"
                    title="Close"
                >
                    <X className="w-8 h-8" />
                </button>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start mb-4">
                <div className="flex-shrink-0">
                    {candidate.profile_pic_url ? (
                        <img
                            src={candidate.profile_pic_url}
                            alt={candidate.first_name}
                            className="w-32 h-32 rounded-full object-cover border-4 border-blue-100"
                        />
                    ) : (
                        <UserCircle2 className="w-32 h-32 text-gray-300" />
                    )}
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 flex-grow">
                    {currentCandidate && (
                        <>
                            <h2 className="text-2xl font-bold text-gray-800">
                                {currentCandidate.first_name} {currentCandidate.last_name} (Exp - {candidate.Experience}  {candidate.Experience === 1 ? 'yr' : 'yrs'})
                            </h2>
                            <p className="text-gray-600">{currentCandidate.occupation}</p>
                        </>
                    )}
                    {currentCandidate && currentCandidate.personal_emails?.length > 0 ? (
                        <div className="text-sm text-gray-600 flex items-center mt-2">
                            <Mail className="w-4 h-4 mr-2 text-blue-500" />
                            <a
                                href={`mailto:${currentCandidate.personal_emails}`}
                                className="hover:text-blue-700 transition-colors"
                            >
                                {currentCandidate.personal_emails}
                            </a>
                        </div>
                    ) : (
                        <p className="text-sm text-gray-600">No Email available</p>
                    )}
                    {currentCandidate && currentCandidate.personal_numbers?.length > 0 ? (
                        <div className="text-sm text-gray-600 flex items-center mt-2">
                            <Phone className="w-4 h-4 mr-2 text-blue-500" />
                            <a
                                href={`tel:${currentCandidate.personal_numbers[0]}`}
                                className="hover:text-blue-700 transition-colors"
                            >
                                {currentCandidate.personal_numbers[0]}
                            </a>
                        </div>
                    ) : (
                        <p className="text-sm text-gray-600">No phone number available</p>
                    )}
                    {currentCandidate?.public_identifier && (
                        <a
                            href={`https://www.linkedin.com/in/${currentCandidate.public_identifier}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700 flex items-center mt-2"
                        >
                            <Linkedin className="w-5 h-5 mr-2" />
                            LinkedIn
                        </a>
                    )}
                </div>
            </div>
                <div className="grid md:grid-cols-2 gap-6">
                    {currentCandidate?.education && currentCandidate.education.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Education</h3>
                            {currentCandidate.education.map((edu, index) => (
                                <div key={index} className="mb-4 pb-4 border-b last:border-b-0">
                                    <h3 className="font-bold text-gray-800">{edu.school}</h3>
                                    <p className="text-gray-600">
                                        {edu.degree_name || edu.field_of_study}
                                    </p>
                                    <p className="text-gray-600 text-sm">
                                        {edu.starts_at && edu.starts_at.month
                                            ? `${edu.starts_at.month}/${edu.starts_at.year}`
                                            : edu.starts_at?.year || "N/A"}{" "}
                                        -
                                        {edu.ends_at && edu.ends_at.month
                                            ? `${edu.ends_at.month}/${edu.ends_at.year}`
                                            : edu.ends_at?.year || "Present"}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                    {currentCandidate?.skills && currentCandidate?.skills.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Skills</h3>
                            <div className="flex flex-wrap gap-2">
                                {currentCandidate.skills.map((skill, index) => (
                                    <span
                                        key={index}
                                        className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded"
                                    >
                                        {skill}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {currentCandidate?.experiences && currentCandidate?.experiences.length > 0 && (
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-4">Experience</h3>
                        <div className="space-y-4">
                            {currentCandidate.experiences.map((exp, index) => (
                                <div key={index} className="border-b pb-4 last:border-b-0">
                                    <p className="font-medium">{exp.title}</p>
                                    <p className="text-gray-600">{exp.company}</p>
                                    <p className="text-sm text-gray-500">{exp.location}</p>
                                    <p className="text-gray-600 text-sm">
                                        {exp.starts_at && exp.starts_at.month
                                            ? `${exp.starts_at.month}/${exp.starts_at.year}`
                                            : exp.starts_at?.year || "N/A"}{" "}
                                        -
                                        {exp.ends_at && exp.ends_at.month
                                            ? `${exp.ends_at.month}/${exp.ends_at.year}`
                                            : exp.ends_at?.year || "Present"}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {currentCandidate?.certifications && currentCandidate?.certifications.length > 0 && (
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold mb-4">Certifications</h3>
                        <div className="space-y-4">
                            {currentCandidate.certifications.map((cert, index) => (
                                <div key={index} className="mb-2">
                                    <h3 className="font-bold text-gray-800">{cert.name}</h3>
                                    <div className="flex gap-2">
                                        <p className="text-gray-600">{cert.authority}</p>
                                        {cert.url && (
                                            <a href={cert.url}>
                                                <Link className="w-4 h-4 mr-2 text-blue-500" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    const generateAllProfilesPDF = async () => {
        const printWindow = window.open('', '', 'height=600,width=800');
        const getCandidateData = async (candidateId) => {
            try {
                const response = await axios.get(`${host}/getCandidateProfileData`, {
                    params: { _id: candidateId },
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });
                return response.data;
            } catch (error) {
                console.error("Error fetching candidate data:", error);
                return null;
            }
        };
        const allProfiles = await Promise.all(
            candidates.map(candidate => getCandidateData(candidate.candidateId))
        );
        const validProfiles = allProfiles.filter(profile => profile !== null);
        const profilesHTML = validProfiles.map(profile => `
          <div class="profile-page" style="page-break-after: always; margin-bottom: 30px;">
            <div class="header">
              <div class="title">${profile?.first_name} ${profile?.last_name}</div>
              <div class="subtitle">${profile?.occupation || ''}</div>
              ${profile?.personal_emails ? `<div>${profile.personal_emails}</div>` : ''}
              ${profile?.personal_numbers ? `<div>${profile.personal_numbers[0]}</div>` : ''}
            </div>
            ${profile?.education && profile.education.length > 0 ? `
              <div class="section">
                <h2>Education</h2>
                ${profile.education.map(edu => `
                  <div class="education-item">
                    <div><strong>${edu.school}</strong></div>
                    <div>${edu.degree_name || edu.field_of_study}</div>
                    <div>${edu.starts_at?.year || 'N/A'} - ${edu.ends_at?.year || 'Present'}</div>
                  </div>
                `).join('')}
              </div>
            ` : ''}
            ${profile?.skills && profile.skills.length > 0 ? `
              <div class="section">
                <h2>Skills</h2>
                <div class="skills-container">
                  ${profile.skills.map(skill => `
                    <span class="skill-tag">${skill}</span>
                  `).join('')}
                </div>
              </div>
            ` : ''}
            ${profile?.experiences && profile.experiences.length > 0 ? `
              <div class="section">
                <h2>Experience</h2>
                ${profile.experiences.map(exp => `
                  <div class="experience-item">
                    <div><strong>${exp.title}</strong></div>
                    <div>${exp.company}</div>
                    <div>${exp.location}</div>
                    <div>${exp.starts_at?.year || 'N/A'} - ${exp.ends_at?.year || 'Present'}</div>
                  </div>
                `).join('')}
              </div>
            ` : ''}
            ${profile?.certifications && profile.certifications.length > 0 ? `
              <div class="section">
                <h2>Certifications</h2>
                ${profile.certifications.map(cert => `
                  <div class="certification-item">
                    <div><strong>${cert.name}</strong></div>
                    <div>${cert.authority}</div>
                  </div>
                `).join('')}
              </div>
            ` : ''}
          </div>
        `).join('');
        printWindow.document.write(`
          <html>
            <head>
              <title>All Candidate Profiles</title>
              <style>
                body { font-family: Arial, sans-serif; padding: 20px; }
                .profile-page { margin-bottom: 30px; }
                .header { margin-bottom: 20px; }
                .section { margin-bottom: 15px; }
                .title { font-size: 24px; font-weight: bold; margin-bottom: 5px; }
                .subtitle { color: #666; margin-bottom: 10px; }
                .skills-container { display: flex; flex-wrap: wrap; gap: 5px; }
                .skill-tag { background-color: #dbeafe; color: #1e40af; padding: 4px 8px; border-radius: 4px; }
                .experience-item, .education-item { margin-bottom: 15px; padding-bottom: 15px; border-bottom: 1px solid #eee; }
                @media print {
                  .profile-page { page-break-after: always; }
                  .profile-page:last-child { page-break-after: auto; }
                }
              </style>
            </head>
            <body>
              ${profilesHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 250);
    };
    const BatchDownloadButton = () => (
        <div className='flex justify-end pb-1 mb-1'>
            <button
                onClick={generateAllProfilesPDF}
                className="flex items-center gap-2 px-4 py-2 bg-transparent text-black border border-black rounded-lg shadow cursor-pointer transition duration-300 hover:bg-gray-100"
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                }}
            >
                <Download className="w-5 h-5" />
                Download All Profiles
            </button>
        </div>

    );

    return (
        <div className="p-4 w-full min-h-screen mx-auto bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
          <div className="max-w-7xl mx-auto">
            {/* Main Header */}
            <div className="mb-6">
              <h1 className="text-3xl font-bold mb-2">Candidates for {jobrole} Position</h1>
            </div>
    
            {/* Tab Navigation */}
            <div className="flex gap-4 mb-6">
              <button
                onClick={() => setActiveTab('all')}
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  activeTab === 'all'
                    ? 'bg-black text-white shadow-lg'
                    : 'bg-white/30 backdrop-blur-sm hover:bg-black/10'
                }`}
              >
                All Candidates ({candidates.length})
              </button>
              {services && services.includes('screening') && (
                <button
                  onClick={() => setActiveTab('manual')}
                  className={`px-6 py-2 rounded-full transition-all duration-300 ${
                    activeTab === 'manual'
                      ? 'bg-black text-white shadow-lg'
                      : 'bg-white/30 backdrop-blur-sm hover:bg-black/10'
                  }`}
                >
                  Manual Review ({manualReviewCandidates.length + screeningFailedCandidates.length})
                </button>
              )}
            </div>
    
            {activeTab === 'manual' ? (
              <div className="space-y-4">
                <Card className="bg-white/30 backdrop-blur-sm shadow-lg">
                  <CardHeader title="Awaiting Approval"/>
                  <CardContent className="space-y-4">
                    {manualReviewCandidates.map((candidate) => (
                      <ManualReviewCard key={candidate.candidateId} candidate={candidate} />
                    ))}
                    {manualReviewCandidates.length === 0 && (
                      <div className="text-center py-4 text-gray-500">
                        No candidates for manual review
                      </div>
                    )}
                  </CardContent>
                </Card>
    
                <Card className="bg-white/30 backdrop-blur-sm shadow-lg">
                  <CardHeader title="Failed Screening"/>
                  <CardContent className="space-y-4">
                    {screeningFailedCandidates.map((candidate) => (
                      <ManualReviewCard key={candidate.candidateId} candidate={candidate} />
                    ))}
                    {screeningFailedCandidates.length === 0 && (
                      <div className="text-center py-4 text-gray-500">
                        No failed screening candidates
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            ) : (
              <div className="space-y-4">
                {/* Controls Section */}
                <Card className="bg-white/30 backdrop-blur-sm shadow-lg">
                  <CardContent className="p-4">
                    <div className="flex flex-wrap items-center gap-4">
                      <div className="flex-1 flex flex-wrap gap-4">
                        {services && services.length === 1 && services.includes('sourcing') && (
                            <ApplicationStatusDropdown/>
                        )}
                        <FilterDropdown />
                        {activeTab === 'all' && services && services.includes('screening') && (
                          <ScreeningStatusDropdown/>
                        )}
                      </div>
                      <BatchDownloadButton />
                    </div>
                  </CardContent>
                </Card>
    
                {/* Candidates Grid */}
                <div className="grid grid-cols-1 gap-4">
                {candidates.length <= 0 ? (
      <Card className="bg-white/30 backdrop-blur-sm border-dashed border-2">
      <CardContent className="p-12 flex flex-col items-center justify-center text-center">
        <div className="w-16 h-16 bg-black/5 rounded-full flex items-center justify-center mb-4">
          <Users className="w-8 h-8 text-gray-400" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          No Candidates Yet
        </h3>
        <p className="text-sm text-gray-500 max-w-sm">
          Your candidates will appear here once they're added to the system. You'll be able to view their profiles and track their application status.
        </p>
      </CardContent>
    </Card>
                ):(
          candidates.map((candidate, index) => (
            <Card 
              key={candidate.candidateId || index}
              className="bg-white/30 backdrop-blur-sm hover:shadow-lg transition-all duration-300"
            >
              <CardContent className="p-4">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h2 className="text-lg font-semibold">{candidate.name}</h2>
                  </div>
                  <StatusDropdown candidate={candidate} services={services} />
                </div>

                <div className="flex gap-6">
                  {/* Left Column - Score and Summary */}
                  <div className="flex-1">
                    <div className="mb-4">
                      <h3 className="text-sm font-medium text-gray-600 mb-1">
                        Profile Matching Score
                      </h3>
                      <div className="flex items-center gap-2">
                        <div className="flex-1 h-2 bg-black/5 rounded-full">
                          <div
                            className="h-full bg-black rounded-full transition-all duration-300"
                            style={{ width: `${candidate.profileMatchingScore}%` }}
                          />
                        </div>
                        <span className="text-sm font-medium">
                          {candidate.profileMatchingScore}%
                        </span>
                      </div>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-gray-600 mb-1">
                        Profile Summary
                      </h3>
                      <p className="text-sm text-gray-600">
                        {candidate.profileSummary}
                      </p>
                    </div>
                  </div>

                  {/* Right Column - Details and Button */}
                  <div className="w-72 flex flex-col">
                    {services && services.includes('screening') && (
                      <div className="space-y-3 mb-4">
                        {candidate.notice_period && (
                          <div>
                            <span className="text-gray-500 text-xs">Notice Period</span>
                            <p className="font-medium text-sm">{candidate.notice_period} {candidate.notice_period === 1 ? 'month' : 'months'}</p>
                          </div>
                        )}
                        {candidate.expected_ctc && (
                          <div>
                            <span className="text-gray-500 text-xs">Expected CTC</span>
                            <p className="font-medium text-sm">₹{candidate.expected_ctc} INR</p>
                          </div>
                        )}
                        {candidate.screeningStatus && (
                          <div>
                            <span className="text-gray-500 text-xs">Screening Status</span>
                            <p className="font-medium text-sm">{candidate.screeningStatus}</p>
                          </div>
                        )}
                      </div>
                    )}
                    
                    <button
                      onClick={() => {
                        setCurrentCandidate(candidate);
                        ViewCandidateProfile(candidate.candidateId);
                        setShowProfileModal(true);
                      }}
                      className="w-full px-4 py-2 bg-black text-white rounded-lg hover:bg-black/80 transition-colors duration-300 text-sm font-medium"
                    >
                      View Full Profile
                    </button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        )}
        </div>
              </div>
            )}
    
            {showProfileModal && currentCandidate && (
              <ProfileModal
                candidate={currentCandidate}
                onClose={() => {
                  setShowProfileModal(false);
                  setCurrentCandidate(null);
                }}
              />
            )}
          </div>
        </div>
      );
};
export default CandidateList;


