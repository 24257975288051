import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import axios from "axios";
import "../css/loginPage.css";
import { Brain, Check, Bot, ChartBar } from "lucide-react";
import host from "../global";

const setupAxiosInterceptors = (navigate) => {
 
  if (axios.interceptors.response.handlers?.length > 0) {
    axios.interceptors.response.eject(axios.interceptors.response.handlers[0]);
  }

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    
    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (originalRequest.url?.includes('/api/candidate/login')) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const accessToken = localStorage.getItem('accessToken');
          const refreshToken = localStorage.getItem('refreshToken');

          if (!refreshToken) {
            throw new Error('No refresh token available');
          }

          const response = await axios.post(`${host}/api/candidate/refresh`, {
            access_token: accessToken,
            refresh_token: refreshToken
          });

          const { access_token, refresh_token } = response.data;
          
          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('refreshToken', refresh_token);
          
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
          
          processQueue(null, access_token);
          isRefreshing = false;
          
          return axios(originalRequest);

        } catch (refreshError) {
          processQueue(refreshError, null);
          isRefreshing = false;

          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          delete axios.defaults.headers.common['Authorization'];
          navigate("/candidate-login");
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
};

function CandidateLogin() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [registrationEmail, setRegistrationEmail] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [currentFeature, setCurrentFeature] = useState(0);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  const features = [
    {
      icon: <Brain className="w-8 h-8" />,
      title: "AI-Powered Matching",
      description: "Smart algorithms that connect the right talent with the right opportunities",
      stats: "93% better matches",
    },
    {
      icon: <Bot className="w-8 h-8" />,
      title: "Intelligent Screening",
      description: "Automated behavioral and technical assessments",
      stats: "75% time saved",
    },
    {
      icon: <ChartBar className="w-8 h-8" />,
      title: "Predictive Analytics",
      description: "Data-driven insights for informed hiring decisions",
      stats: "88% better retention",
    },
  ];

  const startSlider = () => {
    intervalRef.current = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 3000);
  };

  const stopSlider = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    setupAxiosInterceptors(navigate);
    startSlider();
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      navigate("/candidate-dashboard", { replace: true });
    }
    return () => clearInterval(intervalRef.current);
  }, [navigate]);

  const handleSendVerificationEmail = async (email) => {
    try {
      const response = await axios.post(`${host}/api/candidate/send-verification`, {
        email: email
      });
      setSuccess("Verification link sent to your email. Please check your inbox.");
      setRegistrationEmail(email);
    } catch (err) {
      setError(err.response?.data?.detail || "Error sending verification email");
    }
  };

  const handleMouseEnter = () => {
    stopSlider();
  };

  const handleMouseLeave = () => {
    startSlider();
  };

  const handleDotClick = (index) => {
    stopSlider();
    setCurrentFeature(index);
    startSlider();
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setError("");
    setSuccess("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post(`${host}/api/candidate/login`, {
        email: formData.email,
        password: formData.password,
      });

      const { access_token, refresh_token } = response.data;
      if (access_token && refresh_token) {
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('userEmail', formData.email);
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        navigate("/candidate-dashboard", { replace: true });
      }
    } catch (err) {
      setError(err.response?.data?.detail || "Login failed");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      setError("Please enter your email address");
      return;
    }
    try {
      await axios.post(`${host}/api/candidate/forgot-password`, {
        email: formData.email
      });
      setSuccess("Password reset link sent to your email");
    } catch (err) {
      setError(err.response?.data?.detail || "Error sending reset link");
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (isRegistering) {
      const passwordError = validatePassword(formData.password);
      if (passwordError) {
        setError(passwordError);
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setError("Passwords do not match");
        return;
      }
    }

    try {
      if (isRegistering) {
        const registerResponse = await axios.post(`${host}/api/candidate/complete-registration`, {
          email: formData.email,
          password: formData.password,
        });

        if (registerResponse.data.success) {
          setError("Registration successful! Please sign in.");
          setIsRegistering(false);
          setFormData({ email: "", password: "", confirmPassword: "" });
        }
      } else {
        console.log("Attempting login..");
        const loginResponse = await axios.post(`${host}/api/candidate/login`, {
          email: formData.email,
          password: formData.password,
        });

        console.log("Login response:", loginResponse.data)

        const { access_token, refresh_token } = loginResponse.data;
        if (access_token && refresh_token) {
          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('refreshToken', refresh_token);
          localStorage.setItem('userEmail', formData.email);
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          navigate("/candidate-dashboard", { replace: true });
        } else {
          setError("Invalid response from server");
        }
      }
    } catch (err) {
      console.error("Full error object:", err);
  
  
  let errorMessage = "An error occurred. Please try again later.";
  
  if (err.response && err.response.data && err.response.data.detail) {
    errorMessage = err.response.data.detail;
  } else if (err.message) {
    errorMessage = err.message;
  }
      
      
  if (err.response?.data?.detail) {

    errorMessage = err.response.data.detail;
  }
      setError(errorMessage);
      if (err.response?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        delete axios.defaults.headers.common['Authorization'];
      }
    }
  };

  return (
    <div className="min-h-screen w-full flex loginpage">
      <div className="hidden lg:flex w-1/2 bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] p-12 relative overflow-hidden">
        <div className="relative z-10 text-gray-800 w-full max-w-xl mx-auto flex flex-col justify-center">
          <div className="mb-10 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center space-x-3 mb-2">
              <h1 className="text-5xl font-bold tracking-tight bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
                GenieHire
              </h1>
            </div>
            <p className="text-gray-600 text-lg">Find Your Dream Job</p>
          </div>

          <div className="overflow-hidden mb-8">
            <h2 className="text-6xl font-bold leading-tight animate-fade-in-up">
              Connect with
              <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#3ECFDA] to-[#0952DA]">
                Top Employers
              </span>
            </h2>
          </div>

          <div className="relative h-64">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full p-6 rounded-2xl backdrop-blur-lg bg-white/40 border border-white/40 transition-all duration-300 transform ${
                  index === currentFeature
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-8"
                }`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex items-start space-x-4 mb-4">
                  <div className="p-3 bg-white/60 rounded-xl">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold mb-2 text-gray-800">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 text-lg mb-4">
                      {feature.description}
                    </p>
                    <div className="flex items-center space-x-2 text-sm">
                      <Check className="w-5 h-5 text-blue-400" />
                      <span className="font-semibold text-gray-700">
                        {feature.stats}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center space-x-[7px] -mt-8">
            {features.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full cursor-pointer ${
                  index === currentFeature ? "bg-[#0952DA]" : "bg-[#3ECFDA]"
                } transition-all duration-300`}
                onClick={() => handleDotClick(index)}
                style={{ zIndex: 20 }}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md p-6">
          <div className="text-center">
            <div className="mb-12 lg:hidden">
              <img
                src="./GenieHire-logo-black.png"
                alt="GenieHire.ai"
                className="w-[160px] mx-auto"
              />
            </div>
            <h2 className="text-3xl font-bold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#3ECFDA] to-[#0952DA]">
              {isRegistering ? "Create Account" : "Welcome Back"}
            </h2>
            <p className="text-gray-600 mb-8">
              {isRegistering ? "Join our talent network" : "Sign in to your candidate portal"}
            </p>
          </div>

          {isRegistering ? (
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSendVerificationEmail(formData.email);
            }}>
              <p className="text-gray-600 mb-2">Email</p>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                required
              />
              
              {error && <div className="text-red-500 text-sm">{error}</div>}
              {success && <div className="text-green-500 text-sm">{success}</div>}

              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white p-3 rounded-full hover:opacity-90 transition-opacity font-semibold mt-4"
              >
                Verify Email
              </button>
            </form>
          ) : (
            <form onSubmit={handleLogin}>
              <p className="text-gray-600 mb-2">Email</p>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                required
              />
              <p className="text-gray-600 mb-2">Password</p>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
                className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                required
              />

              {error && <div className="text-red-500 text-sm">{error}</div>}
              {success && <div className="text-green-500 text-sm">{success}</div>}

              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white p-3 rounded-full hover:opacity-90 transition-opacity font-semibold mt-4"
              >
                Sign In
              </button>
            </form>
          )}

          {!isRegistering && (
            <button
              onClick={handleForgotPassword}
              className="w-full text-sm text-transparent bg-clip-text bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] p-2 rounded-full hover:opacity-90 transition-opacity font-semibold mt-4 hover:bg-none hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] hover:text-md hover:font-bold"
            >
              Forgot your password?
            </button>
          )}

          <div className="text-center mt-6">
            <button
              onClick={() => {
                setIsRegistering(!isRegistering);
                setError("");
                setSuccess("");
                setFormData({ email: "", password: "" });
              }}
              className="w-full text-md text-transparent bg-clip-text bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] p-2 rounded-full hover:opacity-90 transition-opacity font-semibold hover:bg-none hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] hover:text-md hover:font-bold"
            >
              {isRegistering
                ? "Already have an account? Sign In"
                : "Need an account? Register"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateLogin;