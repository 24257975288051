import React, { useState, useEffect } from "react";
import "../../css/CandidatePortal.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import host from "../../global.jsx";
import { toast } from "react-toastify";
import Timer from "./Timer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

export default function Component() {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const [question, setQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [totalMCQs, setTotalMCQs] = useState(0);
  const [MCQTimeLimit, setMCQTimeLimit] = useState(90);
  const [stopTest, setStopTest] = useState(false);
  const [mcq_startTime, setMCQStartTime] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    handleTimeUp();
  };

  useEffect(() => {
    const getCandidateStatus = async () => {
      try {
        const startDateTime = new Date().toUTCString();
        const response = await axios.get(`${host}/get_mcq_timelimit`, {
          params: {
            interview_id: interview_id,
            startDateTime: startDateTime,
          },
        });
        setMCQStartTime(new Date(response.data.mcq_start_time));
        const timeLimitInSeconds = response.data.mcq_time_limit * 60;
        const startTime = new Date(response.data.mcq_start_time);
        const startTimeInSeconds = Math.floor(startTime.getTime() / 1000);
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
        const remainingSeconds = timeLimitInSeconds - elapsedSeconds;
        setMCQTimeLimit(remainingSeconds);
        setQuestion(response.data.question);
        setOptions(response.data.options);
        setTotalMCQs(response.data.total_mcqs);
        setCurrentQuestionIndex(response.data.currentQuestionIndex);
        setAnsweredQuestions(response.data.answered_questions);
        console.log(response.data.answered_questions);
        if (response.data.mcq_status === "Complete") {
          setStopTest(true);
        }
      } catch (error) {
        toast.error("Failed to fetch candidate status");
        console.error(error);
      }
    };

    getCandidateStatus();
  }, [interview_id]);

  const handleOptionChange = (option) => {
    if (selectedOption === option) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleTimeUp = async () => {
    const startTimeInSeconds = Math.floor(mcq_startTime.getTime() / 1000);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
    let timeTaken = 0;
    if (elapsedSeconds > MCQTimeLimit) {
      timeTaken = formatTime(MCQTimeLimit);
    } else {
      timeTaken = formatTime(elapsedSeconds);
    }

    try {
      await axios.post(`${host}/save_mcqtest_result`, null, {
        params: {
          interview_id: interview_id,
          time_taken: timeTaken,
          selectedOption,
        },
      });
      toast.success("Test submitted successfully!");
      setTimeout(() => {
        navigate(`/candidate/${interview_id}/testhomepage`);
      }, 2000);
    } catch (error) {
      toast.error("Error Submitting Test");
    }
  };

  const handleNextQuestion = async () => {
    setQuestion(null);
    if (selectedOption != null) {
      setAnsweredQuestions((prevItems) => [...prevItems, currentQuestionIndex]);
    }
    try {
      const response = await axios.get(`${host}/get_mcqs/${interview_id}`, {
        params: { selectedOption },
      });
      setSelectedOption(null);
      setQuestion(response.data.question);
      setOptions(response.data.options);
      setTotalMCQs(response.data.total_mcqs);
      setCurrentQuestionIndex(response.data.currentQuestionIndex);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (stopTest) {
    return (
      <div className="stoptestpage">
        <div id="stoptest">
          <h2>Test For This Candidate ID Is Already Conducted</h2>
          <h3>Only One Attempt Is Allowed</h3>
          <h3>You Can Close This Window Now</h3>
        </div>
      </div>
    );
  }

  return (
    <>
      <Timer timeLimit={MCQTimeLimit} onTimeUp={handleTimeUp} />
      <div className="min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] p-4 ">
        <div className="min-h-[80vh] w-full mx-auto bg-white/30 backdrop-blur-lg rounded-3xl shadow-2xl p-4">
          <div className="flex gap-8">
            {/* Main Content Area */}
            <div className="flex-1">
              {question ? (
                <div key={currentQuestionIndex} className="space-y-4">
                  {/* Question Display */}
                  <div className="bg-gray-50 rounded-2xl p-4 shadow-inner">
                    <pre className="font-sans whitespace-pre-wrap text-gray-800 text-md">
                      {question}
                    </pre>
                  </div>

                  {/* Options */}
                  <form className="space-y-2">
                    {[1, 2, 3, 4].map((index) => {
                      const optionKey = `option${index}`;
                      const option = options[optionKey];
                      const isSelected = selectedOption === option;
                      return (
                        <div
                          key={index}
                          className={`transform transition-all duration-200 hover:scale-[1.01] ${
                            isSelected
                              ? "bg-gradient-to-r from-[#3ECFDA]/30 to-[#0952DA]/30 shadow-lg"
                              : "bg-white hover:bg-green-100"
                          } rounded-xl shadow`}
                        >
                          <label className="flex items-center cursor-pointer p-2 w-full">
                            <div
                              className={`w-10 h-10 rounded-full flex items-center justify-center font-bold mr-4 ${
                                isSelected
                                  ? "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white"
                                  : "bg-gray-100 text-gray-600"
                              }`}
                            >
                              {String.fromCharCode(64 + index)}
                            </div>
                            <div className="text-gray-700 flex-grow">
                              <input
                                type="radio"
                                name={`question_${currentQuestionIndex + 1}`}
                                value={option}
                                checked={isSelected}
                                className="hidden"
                                onClick={() => handleOptionChange(option)}
                              />
                              {option}
                            </div>
                          </label>
                        </div>
                      );
                    })}
                  </form>
                </div>
              ) : (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#3ECFDA]"></div>
                </div>
              )}
            </div>

            {/* Question Navigator */}
            <div className="w-72 border-l border-[#3ECFDA]/30 pl-6">
              <div className="grid grid-cols-5 gap-3">
                {question ? (
                  Array.from({ length: totalMCQs }).map((_, qId) => {
                    const isCurrentQuestion = qId === currentQuestionIndex;
                    const isAnswered = answeredQuestions.includes(qId);

                    return (
                      <div
                        key={qId}
                        className={`aspect-square rounded-2xl flex items-center justify-center font-medium text-sm transition-all duration-200 cursor-not-allowed ${
                          isCurrentQuestion
                            ? "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white shadow-lg scale-110 border border-[#3ECFDA]"
                            : isAnswered
                            ? "bg-gradient-to-r from-[#28A745] to-[#218838] text-white border border-[#28A745]"
                            : "bg-white text-gray-600 hover:bg-gray-50 border border-gray-300"
                        }`}
                      >
                        {qId + 1}
                      </div>
                    );
                  })
                ) : (
                  <div className="col-span-5 flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#3ECFDA]"></div>
                  </div>
                )}
              </div>
              {question && (
                <div className="flex justify-center mt-8 gap-4 w-full">
                  <button
                    className={`w-1/2 py-3 px-6 rounded-xl font-medium shadow-lg transition-all duration-200 ${
                      currentQuestionIndex === totalMCQs - 1
                        ? "bg-gray-400 text-gray-300 pointer-events-none"
                        : "bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white hover:shadow-xl hover:scale-105"
                    }`}
                    onClick={() => handleNextQuestion()}
                    disabled={currentQuestionIndex === totalMCQs - 1}
                  >
                    Next
                  </button>
                  <button
                    className="w-1/2 bg-gradient-to-r from-red-500 to-red-700 text-white py-3 px-8 rounded-xl font-medium shadow-lg hover:from-red-700 hover:to-red-500 hover:shadow-xl transition-all duration-200 hover:scale-105"
                    onClick={handleOpen}
                  >
                    Submit
                  </button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle className="text-xl font-semibold text-gray-900">
                      {"Are You Sure?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText className="text-gray-600 mt-2">
                        Your test will end when you submit. This action cannot
                        be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions className="space-x-2">
                      <Button
                        onClick={handleClose}
                        className="bg-gray-100 hover:bg-gray-200 text-gray-700 border-none"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleConfirm}
                        className="bg-blue-600 hover:bg-blue-700 text-white"
                        autoFocus
                      >
                        Yes, Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
