import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Briefcase, Code, Edit, MapPin, ChevronRight, Bell } from 'lucide-react';
import host from '../global';
import axios from "axios";
import Loader from "../components/Loader";

const CandidateDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const [assessmentData, setAssessmentData] = useState(null);
  
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp * 1000 < Date.now();
    } catch (error) {
      return true;
    }
  };

  const validateTokens = async() => {
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshExpired = isTokenExpired(refreshToken);
    const accessToken = localStorage.getItem('accessToken');
    const accessExpired = isTokenExpired(accessToken);
  
    if (refreshExpired) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      navigate('/candidate-login');
      return null;
    }
  
    if(accessExpired && !refreshExpired) {
      try {
        const response = await axios.post(`${host}/api/candidate/refresh`, {
          access_token: accessToken,
          refresh_token: refreshToken
        });
  
        const { access_token, refresh_token } = response.data;
        
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        
        return access_token;
      } catch (error) {
        console.error('Token refresh failed:', error);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/candidate-login');
        return null;
      }
    }
    
    return accessToken;
  };

  useEffect(() => {
    validateTokens();
    const tokenCheckInterval = setInterval(validateTokens, 30000);
    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/candidate-login');
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const email = localStorage.getItem("userEmail");
        const validToken = await validateTokens();
        if (!validToken) {
          setError("Authentication Failed! Please Log In Again");
          return;
        };
    
        const response = await fetch(`${host}/api/user-profile/${email}`, {
          headers: {
            'Authorization': `Bearer ${validToken}`,
            'Content-Type': 'application/json'
          },
        });
    
        if (!response.ok) {
          if (response.status === 401) {
            const newToken = await validateTokens();
            if (!newToken) {
              setError("Session expired! Please Log In again");
            };
            
            const retryResponse = await fetch(`${host}/api/user-profile/${email}`, {
              headers: {
                'Authorization': `Bearer ${newToken}`,
                'Content-Type': 'application/json'
              },
            });
            
            if (!retryResponse.ok) throw new Error(await retryResponse.text());
            const profile = await retryResponse.json();
            setData(profile);
            return;
          }
          throw new Error(await response.text());
        }
    
        const profile = await response.json();
        setData(profile);
        console.log(profile);
        console.log(profile.id);
    
      } catch (error) {
        if (error.name === 'AbortError') {
          setError("Request timed out - please try again");
        } else {
          setError(error.message || "Failed to fetch profile");
        }
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false);
      }
  };

  fetchProfile();
 
}, [navigate]);

useEffect(() => {
  const fetchAssessmentData = async () => {
    if (!data || !data._id) return; // Only proceed if we have the profile data with _id

    try {
      const validToken = await validateTokens();
      if (!validToken) {
        setError("Authentication Failed! Please Log In Again");
        return;
      }

      const response = await fetch(`${host}/api/candidate-assessment/${data._id}`, {
        headers: {
          'Authorization': `Bearer ${validToken}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          const newToken = await validateTokens();
          if (!newToken) {
            setError("Session expired! Please Log In again");
            return;
          }
          
          const retryResponse = await fetch(`${host}/api/candidate-assessment/${data._id}`, {
            headers: {
              'Authorization': `Bearer ${newToken}`,
              'Content-Type': 'application/json'
            },
          });
          
          if (!retryResponse.ok) throw new Error(await retryResponse.text());
          const assessmentInfo = await retryResponse.json();
          setAssessmentData(assessmentInfo);
          return;
        }
        throw new Error(await response.text());
      }

      const assessmentInfo = await response.json();
      setAssessmentData(assessmentInfo);
      console.log("Assessment data:", assessmentInfo);

    } catch (error) {
      console.error("Error fetching assessment data:", error);
      setError(error.message || "Failed to fetch assessment data");
    }
  };

  fetchAssessmentData();
}, [data]);



  const profileData = {
    first_name: "Rohan",
    last_name: "Ghagare",
    occupation: "Software Engineer at Allscripts",
    location: {
      city: "Pune",
      state: "Maharashtra",
      country: "India"
    },
    experience: "5 years",
    skills: [
      "JavaScript", "React.js", "Node.js", "Python",
      "MongoDB", "SQL", "AWS", "Docker"
    ],
    applications: [
      {
        role: "Senior Software Engineer",
        company: "TechCorp Inc",
        status: "Interview Scheduled",
        date: "2024-01-05"
      },
      {
        role: "Lead Developer",
        company: "Digital Solutions",
        status: "In Review",
        date: "2024-01-03"
      },
      {
        role: "Full Stack Developer",
        company: "WebTech Solutions",
        status: "Application Submitted",
        date: "2024-01-01"
      }
    ]
  };

  const getStatusColor = (status) => {
    const colors = {
      'Interview Scheduled': 'bg-green-100 text-green-800',
      'In Review': 'bg-yellow-100 text-yellow-800',
      'Application Submitted': 'bg-blue-100 text-blue-800'
    };
    return colors[status] || 'bg-gray-100 text-gray-800';
  };

  if (loading) return <Loader />;

  return (
    <div className="max-w-7xl mx-auto px-8 py-10">
      <div className="flex justify-between items-start mb-8">
        <div className="space-y-4">
          <h1 className="text-5xl font-medium mr-[120px]">Hi {data.first_name},</h1>
          <div className="space-y-1">
            <div className="flex items-center text-gray-600">
              <Briefcase className="w-4 h-4 mr-2" />
              {data.occupation}
            </div>
            <div className="flex items-center text-gray-600">
              <MapPin className="w-4 h-4 mr-2" />
              {`${data.city}, ${data.state}, ${data.country_full_name}`}
            </div>
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          Logout
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-2">
      <div className="md:col-span-2">
  <div className="border border-gray-300 rounded-2xl p-6 bg-white h-full">
    <div className="flex items-center mb-4">
      <Briefcase className="w-5 h-5 mr-2" />
      <h2 className="text-xl font-semibold">Upcoming Assessments</h2>
    </div>
    <div className="space-y-4">
      {assessmentData && assessmentData.assessments && assessmentData.assessments.length > 0 ? (
        assessmentData.assessments.map((assessment, index) => (
          <div key={index} className="border border-gray-200 rounded-xl p-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-semibold">{assessment.job_role}</h3>
                <p className="text-gray-600">{assessment.company_name}</p>
              </div>
              <span className="px-3 py-1 rounded-full text-sm bg-green-100 text-green-800">
                Assessment Scheduled
              </span>
            </div>
            <button 
              className="text-sm mt-4 px-4 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-700 hover:text-white transition-colors"
              onClick={() => navigate(`/candidate/${assessment.interviewID}/scheduletest/`)}
            >
              Start Assessment
            </button>
          </div>
        ))
      ) : (
        <div className="text-gray-500 text-center py-8">
          No scheduled assessments found.
        </div>
      )}
    </div>
  </div>
</div>
     

        <div className="space-y-6">
          <div className="border border-gray-300 rounded-2xl p-6 bg-white">
            <div className="flex items-center mb-4">
              <User className="w-5 h-5 mr-2" />
              <h2 className="text-xl font-semibold">Profile Overview</h2>
            </div>
            <div className="mb-4 p-3 bg-blue-50 rounded-lg flex items-start">
              <Bell className="w-4 h-4 text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
              <p className="text-sm text-blue-800">
                Keep your profile up to date to ensure recruiters see your latest achievements and experience.
              </p>
            </div>
            <button
              onClick={() => navigate('/candidate-edit-profile')}
              className="w-full flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Edit className="w-4 h-4 mr-2" />
              Edit Profile
              <ChevronRight className="w-4 h-4 ml-2" />
            </button>
          </div>

          <div className="border border-gray-300 rounded-2xl p-6 bg-white">
            <div className="flex items-center mb-4">
              <Code className="w-5 h-5 mr-2" />
              <h2 className="text-xl font-semibold">Skills</h2>
            </div>
            <div className="flex flex-wrap gap-2">
              {data.skills.map((skill, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDashboard;