import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import host from '../../global';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import Cookies from 'js-cookie'
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Loader';
const HrProfile = () => {
  const [hrProfiles, setHrProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");
  const accessToken = Cookies.get('accessToken');
  const fetchHrProfiles = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(`${host}/orgadmin/get_hrprofiles`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setHrProfiles(data);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching HR profiles:', error);
      setIsLoading(false)
    }
  };
  useEffect(() => {
    fetchHrProfiles();
  }, []);
  const handleAddHR = () => {
    setModalTitle('Add HR');
    setModalContent(
      <form onSubmit={handleAddHRSubmit}>
        <TextField
          label="Name"
          name="name"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Department"
          name="department"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add HR
        </Button>
      </form>
    );
    setShowModal(true);
  };
  const handleEditHR = (hrProfile) => {
    setModalTitle('Edit HR');
    setModalContent(
      <form onSubmit={(event) => handleEditHRSubmit(event, hrProfile.hrId)}>
        <TextField
          label="Name"
          name="name"
          defaultValue={hrProfile.hrName}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          defaultValue={hrProfile.hremail}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          defaultValue={hrProfile.contactNumber}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Department"
          name="department"
          defaultValue={hrProfile.HRDepartment}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Save Changes
        </Button>
      </form>
    );
    setShowModal(true);
  };
  const handleAddHRSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department'),
    };
    try {
      setIsLoading(true)
      const response = await fetch(`${host}/orgadmin/addhrprofile`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newHR),
      });
      if (response.ok) {
        const result = await response.json();
        toast.success(`HR profile added successfully!`, {});
        setShowModal(false);
        await fetchHrProfiles();
      } else {
        const errorData = await response.json();
        toast.error(`${errorData.detail || 'Unknown error'}`, {});
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`, {});
    } finally {
      setIsLoading(false)
    }
  };
  const handleEditHRSubmit = async (event, hrId) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    try {
      await axios.put(`${host}/orgadmin/updatehrprofile`, updatedHR, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          hrId: hrId,
        },
      });
      alert('HR Profile Updated Successfully');
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error updating HR profile:', error);
    }
  };
  if (isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <Authenticator>
      <div className="hr-profiles min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] rounded-lg backdrop-blur-[10px]">
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddHR}
          style={{
            float: 'right', marginBottom: '16px', background: 'rgba(255, 255, 255, 0.4)', backdropFilter: 'blur(5px)', borderRadius: '5px', color: 'black'
          }}>
          Add HR
        </Button>
        <h1 style={{ textAlign: 'center' }}>HR Profiles</h1>
        <div className="table-container">
          <div className="table-header">
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ textAlign: 'left', paddingLeft: '30px' }}>HR Name</Th>
                  {isSmallScreen ? (
                    <Th style={{ textAlign: 'Center' }}>Email</Th>
                  ) : (
                    <Th style={{ width: '400px', textAlign: 'left', paddingLeft: '130px' }}>Email</Th>
                  )}
                  <Th style={{ textAlign: 'left', paddingLeft: '60px' }}>Contact</Th>
                  <Th style={{ textAlign: 'left', paddingLeft: '50px' }}>Department</Th>
                  <Th style={{ textAlign: 'center' }}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hrProfiles.length > 0 ? (
                  hrProfiles.map((hr) => (
                    <Tr key={hr.hrId}>
                      <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>{hr.hrName}</Td>
                      {isSmallScreen ? (
                        <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}>{hr.hremail}</Td>
                      ) : (
                        <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '400px', textAlign: 'left' }}>{hr.hremail}</Td>
                      )}
                      <Td style={{ textAlign: 'left', paddingLeft: '50px' }}>{hr.contactNumber}</Td>
                      <Td style={{ textAlign: 'left', paddingLeft: '50px' }}>{hr.HRDepartment}</Td>
                      <Td>
                        <Button
                          variant="contained"
                          onClick={() => handleEditHR(hr)}
                          style={{
                            marginLeft: 'auto', marginRight: 'auto', marginBottom: '16px', background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(5px)', borderRadius: '5px', color: 'black',
                            width: 'auto', maxWidth: '180px', padding: '8px 16px', fontSize: '14px', boxSizing: 'border-box',
                          }}
                        >
                          Edit HR
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="5">No HR profiles found.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        </div>
        <Dialog open={showModal} onClose={() => setShowModal(false)}>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogContent>
            {modalContent}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Authenticator>
  );
};
export default HrProfile;
