import React, { useEffect, useRef, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import host from "../../global";
import { getCurrentUser } from "aws-amplify/auth";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Camera } from 'lucide-react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Loader";
const profile_photos_host = process.env.REACT_APP_PROFILE_PHOTOS_HOST;
const EditProfile = () => {
  const fileInputRef = useRef(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contact: "",
    profileImage: "",
    profileImageFile: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get('accessToken');
  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit, accessToken]);
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${host}/gethrdetails`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        const { userId } = await getCurrentUser();
        setProfileData({
          name: data.name,
          email: data.email,
          contact: data.contact,
          profileImage: data.profile ? `${profile_photos_host}/HRProfiles/${userId}_${data.profile}` : "",
        });
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    };
    fetchProfileData();
  }, [accessToken]);
  const handleChange = (e) => {
    const { name, files } = e.target;

    if (name === "profileImage" && files.length > 0) {
      const file = files[0];

      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
        return;
      }
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        setMessage("File size exceeds 1MB. Please upload a smaller image.");
        return;
      }
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl)
      setProfileData((prevData) => ({
        ...prevData,
        profileImage: imageUrl,
        profileImageFile: file,
      }));
      setIsLoading(true);
      setShouldSubmit(true);
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };
  const handleDeleteImage = () => {
    setProfileData((prevData) => ({
      ...prevData,
      profileImage: "",
      profileImageFile: null,
      deleteProfileImage: true,
    }));
    setIsLoading(true);
    setShouldSubmit(true);
  };
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("hrName", profileData.name);
    formData.append("hremail", profileData.email);
    formData.append("contactNumber", profileData.contact);
    if (profileData.profileImageFile) {
      formData.append("profile", profileData.profileImageFile);
    }
    if (profileData.deleteProfileImage) {
      formData.append("deleteProfileImage", "true");
    }
    console.log(profileData);
    console.log(formData);
    try {
      const response = await fetch(`${host}/hr/updateprofile`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        toast.success("Profile updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await response.json();
        console.error("Error details:", errorData);
        toast.error(
          "Failed to update profile. " +
          errorData.detail.map((err) => err.msg).join(", ")
        );
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
    setIsSubmitting(false);
  };
  const handleEditProfile = () => {
    fileInputRef.current.click();
  };
  const handleImageClick = () => {
    setIsModalOpen(true);
  };
  if (isLoading) {
    return (
      <Loader />
    )
  }
  return (
    <Authenticator>
      <div className="h-screen overflow-auto bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]" >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="edit-profile backdrop-blur-lg bg-white/30 rounded-3xl shadow-xl p-4 border border-white/20  ">
            <div className="edit-profile-container">
              <h2>Edit Profile</h2>
              <form onSubmit={handleSubmit}>
                <div className="profile-img-container relative">
                  {profileData.profileImage ? (
                    <div className="relative">
                      <img
                        src={profileData.profileImage}
                        alt="Profile"
                        className="profile-img w-24 h-24 rounded-full object-cover"
                        onClick={handleImageClick}
                      />
                      <Camera
                        className="absolute bottom-0 right-0 text-white bg-black bg-opacity-50 rounded-full p-2 cursor-pointer w-8 h-8"
                        onClick={handleImageClick}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={handleImageClick}
                      className="flex justify-center items-center w-24 h-24   rounded-full  p-2 cursor-pointer relative"
                    >
                      <AccountCircleRoundedIcon
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          fill: "#0073e6",
                        }}
                        onClick={handleImageClick}
                      />
                      <Camera
                        className="absolute bottom-0 right-0 text-white bg-black bg-opacity-50 rounded-full p-2 cursor-pointer w-8 h-8"
                        onClick={handleImageClick}
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    name="profileImage"
                    accept="image/*"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleChange}
                  />
                </div>
                <Dialog
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  PaperProps={{
                    sx: {
                      width: '37%',
                      maxWidth: '350px',
                      height: '60%',
                      borderRadius: '20px',
                      padding: '30px',
                    },
                  }}
                >
                  <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                    overflow: 'hidden'
                  }}>
                    {profileData.profileImage ? (
                      <img
                        src={profileData.profileImage}
                        alt="Profile"
                        className="w-[120px] h-[120px] rounded-full object-cover border-[8px] border-[#e5e5e5] mb-[10px] self-center"
                      />
                    ) : (
                      <AccountCircleRoundedIcon
                        className="min-w-[150px] min-h-[150px] rounded-[50%] object-cover mb-[10px] self-center"
                      />
                    )}
                    <div className="text-center font-bold text-[18px] text-[#333]">
                      {profileData.name}
                    </div>
                    <div className="text-center text-[12px] text-[#808080]">
                      Organization Admin
                    </div>
                  </DialogContent>
                  <DialogActions
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <IconButton
                      onClick={handleEditProfile}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70px",
                        height: "55px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.querySelector("svg").style.fill = "white";
                        e.currentTarget.querySelector("span").style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.querySelector("svg").style.fill = "#333";
                        e.currentTarget.querySelector("span").style.color = "#333";
                      }}
                    >
                      <EditIcon style={{ fontSize: "20px", marginBottom: "5px", color: "#333" }} />
                      <span style={{ fontSize: "10px", color: "#333" }}>Edit</span>
                    </IconButton>
                    <IconButton
                      onClick={handleDeleteImage}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70px",
                        height: "55px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: "1px solid grey",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.querySelector("svg").style.fill = "white";
                        e.currentTarget.querySelector("span").style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.querySelector("svg").style.fill = "#333";
                        e.currentTarget.querySelector("span").style.color = "#333";
                      }}
                    >
                      <DeleteIcon style={{ fontSize: "20px", marginBottom: "5px", color: "#333" }} />
                      <span style={{ fontSize: "10px", color: "#333" }}>Remove</span>
                    </IconButton>
                  </DialogActions>
                </Dialog>
                <div className="bg-transparent">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={profileData.name}
                    onChange={handleChange}
                    required
                    className="bg-transparent border-none focus:ring-0 focus:outline-none"
                  />
                </div>
                <div className="bg-transparent">
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={profileData.email}
                    onChange={handleChange}
                    required
                    className="bg-transparent border-none focus:ring-0 focus:outline-none"
                  />
                </div>
                <div className="bg-transparent">
                  <label>Contact:</label>
                  <input
                    type="text"
                    name="contact"
                    value={profileData.contact}
                    onChange={handleChange}
                    required
                    className="bg-transparent border-none focus:ring-0 focus:outline-none"
                  />
                </div>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Updating..." : "Update Profile"}
                </button>
              </form>
              {message && <p>{message}</p>}
            </div>
          </div>
        )}
      </div>
    </Authenticator>
  );
};
export default EditProfile;
