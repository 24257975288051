import React, { useEffect, useState } from "react";
import axios from "axios";
import host from "../../global.jsx";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,LineChart, Line } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem, Pagination, PaginationItem } from "@mui/material";
import Rating from "@mui/material/Rating";

const Feedback = () => {
  const [showFeedback, setShowFeedback] = useState([]);
  const [allFeedback, setAllFeedback] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);
  const [ratingDistribution, setRatingDistribution] = useState([]);
  const [criticalFeedback, setCriticalFeedback] = useState([]);
  const [positiveFeedback, setPositiveFeedback] = useState([]);
  const [overallSentiment, setOverallSentiment] = useState({
    positive: 0,
    neutral: 0,
    negative: 0
  });

  const itemsPerPage = 10;
  const accessToken = Cookies.get("accessToken");
  const [filters, setFilters] = useState({
    sortOrder: "",
  });

   // Simple sentiment analysis function
   const analyzeSentiment = (text) => {
    if (!text) return { score: 0, label: 'neutral' };
    
    const positiveWords = new Set([
      'great', 'excellent', 'good', 'amazing', 'wonderful', 'fantastic',
      'helpful', 'best', 'love', 'perfect', 'outstanding', 'awesome',
      'happy', 'pleased', 'satisfied', 'thank', 'thanks', 'impressive',
      'delightful', 'superb', 'exceptional', 'brilliant', 'favorable', 
      'out of this world', 'positive', 'uplifting', 'joyful', 'motivated',
      'encouraging', 'inspiring', 'rewarding', 'elevated', 'accomplished',
      'valuable', 'good job', 'well done', 'appreciated', 'pleasing',
      'outstanding', 'remarkable', 'extraordinary', 'incredible', 'dynamic',
      'phenomenal', 'stellar', 'refreshing', 'engaging', 'commendable',
      'impressive', 'masterful', 'motivational', 'impactful', 'successful',
      'brilliant', 'solid', 'powerful', 'polished', 'top-notch', 'world-class',
      'exceptionally good', 'fantastically helpful', 'rewarding experience',
      'highly recommend', 'incredible experience', 'above and beyond',
      'smooth', 'efficient', 'intuitive', 'quick', 'user-friendly', 'seamless',
      'easy', 'responsive', 'clear', 'accessible', 'organized', 'simple',
      'fast', 'clear instructions', 'well structured', 'well organized',
      'on time', 'up-to-date', 'convenient', 'personalized', 'interactive',
      'smooth experience', 'time-saving', 'informative', 'responsive support'
    ]);
    

    const negativeWords = new Set([
      'bad', 'poor', 'terrible', 'horrible', 'awful', 'worst',
      'disappointing', 'useless', 'hate', 'difficult', 'frustrated',
      'annoying', 'slow', 'expensive', 'waste', 'error', 'problem',
      'unhelpful', 'unsatisfactory', 'mediocre', 'regret', 'unpleasant',
      'underwhelming', 'irritating', 'uncomfortable', 'unproductive', 'discouraging',
      'stressful', 'overwhelming', 'unprofessional', 'confusing', 'challenging',
      'complicated', 'unmotivating', 'disorganized', 'disheartening', 'impossible',
      'disastrous', 'infuriating', 'unsuccessful', 'lacking', 'unqualified',
      'deficient', 'uncooperative', 'misleading', 'frustrating', 'unfit',
      'dismal', 'unresolved', 'shocking', 'negative experience', 'painful',
      'broken', 'disrupted', 'unproductive', 'unfocused', 'unwanted',
      'awkward', 'unresponsive', 'overwhelmed', 'outdated', 'uninspiring',
      'unconvincing', 'unprofessional', 'unstructured', 'chaotic', 'disturbing',
      'negative', 'unreliable', 'lacking direction', 'unfulfilled', 'disconnected',
      'lagging', 'glitchy', 'buggy', 'difficult to navigate', 'clunky', 'outdated',
      'cumbersome', 'poor performance', 'crashed', 'slow loading', 'confusing layout',
      'no support', 'unavailable', 'difficult to use', 'inconvenient', 'complicated',
      'unusable', 'unintuitive', 'hard to find', 'unhelpful resources', 'disjointed',
      'unavailable features', 'missing features', 'unsuitable', 'unsupported',
      'error message', 'unexpected behavior', 'long wait', 'limited functionality',
      'too complicated', 'lack of clarity', 'time-consuming', 'unreliable support'
    ]);
    

    const words = text.toLowerCase().split(/\W+/);
    let score = 0;

    words.forEach(word => {
      if (positiveWords.has(word)) score += 1;
      if (negativeWords.has(word)) score -= 1;
    });

    const normalizedScore = score / Math.max(words.length / 2, 1);
    
    return {
      score: normalizedScore,
      label: normalizedScore > 0.1 ? 'positive' : 
             normalizedScore < -0.1 ? 'negative' : 'neutral'
    };
  };

  const getAllFeedback = async () => {
    try {
      const response = await axios.get(`${host}/get-all-feedback`, {
        params: {
          limit: 999999,
          sort_order: filters.sortOrder
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        setAllFeedback(response.data.feedbacks);
        processData(response.data.feedbacks);
      }
    } catch (error) {
      console.error("Error fetching all feedback:", error);
      toast.error('Error fetching analytics data');
    }
  };

  const getPaginatedFeedback = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/get-all-feedback`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          sort_order: filters.sortOrder
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        setShowFeedback(response.data.feedbacks);
        setTotalFeedbacks(response.data.total_count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching paginated data:", error);
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        
        if (status === 401) {
          Cookies.remove('accessToken');
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllFeedback();
    getPaginatedFeedback();
  }, [currentPage, filters.sortOrder, accessToken]);

  const processData = (feedbackData) => {
    const distribution = Array(6).fill(0);
    feedbackData.forEach(feedback => {
      distribution[feedback.rating || 0]++;
    });

    const distributionData = distribution.map((count, index) => ({
      rating: index === 0 ? 'No Rating' : `${index} Star${index === 1 ? '' : 's'}`,
      count: count
    }));

    setRatingDistribution(distributionData);

    const feedbackWithSentiment = feedbackData.map(feedback => ({
      ...feedback,
      sentiment: analyzeSentiment(feedback.comments)
    }));

    const sentimentCounts = feedbackWithSentiment.reduce((acc, curr) => {
      if (curr.comments) {
        acc[curr.sentiment.label]++;
      }
      return acc;
    }, { positive: 0, neutral: 0, negative: 0 });

    setOverallSentiment(sentimentCounts);

    const critical = feedbackWithSentiment.filter(feedback => 
      feedback.rating !== null && 
      feedback.rating <= 2 && 
      feedback.rating > 0 && 
      feedback.comments && 
      feedback.comments.trim() !== ''
    );
    setCriticalFeedback(critical);

    const positive = feedbackWithSentiment.filter(feedback => 
      feedback.rating === 5 && 
      feedback.comments && 
      feedback.comments.trim() !== ''
    );
    setPositiveFeedback(positive);

    setAllFeedback(feedbackWithSentiment);
  };

  const getSentimentColor = (sentiment) => {
    switch(sentiment.label) {
      case 'positive': return 'text-green-600';
      case 'negative': return 'text-red-600';
      default: return 'text-gray-600';
    }
  };

  const COLORS = ['#d3d3d3', '#FF8042', '#FFBB28', '#00C49F', '#0088FE', '#8884d8'];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return percent > 0 ? (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : null;
  };

  const totalPages = Math.ceil(totalFeedbacks / itemsPerPage);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-4 px-12 mb-10">
      <h1 className="text-3xl font-bold text-center mb-8">Feedback Dashboard</h1>
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h2 className="text-xl font-semibold mb-4">Sentiment Analysis Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-lg font-medium mb-4">Sentiment Distribution</h3>
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="bg-green-50 p-4 rounded-lg">
                <div className="text-green-600 font-semibold">Positive</div>
                <div className="text-2xl font-bold">{overallSentiment.positive}</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-gray-600 font-semibold">Neutral</div>
                <div className="text-2xl font-bold">{overallSentiment.neutral}</div>
              </div>
              <div className="bg-red-50 p-4 rounded-lg">
                <div className="text-red-600 font-semibold">Negative</div>
                <div className="text-2xl font-bold">{overallSentiment.negative}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Analytics Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
        {/* Charts Section */}
        <div className="lg:col-span-1 bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Rating Distribution</h2>
          <div className="flex justify-center mb-8">
            <PieChart width={300} height={300}>
              <Pie
                data={ratingDistribution}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                fill="#8884d8"
                dataKey="count"
              >
                {ratingDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
          
          <div className="mt-8">
            <BarChart
              width={400}
              height={300}
              data={ratingDistribution}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="rating" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8">
                {ratingDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>

        {/* Positive Feedback Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 text-green-600">
            Positive Feedback (5 Stars)
          </h2>
          <div className="space-y-4 max-h-[600px] overflow-y-auto">
            {positiveFeedback.length === 0 ? (
              <p className="text-gray-500 italic">No 5-star feedback found</p>
            ) : (
              positiveFeedback.map((feedback, index) => (
                <div key={index} className="border-l-4 border-green-500 pl-4 py-2">
                  <div className="flex items-center mb-2">
                    <Rating
                      value={feedback.rating}
                      readOnly
                      size="small"
                    />
                  </div>
                  <p className="text-gray-700">{feedback.comments}</p>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Critical Feedback Section with Sentiment */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 text-red-600">
            Critical Feedback (1-2 Stars)
          </h2>
          <div className="space-y-4 max-h-[600px] overflow-y-auto">
            {criticalFeedback.map((feedback, index) => (
              <div key={index} className="border-l-4 border-red-500 pl-4 py-2">
                <div className="flex items-center justify-between mb-2">
                  <Rating value={feedback.rating} readOnly size="small" />
                  <div className="flex items-center gap-2">
                    <span className={getSentimentColor(feedback.sentiment)}>
                      {(feedback.sentiment.score >= 0 ? '+' : '') + feedback.sentiment.score.toFixed(2)}
                    </span>
                  </div>
                </div>
                <p className="text-gray-700">{feedback.comments}</p>
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* Filters Section */}
      <div className="flex justify-end mb-6">
        <FormControl
          variant="outlined"
          size="small"
          style={{ minWidth: 150, maxWidth: 150 }}
        >
          <InputLabel>Sort By Rating</InputLabel>
          <Select
            value={filters.sortOrder}
            onChange={(e) => {
              setCurrentPage(1);
              setFilters((prevFilters) => ({
                ...prevFilters,
                sortOrder: e.target.value,
              }));
            }}
            label="Sort By Rating"
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="desc">High to Low</MenuItem>
            <MenuItem value="asc">Low to High</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* Feedback Table */}
      {showFeedback.length === 0 ? (
        <div className="text-center text-gray-500">No feedback available</div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-50">
                  <th className="border-b p-4 text-left">Rating</th>
                  <th className="border-b p-4 text-left">Comments</th>
                </tr>
              </thead>
              <tbody>
                {showFeedback.map((feedback, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border-b p-4">
                      {feedback.rating ? (
                        <Rating
                          value={feedback.rating}
                          readOnly
                          size="small"
                        />
                      ) : (
                        <span className="text-gray-500 text-sm">No Rating</span>
                      )}
                    </td>
                    <td className="border-b p-4">
                      {feedback.comments.trim() === "" ? (
                        <span className="text-gray-500 italic">No comment</span>
                      ) : (
                        feedback.comments
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Pagination Section */}
      <div className="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="text-sm text-gray-600">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, totalFeedbacks)} of{" "}
          {totalFeedbacks} results
        </div>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#4B5D67",
                  fontWeight: 600,
                  borderRadius: "12px",
                  padding: "5px 10px",
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Feedback;