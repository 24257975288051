import React, { useEffect, useState } from "react";
import { Authenticator, Button } from "@aws-amplify/ui-react";
import axios from "axios";
import host from "../../global";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "../../css/orgadmin.css";
import { PlusCircle } from 'lucide-react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import Loader from "../Loader";
const CreatedJDs = () => {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [codingSets, setCodingSets] = useState([]);
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState("NA");
  const [selectedCodingSet, setSelectedCodingSet] = useState("NA");
  const [selectedInterviewType, setSelectedInterviewType] = useState("");
  const [custom_questions, setcustom_questions] = useState("");
  const [interview_timelimit, setInterviewTimeLimit] = useState(0);
  const [selectedSet, setSelectedSet] = useState("NA");
  const [sets, setSets] = useState([]);
  const [showEditJDForm, setShowEditJDForm] = useState(false);
  const [jbrole, setJbrole] = useState("");
  const [jobdesc, setJobdesc] = useState("");
  const [isUpdatingJD, setIsUpdatingJD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");
  const [jd_id, setjd_id] = useState("");
  const fetchCombinedData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${host}/orgadmin/get_jd_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data.job_descriptions)
      setJobDescriptions(data.job_descriptions);
      setSets(data.set_names);
      setCodingSets(Object.keys(data.coding_sets));
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCombinedData();
  }, []);
  const handleJbroleChange = (e) => {
    setJbrole(e.target.value);
  };
  const handleNumberChange = (e) => {
    setInterviewTimeLimit(e.target.value);
  };
  const handleMCQTimeLimitChange = (e) => {
    setMCQTimeLimit(e.target.value);
  };
  const handleCodingTimeLimitChange = (e) => {
    setCodingTimeLimit(e.target.value);
  };
  const handleInterviewTypeChange = (e) => {
    setSelectedInterviewType(e.target.value);
  };
  const handleSetChange = (e) => {
    setSelectedSet(e.target.value);
  };
  const handleCodingJudgingCriteriaChange = (e) => {
    setCodingJudgingCriteria(e.target.value);
  };
  const handleInputChange = (index, event) => {
    const newQuestions = [...custom_questions];
    newQuestions[index] = event.target.value;
    setcustom_questions(newQuestions);
  };
  const handleIncludeMCQ = () => {
    if (isMCQIncluded) {
      setIsMCQIncluded(false);
      setSelectedSet("NA");
      setMCQTimeLimit(0);
    } else {
      setIsMCQIncluded(true);
    }
  };
  const handleIncludeCoding = () => {
    if (isCodingIncluded) {
      setIsCodingIncluded(false);
      setSelectedCodingSet("NA");
      setCodingJudgingCriteria("NA");
      setCodingTimeLimit(0);
    } else {
      setIsCodingIncluded(true);
    }
  };
  const handleAddJD = () => {
    navigate(`/org-admin/createaijd`);
  };
  const handleDeleteJD = async (e) => {
    e.preventDefault();
    if (
      window.confirm("Are you sure you want to delete this job description?")
    ) {
      try {
        const response = await axios.delete(`${host}/orgadmin/deletejd`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            jd_id: jd_id,
          },
        });
        if (response.status === 200) {
          toast.success("JD Deleted Successfully!");
          await fetchCombinedData();
          setShowEditJDForm(false);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  const closeEditJD = () => {
    setShowEditJDForm(false);
  };
  const handleEditJDRequest = async (jdId) => {
    setIsUpdatingJD(true);
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          jd_id: jdId,
        },
      });
      setShowEditJDForm(true);
      if (response) {
        setjd_id(jdId);
        setJbrole(response.data.jobrole);
        setJobdesc(response.data.description);
        setInterviewTimeLimit(response.data.interview_timelimit);
        setSelectedInterviewType(response.data.interview_type);
        setcustom_questions(response.data.customQuestions);
        if (response.data.mcq_set !== "NA") {
          setIsMCQIncluded(true);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setSelectedSet(response.data.mcq_set);
        } else {
          setIsMCQIncluded(false);
        }
        if (response.data.Coding_set !== "NA") {
          setIsCodingIncluded(true);
          setCodingTimeLimit(response.data.coding_time_limit);
          setCodingJudgingCriteria(response.data.Coding_Judge_Criteria);
          setSelectedCodingSet(response.data.Coding_set);
        } else {
          setIsCodingIncluded(false);
        }
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleViewCandidates = (jobId, selectedServices,jobrole) => {
    console.log(selectedServices,)
    const services = Object.keys(selectedServices).filter(service => selectedServices[service]);
    navigate(`/org-admin/candidatelist`, {
        state: { jobId: jobId, services: services, jobrole:jobrole }
    });
};

  const formatJobDescription = (data) => {
    let result = "";
    if (data.Experience_Level !== null && data.Experience_Level !== undefined) {
      result += `Experience Level: ${data.Experience_Level}\n\n`;
    }
    const isMediumSkillsRequired = data.Experience_Level < 4;
    if (data.Required_Skills) {
      if (data.Required_Skills.Hard && data.Required_Skills.Hard.length > 0) {
        result += `Required Skills - Hard:\n`;
        data.Required_Skills.Hard.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        Array.isArray(data.Required_Skills.Medium) &&
        data.Required_Skills.Medium.length > 0
      ) {
        if (isMediumSkillsRequired) {
          result += `Required Skills - Medium:\n`;
          data.Required_Skills.Medium.forEach((item, index) => {
            result += `  ${index + 1}. ${item.join(", ")}\n`;
          });
          result += "\n";
        }
      }
    }
    if (data.Required_Skills) {
      if (data.Required_Skills.Easy && data.Required_Skills.Easy.length > 0) {
        result += `Optional Skills - Easy:\n`;
        data.Required_Skills.Easy.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
      if (
        data.Required_Skills.Medium &&
        !isMediumSkillsRequired &&
        data.Required_Skills.Medium.length > 0
      ) {
        result += `Optional Skills - Medium:\n`;
        data.Required_Skills.Medium.forEach((item, index) => {
          result += `  ${index + 1}. ${item.join(", ")}\n`;
        });
        result += "\n";
      }
    }
    if (data.GoodtoHave && data.GoodtoHave.length > 0) {
      result += `Good to Have:\n  - ${data.GoodtoHave.join(", ")}\n\n`;
    }
    if (data.Tools_and_Technologies && data.Tools_and_Technologies.length > 0) {
      result += `Tools and Technologies:\n`;
      data.Tools_and_Technologies.forEach((item, index) => {
        result += `  - ${item}\n`;
      });
      result += "\n";
    }
    return result;
  };
  const updateJDRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${host}/orgadmin/updatejd`,
        {
          jd_id: jd_id,
          jobrole: jbrole,
          selectedInterviewType: selectedInterviewType,
          interview_timelimit: interview_timelimit,
          selectedSet: selectedSet,
          MCQTimeLimit: MCQTimeLimit,
          selectedCodingSet: selectedCodingSet,
          CodingTimeLimit: CodingTimeLimit,
          codingJudgingCriteria: codingJudgingCriteria,
          custom_questions: custom_questions,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        toast.success("JD Updated Successfully!");
        setShowEditJDForm(false);
        await fetchCombinedData();
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  if (isLoading) {
    return (
      <Loader />
    )
  };
  return (
    <>
      <Authenticator>
        <div className="min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
          <Dialog
            open={showEditJDForm}
            onClose={() => setShowEditJDForm(false)}
            maxWidth="md"
            fullWidth
            sx={{
              backdropFilter: 'blur(10px)',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }}
          >
            <DialogTitle
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              Edit Job Description
            </DialogTitle>
            <DialogContent dividers sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <Card variant="outlined" sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                <CardContent>
                  <form>
                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Job Title"
                          value={jbrole}
                          onChange={handleJbroleChange}
                          required
                          variant="outlined"
                          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                        />
                      </Grid>
                      {jobdesc && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Job Description
                          </Typography>
                          <Typography
                            component="pre"
                            sx={{
                              backgroundColor: 'grey.100',
                              p: 2,
                              borderRadius: 2,
                              maxHeight: 300,
                              overflowY: 'auto',
                              fontFamily: 'inherit',
                              backdropFilter: 'blur(10px)',
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            }}
                          >
                            {formatJobDescription(jobdesc)}
                          </Typography>
                        </Grid>
                      )}
                      {custom_questions && custom_questions.length > 0 && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Custom Questions
                          </Typography>
                          {custom_questions.map((question, index) => (
                            <TextField
                              key={index}
                              fullWidth
                              value={question}
                              onChange={(e) => handleInputChange(index, e)}
                              variant="outlined"
                              margin="dense"
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            />
                          ))}
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          fullWidth
                          label="Type of Interview"
                          value={selectedInterviewType}
                          onChange={handleInterviewTypeChange}
                          required
                          variant="outlined"
                          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                        >
                          <MenuItem value="">Select Type Of Interview</MenuItem>
                          <MenuItem value="Technical Interview">Technical Interview</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Interview Time Limit (Mins)"
                          type="number"
                          value={interview_timelimit}
                          onChange={handleNumberChange}
                          required
                          variant="outlined"
                          InputProps={{ inputProps: { min: 1 } }}
                          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isMCQIncluded}
                              onChange={handleIncludeMCQ}
                              color="primary"
                            />
                          }
                          label="Include MCQ Test"
                        />
                      </Grid>
                      {isMCQIncluded && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              select
                              fullWidth
                              label="Select Set For MCQ Test"
                              value={selectedSet}
                              onChange={handleSetChange}
                              required
                              variant="outlined"
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            >
                              <MenuItem value="">Select a set</MenuItem>
                              {sets.map((setName, index) => (
                                <MenuItem key={index} value={setName}>
                                  {setName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Set MCQ Time Limit (Mins)"
                              type="number"
                              value={MCQTimeLimit}
                              onChange={handleMCQTimeLimitChange}
                              required
                              variant="outlined"
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isCodingIncluded}
                              onChange={handleIncludeCoding}
                              color="primary"
                            />
                          }
                          label="Include Coding Test"
                        />
                      </Grid>
                      {isCodingIncluded && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              select
                              fullWidth
                              label="Select Coding Set"
                              value={selectedCodingSet}
                              onChange={(e) => setSelectedCodingSet(e.target.value)}
                              required
                              variant="outlined"
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            >
                              <MenuItem value="">Select Set</MenuItem>
                              {codingSets.map((setName, index) => (
                                <MenuItem key={index} value={setName}>
                                  {setName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Set Coding Time Limit (Mins)"
                              type="number"
                              value={CodingTimeLimit}
                              onChange={handleCodingTimeLimitChange}
                              required
                              variant="outlined"
                              InputProps={{ inputProps: { min: 1 } }}
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              select
                              fullWidth
                              label="Set Coding Judging Criteria"
                              value={codingJudgingCriteria}
                              onChange={handleCodingJudgingCriteriaChange}
                              required
                              variant="outlined"
                              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 2 }}
                            >
                              <MenuItem value="">Select Criteria</MenuItem>
                              <MenuItem value="0">Judge 0</MenuItem>
                              <MenuItem value="1">Judge 1</MenuItem>
                              <MenuItem value="2" disabled>Judge 2</MenuItem>
                              <MenuItem value="3" disabled>Judge 3</MenuItem>
                              <MenuItem value="4" disabled>Judge 4</MenuItem>
                              <MenuItem value="5" disabled>Master Judge</MenuItem>
                            </TextField>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </DialogContent>
            {isUpdatingJD && (
              <DialogActions>
                <Button
                  onClick={updateJDRequest}
                  variant="contained"
                  color="primary"
                  sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                >
                  Update JD
                </Button>
                <Button
                  onClick={handleDeleteJD}
                  variant="contained"
                  color="error"
                  sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                >
                  Delete JD
                </Button>
                <Button
                  onClick={closeEditJD}
                  variant="contained"
                  color="secondary"
                  sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </Dialog>
          <div className="flex justify-center items-center">
            <div className="table-container">
              <div className="table-header">
                <div className="flex items-center justify-between mb-6 border-b pb-4">
                  <h1 className="text-2xl font-bold text-gray-800 tracking-tight">
                    Created Job Descriptions
                  </h1>
                  <Button
                    onClick={handleAddJD}
                    className="flex items-center gap-2"
                    style={{
                      float: 'right',
                      marginBottom: '16px',
                      background: 'rgba(255, 255, 255, 0.1)',
                      backdropFilter: 'blur(5px)',
                      borderRadius: '5px',
                      color: 'black',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      fontWeight: 'normal',
                    }}
                  >
                    <PlusCircle className="w-5 h-5" />
                    Add New JD
                  </Button>
                </div>
                <div className="flex items-center w-full">
                  {jobDescriptions.length > 0 ? (
                    <Table>
                      <Thead>
                        <Tr>
                        <Th style={{ textAlign: 'left', paddingLeft: '50px' }} >Created Date</Th>
                          <Th>Job Role</Th>
                          <Th>Experience Range</Th>
                          <Th style={{ textAlign: 'left'}}>Services Selected </Th>
                          <Th style={{ textAlign: 'center', paddingLeft: '30px' }}>Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {jobDescriptions.map((jd) => (
                          <Tr key={jd.jdid}>
                            <Td style={{ whiteSpace: 'normal',textAlign:'left',paddingLeft: '50px' }}>
                              <strong>{jd.postingDate}</strong>
                            </Td>
                            <Td style={{ whiteSpace: 'normal',textAlign: 'left', paddingLeft: '30px' }}>
                              {jd.jobrole}
                            </Td>
                            <Td style={{ whiteSpace: 'normal',textAlign: 'left', paddingLeft: '30px' }}>
                              {(jd.description && jd.description.minExp !== undefined && jd.description.maxExp !== undefined )
                                ? `${jd.description.minExp} - ${jd.description.maxExp} yrs` 
                                : '0-1 yrs'}
                            </Td>
                            <Td style={{textAlign: 'left', paddingLeft: '30px'}}>
                              {jd.selectedServices
                                ? Object.keys(jd.selectedServices)
                                    .filter((service) => jd.selectedServices[service])
                                    .map((service, index) => (
                                      <span key={index}>
                                        - {service.charAt(0).toUpperCase() + service.slice(1)}
                                        <br />
                                      </span>
                                    ))
                                : "- Assessment"}
                            </Td>
                            <Td>
                            <div className="flex gap-3 items-center justify-center">
        <button
          onClick={() => handleEditJDRequest(jd.jd_id)}
          className="group relative px-1 py-2.5 flex-1 max-w-[150px] overflow-hidden rounded-lg bg-gradient-to-br from-blue-50 to-white shadow-md transition-all duration-300 hover:shadow-lg hover:translate-y-[-1px] hover:text-white"
        >
          <span className="absolute inset-0 bg-blue-100 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></span>
          <div className="flex items-center justify-center gap-2">
            <svg 
              className="w-4 h-4 text-blue-600 transition-transform duration-300 group-hover:scale-110 group-hover:text-white" 
              fill="none" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <span className="text-gray-700 font-small group-hover:text-white">Edit</span>
          </div>
        </button>

        {jd.selectedServices && (jd.selectedServices['sourcing'] || jd.selectedServices['screening']) && (
          <button
            onClick={() => handleViewCandidates(jd.jd_id, jd.selectedServices,jd.jobrole)}
            className="group relative px-1 py-2.5 flex-1 max-w-[150px] overflow-hidden rounded-lg bg-gradient-to-br from-purple-50 to-white shadow-md transition-all duration-300 hover:shadow-lg hover:translate-y-[-1px]"
          >
            <span className="absolute inset-0 bg-purple-100 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></span>
            <div className="flex items-center justify-center gap-2">
              <svg 
                className="w-4 h-4 text-blue-600 transition-transform duration-300 group-hover:scale-110 group-hover:text-white" 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <span className="text-gray-700 group-hover:text-white font-small">Candidates</span>
            </div>
          </button>
        )}
      </div>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <div>No Job Descriptions Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Authenticator>
    </>
  );
};
export default CreatedJDs;
