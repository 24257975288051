import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  Plus,
  Trash2,
  ChevronDown,
  ChevronUp,
  X,
} from "lucide-react";
import Loader from "../components/Loader";
import host from "../global";
import axios from "axios";

const ConfirmDialog = ({ isOpen, onClose, onConfirm, itemName, isLast }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">Confirm</h3>
        <p className="text-gray-600 mb-4">
          Are you sure you want to {isLast ? "clear" : "remove"} this{" "}
          {itemName.toLowerCase()}?
        </p>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded border hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Yes, {isLast ? "clear" : "remove"} it
          </button>
        </div>
      </div>
    </div>
  );
};

const EditProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [skillInput, setSkillInput] = useState("");
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    section: "",
    index: null,
    itemName: "",
  });
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    occupation: "",
    country_full_name: "India",
    city: "",
    state: "",
    skills: [],
    gender: "",
    birth_date: "",
    personal_numbers: [""],
    personal_emails: [""],
    languages_and_proficiencies: [
      {
        language: "",
        proficiency: "",
      },
    ],
    experiences: [
      {
        company: "",
        title: "",
        description: "",
        location: "",
        starts_at: null,
        ends_at: null,
      },
    ],
    education: [
      {
        school: "",
        degree_name: "",
        field_of_study: "",
        grade: "",
        description: "",
        starts_at: null,
        ends_at: null,
      },
    ],
    certifications: [
      {
        name: "",
        authority: "",
        starts_at: null,
        ends_at: null,
        license_number: "",
        url: "",
      },
    ],
    social_links: [
      {
        platform: "",
        url: "",
      },
    ],
    articles: [
      {
        title: "",
        url: "",
        published_date: "",
      },
    ],
  });

  const [expandedSections, setExpandedSections] = useState({
    personalInfo: true,
    location: true,
    experiences: {},
    education: {},
    certifications: {},
    social_links: {},
    languages_and_proficiencies: {},
    articles: {},
    skills: true,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]:
        typeof prev[section] === "object" ? prev[section] : !prev[section],
    }));
  };

  const toggleItem = (section, index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [index]: !prev[section][index],
      },
    }));
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000 < Date.now();
    } catch (error) {
      return true;
    }
  };

  const validateTokens = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshExpired = isTokenExpired(refreshToken);
    const accessToken = localStorage.getItem("accessToken");
    const accessExpired = isTokenExpired(accessToken);
    console.log("From Profile Rf", refreshToken);
    console.log("From Profile At", accessToken);

    if (refreshExpired) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/candidate-login");
      return;
    }

    if (accessExpired && !refreshExpired) {
      try {
        const response = await axios.post(`${host}/api/candidate/refresh`, {
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        const { access_token, refresh_token } = response.data;

        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);

        return access_token;
      } catch (error) {
        console.error("Token refresh failed:", error);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/candidate-login");
        return null;
      }
    }

    return accessToken;
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userEmail = localStorage.getItem("userEmail");

    if (!accessToken || !userEmail) {
      console.log("No authentication credentials found");
      navigate("/candidate-login");
      return;
    }

    validateTokens();
    const tokenCheckInterval = setInterval(validateTokens, 1000);
    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);

  useEffect(() => {
    validateTokens();
    const tokenCheckInterval = setInterval(validateTokens, 30000);
    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const email = localStorage.getItem("userEmail");
        const validToken = await validateTokens();
        if (!validToken) {
          setError("Authentication Failed! Please Log In Again");
          return;
        }

        const response = await fetch(`${host}/api/user-profile/${email}`, {
          headers: {
            Authorization: `Bearer ${validToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            const newToken = await validateTokens();
            if (!newToken) {
              setError("Session expired! Please Log In again");
            }

            const retryResponse = await fetch(
              `${host}/api/user-profile/${email}`,
              {
                headers: {
                  Authorization: `Bearer ${newToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (!retryResponse.ok) throw new Error(await retryResponse.text());
            const data = await retryResponse.json();
            setProfile(data);
            return;
          }
          throw new Error(await response.text());
        }

        const data = await response.json();
        setProfile(data);
        setProfile({
          ...data,
          experiences: data.experiences || [],
          education: data.education || [],
          certifications: data.certifications || [],
          social_links: data.social_links || [],
          languages_and_proficiencies: data.languages_and_proficiencies || [],
          articles: data.articles || [],
          skills: data.skills || [],
          personal_emails: data.personal_emails || [""],
          personal_numbers: data.personal_numbers || [""],
        });
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleInputChange = (e, section = null, index = null) => {
    const { name, value } = e.target;

    if (section) {
      setProfile((prev) => ({
        ...prev,
        [section]: prev[section].map((item, i) =>
          i === index ? { ...item, [name]: value } : item
        ),
      }));
    } else {
      setProfile((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (e, section, index, field) => {
    const dateStr = e.target.value;

    const dateObj = dateStr
      ? {
          year: parseInt(dateStr.split("-")[0]),
          month: parseInt(dateStr.split("-")[1]),
          day: parseInt(dateStr.split("-")[2]),
        }
      : null;

    if (!section) {
      setProfile((prev) => ({
        ...prev,
        [field]: dateObj,
      }));
    } else {
      setProfile((prev) => ({
        ...prev,
        [section]: prev[section].map((item, i) =>
          i === index ? { ...item, [field]: dateObj } : item
        ),
      }));
    }
  };

  const formatDateForInput = (dateObj) => {
    if (!dateObj || !dateObj.year) return "";
    return `${dateObj.year}-${String(dateObj.month).padStart(2, "0")}-${String(
      dateObj.day
    ).padStart(2, "0")}`;
  };

  const handleAddSection = (section) => {
    const newItems = {
      experiences: {
        company: "",
        title: "",
        description: "",
        location: "",
        starts_at: null,
        ends_at: null,
      },
      education: {
        school: "",
        degree_name: "",
        field_of_study: "",
        grade: "",
        description: "",
        starts_at: null,
        ends_at: null,
      },
      certifications: {
        title: "",
        authority: "",
        license_number: "",
        url: "",
        starts_at: null,
        ends_at: null,
      },
      social_links: {
        platform: "",
        url: "",
      },
      languages_and_proficiencies: {
        language: "",
        url: "",
      },
      articles: {
        title: "",
        url: "",
        published_date: null,
      },
    };

    setProfile((prev) => ({
      ...prev,
      [section]: [newItems[section], ...prev[section]],
    }));

    setExpandedSections((prev) => ({
      ...prev,
      [section]: {
        0: true,
        ...Object.keys(prev[section]).reduce(
          (acc, key) => ({
            ...acc,
            [parseInt(key) + 1]: prev[section][key],
          }),
          {}
        ),
      },
    }));
  };

  const handleRemoveWithConfirmation = (section, index, itemName) => {
    setDialogState({
      isOpen: true,
      section,
      index,
      itemName,
    });
  };

  const handleConfirmedRemove = () => {
    if (dialogState.section && dialogState.index !== null) {
      handleRemoveSection(dialogState.section, dialogState.index);
    }
    setDialogState({ isOpen: false, section: "", index: null, itemName: "" });
  };

  const RemoveButton = ({ section, index, itemName, isLast }) => (
    <button
      type="button"
      onClick={() => handleRemoveWithConfirmation(section, index, itemName)}
      className="mt-2 flex items-center text-red-600 text-sm hover:bg-none hover:text-red-600 hover:font-medium"
    >
      <Trash2 className="w-4 h-4 mr-1" />
      {isLast ? `Clear ${itemName}` : `Remove ${itemName}`}
    </button>
  );

  const handleRemoveSection = (section, index) => {
    setProfile((prev) => ({
      ...prev,
      [section]: prev[section].filter((_, i) => i !== index),
    }));
  };

  const handleSkillKeyDown = (e) => {
    if (e.key === "Enter" && skillInput.trim()) {
      e.preventDefault();
      if (!profile.skills.includes(skillInput.trim())) {
        setProfile((prev) => ({
          ...prev,
          skills: [...prev.skills, skillInput.trim()],
        }));
        setSkillInput("");
      }
    }
  };

  const removeSkill = (skillToRemove) => {
    setProfile((prev) => ({
      ...prev,
      skills: prev.skills.filter((skill) => skill !== skillToRemove),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        throw new Error("Authentication required");
      }

      const response = await fetch(`${host}/api/update-profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(profile),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      alert("Profile updated successfully");
      navigate("/candidate-dashboard");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <button
        onClick={() => navigate("/candidate-dashboard")}
        className="flex items-center text-blue-600 mb-6 hover:bg-none hover:text-blue-600 hover:font-medium"
      >
        <ChevronLeft className="w-4 h-4 mr-1" />
        Back to Dashboard
      </button>

      <h1 className="text-3xl font-semibold mb-8">Edit Profile</h1>

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Personal Information */}
        <ConfirmDialog
          isOpen={dialogState.isOpen}
          onClose={() => setDialogState((prev) => ({ ...prev, isOpen: false }))}
          onConfirm={handleConfirmedRemove}
          itemName={dialogState.itemName}
          isLast={profile[dialogState.section]?.length === 1}
        />
        <div className="bg-white p-6 rounded-lg shadow">
          <div
            className="flex justify-between items-center mb-4 cursor-pointer"
            onClick={() => toggleSection("personalInfo")}
          >
            <h2 className="text-xl font-semibold">Personal Information</h2>
            {expandedSections.personalInfo ? (
              <ChevronUp className="w-5 h-5 text-gray-500" />
            ) : (
              <ChevronDown className="w-5 h-5 text-gray-500" />
            )}
          </div>

          {expandedSections.personalInfo && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={profile.first_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={profile.last_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Current Job Title <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="occupation"
                  value={profile.occupation}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={profile.personal_emails?.[0] || ""}
                  disabled
                  className="w-full p-2 border rounded bg-gray-100"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Gender <span className="text-red-500">*</span>
                </label>
                <select
                  name="gender"
                  value={profile.gender}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Birth Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="birth_date"
                  value={
                    profile?.birth_date
                      ? formatDateForInput(profile.birth_date)
                      : ""
                  }
                  onChange={(e) =>
                    handleDateChange(e, null, null, "birth_date")
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Contact Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  name="personal_numbers"
                  value={profile.personal_numbers?.[0] || ""}
                  required
                  onChange={(e) => {
                    setProfile((prev) => ({
                      ...prev,
                      personal_numbers: [
                        e.target.value,
                        ...prev.personal_numbers.slice(1),
                      ],
                    }));
                  }}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          )}
        </div>

        {/* Location */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Location</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Country <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="country_full_name"
                value={profile.country_full_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                State <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="state"
                value={profile.state}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                City <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={profile.city}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
        </div>

        {/* Experience Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Experience</h2>
            <button
              type="button"
              onClick={() => handleAddSection("experiences")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Experience
            </button>
          </div>

          {profile.experiences.map((exp, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div
                className="flex justify-between items-center mb-4 cursor-pointer bg-gray-50 p-4 rounded"
                onClick={() => toggleItem("experiences", index)}
              >
                <h3 className="font-medium">
                  {exp.company || `Add Experience`}
                </h3>
                {expandedSections.experiences[index] ? (
                  <ChevronUp className="w-4 h-4 text-gray-500" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                )}
              </div>

              {expandedSections.experiences[index] && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Company Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="company"
                        value={exp.company}
                        onChange={(e) =>
                          handleInputChange(e, "experiences", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Title <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={exp.title}
                        onChange={(e) =>
                          handleInputChange(e, "experiences", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Start Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="starts_at"
                        value={
                          exp?.starts_at
                            ? formatDateForInput(exp.starts_at)
                            : ""
                        }
                        onChange={(e) =>
                          handleDateChange(e, "experiences", index, "starts_at")
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        End Date{" "}
                        {!exp.is_current && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                      <div className="space-y-2">
                        <input
                          type="date"
                          name="ends_at"
                          value={
                            exp?.ends_at ? formatDateForInput(exp.ends_at) : ""
                          }
                          onChange={(e) =>
                            handleDateChange(e, "experiences", index, "ends_at")
                          }
                          className="w-full p-2 border rounded"
                          disabled={exp.is_current}
                          required={!exp.is_current}
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        name="is_current"
                        checked={exp.is_current}
                        onChange={(e) => {
                          handleInputChange(
                            {
                              target: {
                                name: "is_current",
                                value: e.target.checked,
                              },
                            },
                            "experiences",
                            index
                          );
                          if (e.target.checked) {
                            handleDateChange(
                              { target: { value: "" } },
                              "experiences",
                              index,
                              "ends_at"
                            );
                          }
                        }}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        Currently Working
                      </span>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1 mt-4">
                      Location <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={exp.location}
                      onChange={(e) =>
                        handleInputChange(e, "experiences", index)
                      }
                      className="w-full p-2 border rounded"
                    />
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={exp.description}
                      onChange={(e) =>
                        handleInputChange(e, "experiences", index)
                      }
                      className="w-full p-2 border rounded"
                      rows="3"
                    />
                  </div>
                  {/* <button
                    type="button"
                    onClick={() => handleRemoveSection("experiences", index)}
                    className="mt-2 flex items-center text-red-600"
                  >
                    <Trash2 className="w-4 h-4 mr-1" />
                    {profile.experiences.length > 1
                      ? "Remove Experience"
                      : "Clear Experience"}
                  </button> */}
                  <RemoveButton
                    section="experiences"
                    index={index}
                    itemName="Experience"
                    isLast={profile.experiences.length === 1}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        {/* Education Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Education</h2>
            <button
              type="button"
              onClick={() => handleAddSection("education")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Education
            </button>
          </div>

          {profile.education.map((edu, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div
                className="flex justify-between items-center mb-4 cursor-pointer bg-gray-50 p-4 rounded"
                onClick={() => toggleItem("education", index)}
              >
                <h3 className="font-medium">{edu.school || `Add Education`}</h3>
                {expandedSections.education[index] ? (
                  <ChevronUp className="w-4 h-4 text-gray-500" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                )}
              </div>

              {expandedSections.education[index] && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Institution <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="school"
                        value={edu.school}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Degree <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="degree_name"
                        value={edu.degree_name}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Field of Study <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="field_of_study"
                        value={edu.field_of_study}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Grade/GPA <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="grade"
                        value={edu.grade}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Start Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="starts_at"
                        value={
                          edu?.starts_at
                            ? formatDateForInput(edu.starts_at)
                            : ""
                        }
                        onChange={(e) =>
                          handleDateChange(e, "education", index, "starts_at")
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        End Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="ends_at"
                        value={
                          edu?.ends_at ? formatDateForInput(edu.ends_at) : ""
                        }
                        onChange={(e) =>
                          handleDateChange(e, "education", index, "ends_at")
                        }
                        className="w-full p-2 border rounded"
                        disabled={edu.is_current}
                        required={!edu.is_current}
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        name="is_current"
                        checked={edu.is_current}
                        onChange={(e) => {
                          handleInputChange(
                            {
                              target: {
                                name: "is_current",
                                value: e.target.checked,
                              },
                            },
                            "education",
                            index
                          );
                          if (e.target.checked) {
                            handleDateChange(
                              { target: { value: "" } },
                              "education",
                              index,
                              "ends_at"
                            );
                          }
                        }}
                        className="h-4 w-4 text-blue-600 rounded border-gray-300"
                      />
                      <span className="ml-2 text-sm text-gray-600">
                        Currently Studying
                      </span>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={edu.description}
                      onChange={(e) => handleInputChange(e, "education", index)}
                      className="w-full p-2 border rounded"
                      rows="3"
                    />
                  </div>
                  {/* <button
                    type="button"
                    onClick={() => handleRemoveSection("education", index)}
                    className="mt-2 flex items-center text-red-600"
                  >
                    <Trash2 className="w-4 h-4 mr-1" />
                    {profile.education.length > 1
                      ? "Remove Education"
                      : "Clear Education"}
                  </button> */}
                  <RemoveButton
                    section="education"
                    index={index}
                    itemName="Education"
                    isLast={profile.education.length === 1}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        {/* Certifications Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Certifications</h2>
            <button
              type="button"
              onClick={() => handleAddSection("certifications")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Certification
            </button>
          </div>

          {profile.certifications.map((cert, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div
                className="flex justify-between items-center mb-4 cursor-pointer bg-gray-50 p-4 rounded"
                onClick={() => toggleItem("certifications", index)}
              >
                <h3 className="font-medium">
                  {cert.name || `Add Certification`}
                </h3>
                {expandedSections.certifications[index] ? (
                  <ChevronUp className="w-4 h-4 text-gray-500" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                )}
              </div>

              {expandedSections.certifications[index] && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={cert.name}
                        onChange={(e) =>
                          handleInputChange(e, "certifications", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Issuing Organization{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="authority"
                        value={cert.authority}
                        onChange={(e) =>
                          handleInputChange(e, "certifications", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        License Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="license_number"
                        value={cert.license_number}
                        onChange={(e) =>
                          handleInputChange(e, "certifications", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        URL
                      </label>
                      <input
                        type="url"
                        name="url"
                        value={cert.url}
                        onChange={(e) =>
                          handleInputChange(e, "certifications", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Start Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="starts_at"
                        value={
                          cert?.starts_at
                            ? formatDateForInput(cert.starts_at)
                            : ""
                        }
                        onChange={(e) =>
                          handleDateChange(
                            e,
                            "certifications",
                            index,
                            "starts_at"
                          )
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        End Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="ends_at"
                        value={
                          cert?.ends_at ? formatDateForInput(cert.ends_at) : ""
                        }
                        onChange={(e) =>
                          handleDateChange(
                            e,
                            "certifications",
                            index,
                            "ends_at"
                          )
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                  </div>
                  {/* <button
                    type="button"
                    onClick={() => handleRemoveSection("certifications", index)}
                    className="mt-2 flex items-center text-red-600"
                  >
                    <Trash2 className="w-4 h-4 mr-1" />
                    {profile.certifications.length > 1
                      ? "Remove Certification"
                      : "Clear Certification"}
                  </button> */}
                  <RemoveButton
                    section="certifications"
                    index={index}
                    itemName="Certification"
                    isLast={profile.certifications.length === 1}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        {/* Social Links Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Social Links</h2>
            <button
              type="button"
              onClick={() => handleAddSection("social_links")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Social Link
            </button>
          </div>

          {profile.social_links.map((link, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Platform
                  </label>
                  <select
                    name="platform"
                    value={link.platform || ""}
                    onChange={(e) => handleInputChange(e, "social_links", index)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">
                      Select Platform <span className="text-red-500">*</span>
                    </option>
                    <option value="linkedin">LinkedIn</option>
                    <option value="naukri">Naukri</option>
                    <option value="indeed">Indeed</option>
                    <option value="twitter">Twitter</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    URL <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="url"
                    name="url"
                    value={link.url || ""}
                    onChange={(e) => handleInputChange(e, "social_links", index)}
                    className="w-full p-2 border rounded"
                    placeholder="https://"
                    required
                  />
                </div>
              </div>
              <RemoveButton
                section="social_links"
                index={index}
                itemName="Social Link"
                isLast={profile.social_links.length === 1}
              />
            </div>
          ))}
        </div>

        {/* Languages Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Languages</h2>
            <button
              type="button"
              onClick={() => handleAddSection("languages_and_proficiencies")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Language
            </button>
          </div>

          {profile.languages_and_proficiencies.map((lang, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Language <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="language"
                    value={lang.language || ""}
                    onChange={(e) => handleInputChange(e, "languages_and_proficiencies", index)}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Proficiency Level <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="proficiency"
                    value={lang.proficiency || ""}
                    onChange={(e) => handleInputChange(e, "languages_and_proficiencies", index)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select Proficiency</option>
                    <option value="basic">Basic</option>
                    <option value="conversational">Conversational</option>
                    <option value="fluent">Fluent</option>
                    <option value="native">Native</option>
                  </select>
                </div>
              </div>
              <RemoveButton
                section="languages_and_proficiencies"
                index={index}
                itemName="Language"
                isLast={profile.languages_and_proficiencies.length === 1}
              />
            </div>
          ))}
        </div>

        {/* Published Articles Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Published Articles</h2>
            <button
              type="button"
              onClick={() => handleAddSection("articles")}
              className="flex items-center text-blue-600 hover:bg-none hover:text-blue-600 hover:font-medium"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add Article
            </button>
          </div>

          {profile.articles.map((article, index) => (
            <div key={index} className="mb-6 border-b pb-6">
              <div
                className="flex justify-between items-center mb-4 cursor-pointer bg-gray-50 p-4 rounded"
                onClick={() => toggleItem("articles", index)}
              >
                <h3 className="font-medium">
                  {article.title || `Add Article`}
                </h3>
                {expandedSections.articles[index] ? (
                  <ChevronUp className="w-4 h-4 text-gray-500" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                )}
              </div>

              {expandedSections.articles[index] && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium mb-1">
                        Article Title <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={article.title}
                        onChange={(e) =>
                          handleInputChange(e, "articles", index)
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium mb-1">
                        Article URL <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="url"
                        name="url"
                        value={article.url}
                        onChange={(e) =>
                          handleInputChange(e, "articles", index)
                        }
                        className="w-full p-2 border rounded"
                        placeholder="https://"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Published Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="published_date"
                        value={
                          article?.published_date
                            ? formatDateForInput(article.published_date)
                            : ""
                        }
                        onChange={(e) =>
                          handleDateChange(
                            e,
                            "articles",
                            index,
                            "published_date"
                          )
                        }
                        className="w-full p-2 border rounded"
                        required
                      />
                    </div>
                  </div>
                  <RemoveButton
                    section="articles"
                    index={index}
                    itemName="Article"
                    isLast={profile.articles.length === 1}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        {/* Skills Section */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Skills</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Skills <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={skillInput}
                onChange={(e) => setSkillInput(e.target.value)}
                onKeyDown={handleSkillKeyDown}
                className="w-full p-2 border rounded"
                placeholder="Type a skill and press Enter"
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {profile.skills.map((skill) => (
                <div
                  key={skill}
                  className="text-sm flex items-center gap-1 px-4 py-3 bg-blue-100 text-blue-800 rounded-full"
                >
                  <span>{skill}</span>
                  <button
                    type="button"
                    onClick={() => removeSkill(skill)}
                    className="hover:font-semibold hover:bg-none hover:text-blue-800 p-1"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate("/candidate-dashboard")}
            className="px-6 py-2 border rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
export default EditProfile;
