import React, { useState, useEffect } from "react";
import {
  Typography,
  Skeleton,
} from "@mui/material";
import { FileUp } from "lucide-react";
import { FolderUp } from "lucide-react";
import "../../css/HRPortal.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import host from "../../global";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  function convertToISO(date) {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(
      date.getTime() - timezoneOffset
    ).toISOString();
    return localISOTime.slice(0, -1);
  }
  const convertToLocalTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localTime = new Intl.DateTimeFormat('default', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(utcDate);
    return localTime;
  };
  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    const fetchScheduledInterviews = async () => {
      setLoading(true);
      const isoDate = convertToISO(selectedDate) + 'Z';
      try {
        const response = await fetch(
          `${host}/scheduled_interviews?date=${isoDate}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setInterviews(data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
        setInterviews([]);
      } finally {
        setLoading(false);
      }
    };
    fetchScheduledInterviews();
  }, [selectedDate, accessToken]);
  return (
    <div className="p-8 h-full overflow-auto no-scrollbar bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
      <div className="flex flex-row justify-start items-center mb-8 gap-8">
        <div
          className="flex flex-col justify-center items-center w-[250px] h-[170px] rounded-3xl transition-all duration-300 hover:scale-105 hover:shadow-lg hover:border-blue-300 backdrop-blur-lg bg-white/30 border border-white/20"
          onClick={() => navigate(`/hr/checkresume`)}
        >
           <FileUp size={32} />
          <h3 className="text-black text-center mt-4 text-lg">
            Parse Single Resume
          </h3>
         
        </div>
        <div
          className="flex flex-col justify-center items-center w-[250px] h-[170px] rounded-3xl transition-all duration-300 hover:scale-105 hover:shadow-lg hover:border-blue-300 backdrop-blur-lg bg-white/30 border border-white/20"
          onClick={() => navigate(`/hr/BulkResume`)}
        >
            <FolderUp size={32} />
          <h3 className="text-black text-center mt-4 text-lg">
            Bulk Resume Upload
          </h3>
        
        </div>
      </div>
      {/* <div
        className="glass-metric-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(230px, 1fr))",
          gap: "1rem",
          marginTop: "2rem",
          padding: "20px",
        }}
      >
        {["Total Test Submissions", "Passed Candidates", "Rejected Candidates", "Shortlisted Candidates"].map(
          (title, index) => (
            <Card
              key={index}
              style={{
                backdropFilter: "blur(10px)",
                background: "rgba(255, 255, 255, 0.2)",
                borderRadius: "16px",
              }}
            >
              <CardHeader
                avatar={<BusinessCenterRoundedIcon />}
                title={<Typography>{title}</Typography>}
              />
              <CardContent>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  {"0" + (index + 1)}
                </Typography>
              </CardContent>
            </Card>
          )
        )}
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-[35%_65%] gap-8 px-5 py-8 bg-white/30 rounded-3xl shadow-xl backdrop-blur-md border border-white/30">
        <div className="calendar flex justify-evenly rounded-lg " style={{ fontSize: '0.8rem' }}>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
        <div className="interview-list-container flex flex-col gap-2">
          <div className="flex-col lg:flex-row justify-start items-start gap-5 px-4 pb-4">
          <h2 className="text-lg text-gray-600 font-semibold text-left mb-2">
                Scheduled Interviews
              </h2>
            <div className="overflow-x-auto max-h-[300px] w-full lg:block hidden">
              {loading ? (
                <div>
                  {Array(5).fill().map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="90%"
                      height={30}
                      sx={{ marginBottom: '8px' }}
                    />
                  ))}
                </div>
              ) : interviews.length > 0 ? (
                <table className="min-w-full table-auto border-separate border-spacing-0">
                  <thead>
                    <tr className="text-lg font-semibold text-gray-700">
                      <th className="px-4 py-2 text-left">Name</th>
                      <th className="px-4 py-2 text-left">Job Role</th>
                      <th className="px-4 py-2 text-left">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {interviews.map((interview, index) => (
                      <tr key={index} className="border-t">
                        <td className="px-4 py-2 flex items-center gap-2">
                          <Typography className="candidate-name text-base font-bold text-gray-900 font-poppins">
                            {interview.candidate_name || "Unknown"}
                          </Typography>
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-500 font-poppins">
                          {interview.job_role || "No role specified"}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-500">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className="interview-time text-sm text-gray-500"
                          >
                            {convertToLocalTime(interview.interview_time)}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-md text-center text-gray-400 p-4">
                  No interviews scheduled for this date.
                </div>
              )}
            </div>
            <div className="lg:hidden w-full" style={{ height: "400px", overflowY: "auto" }}>
              {loading ? (
                <div>
                {Array(5).fill().map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width="90%"
                    height={30}
                    sx={{ marginBottom: '8px' }}
                  />
                ))}
              </div>              
              ) : interviews.length > 0 ? (
                interviews.map((interview, index) => (
                  <div key={index} className="interview-item mb-2 p-2 border-b">
                    <div className="font-semibold text-gray-700">
                      <Typography className="candidate-name text-base font-bold text-gray-700 font-poppins">
                        {interview.candidate_name || "Unknown"}
                      </Typography>
                    </div>
                    <div className="text-sm text-gray-500 font-poppins">
                      {interview.job_role || "No role specified"}
                    </div>
                    <div className="text-sm text-gray-500">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="interview-time"
                      >
                        {convertToLocalTime(interview.interview_time)}
                      </Typography>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-lg text-center text-gray-500 p-4">
                  No interviews scheduled for this date.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
