import React, { useEffect, useState } from "react";
import axios from "axios";
import host from "../../global.jsx";
import { Edit } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Cookies from 'js-cookie';

const Dashboard = () => {
  const accessToken = Cookies.get('accessToken');
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminContact, setAdminContact] = useState("");
  const [data, setData] = useState([]);
  const [TotalHRCount, setTotalHRCount] = useState(null);
  const [interviewCount, setInterviewCount] = useState(0);
  const [formVisible, setFormVisible] = useState(false);
  const [viewOrgDetails, setViewOrgDetails] = useState(false);
  const [editOrgId, setEditOrgId] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [editOrgData, setEditOrgData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [ticketRemark, setTicketRemark] = useState("");
  const [formData, setFormData] = useState({
    organization_name: "",
    organization_industry: "",
    organization_email: "",
    org_admin_name: "",
    org_admin_email: "",
    org_admin_contact: "",
    total_hr_allocated: "",
  });

  useEffect(() => {
    const updateDashboardData = async () => {
      await displayConvAIAdminData();
      await getDashCounts();
      await getOrgAdminsData();
    };
    updateDashboardData();
  }, []);

  const getDashCounts = async () => {
    try {
      const response = await axios.get(
        `${host}/get-dashboard-counts`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        setTotalHRCount(response.data.total_hr);
        setInterviewCount(response.data.total_interviews_count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const displayConvAIAdminData = async () => {
    try {
      const response = await axios.get(
        `${host}/get_convai_admin_details`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        setAdminName(response.data.admin_name);
        setAdminEmail(response.data.admin_email);
        setAdminContact(response.data.admin_contact);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getOrgAdminsData = async () => {
    try {
      const response = await axios.get(`${host}/getAllOrgAdmins`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleEditForm = async (org_id) => {
    setEditOrgId(org_id);
    if (editForm) {
      setEditForm(false);
    } else {
      try {
        const response = await axios.get(`${host}/getCompanyData/${org_id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (response) {
          setEditOrgData(response.data);
          setEditForm(true);
          setFormData({
            organization_name: response.data.organization_name,
            organization_industry: response.data.organization_industry,
            organization_email: response.data.organization_email,
            org_admin_name: response.data.org_admin_name,
            org_admin_email: response.data.org_admin_email,
            org_admin_contact: response.data.org_admin_contact,
            total_hr_allocated: response.data.total_hr_allocated,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleDeleteOrg = async (e) => {
    e.preventDefault();
    alert("Confirm Delete Operation");
    try {
      const response = await axios.get(
        `${host}/deleteCompanyData/${editOrgId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      if (response) {
        toast.success("Data Deleted Successfully!");
        await getDashCounts();
        await getOrgAdminsData();
        setTimeout(() => {
          setEditForm(false);
        }, 1300);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddCompany = () => {
    setFormData({
      organization_name: "",
      organization_industry: "",
      organization_email: "",
      org_admin_name: "",
      org_admin_email: "",
      org_admin_contact: "",
      total_hr_allocated: "",
    });
    setFormVisible(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${host}/create_new_org_admin`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("New Organization Added Successfully!");
      setFormData({
        organization_name: "",
        organization_industry: "",
        organization_email: "",
        org_admin_name: "",
        org_admin_email: "",
        org_admin_contact: "",
        total_hr_allocated: "",
      });
      setFormVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Error: ", error);
    }
  };

  const hideForm = () => {
    setFormVisible(false);
  };

  const handleFormUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${host}/update_org_data/${editOrgId}`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Organization Data Successfully Updated!");
      setTimeout(() => {
        setEditForm(false);
      }, 1200);
      setFormData({
        organization_name: "",
        organization_industry: "",
        organization_email: "",
        org_admin_name: "",
        org_admin_email: "",
        org_admin_contact: "",
        total_hr_allocated: "",
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const showOrgData = async (org_id) => {
    try {
      const response = await axios.get(`${host}/getCompanyData/${org_id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setOrgData(response.data);
        setViewOrgDetails(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const hideOrgDetails = () => {
    setViewOrgDetails(false);
  };

  return (
    <>
      <ToastContainer />
      {formVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Fill The Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={hideForm}
              />
            </div>
            <form className="space-y-4 max-h-80 overflow-y-auto">
              {[
                {
                  label: "Organization Name",
                  name: "organization_name",
                  type: "text",
                },
                {
                  label: "Organization Industry",
                  name: "organization_industry",
                  type: "text",
                },
                {
                  label: "Organization Email",
                  name: "organization_email",
                  type: "email",
                },
                { label: "Admin Name", name: "org_admin_name", type: "text" },
                {
                  label: "Admin Email",
                  name: "org_admin_email",
                  type: "email",
                },
                {
                  label: "Admin Contact",
                  name: "org_admin_contact",
                  type: "number",
                },
                {
                  label: "Set Number of HRs",
                  name: "total_hr_allocated",
                  type: "number",
                },
              ].map(({ label, name, type }) => (
                <div key={name} className="mb-4">
                  <label className="block text-gray-600">{label}</label>
                  <input
                    name={name}
                    type={type}
                    value={formData[name]}
                    onChange={handleFormChange}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                  />
                </div>
              ))}
              <button
                type="button"
                className="w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {editForm && editOrgData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Edit Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={toggleEditForm}
              />
            </div>
            <form className="space-y-4 max-h-80 overflow-y-auto">
              {[
                { label: "Organization Name", name: "organization_name" },
                {
                  label: "Organization Industry",
                  name: "organization_industry",
                },
                {
                  label: "Organization Email",
                  name: "organization_email",
                  type: "email",
                },
                { label: "Organization Admin Name", name: "org_admin_name" },
                {
                  label: "Organization Admin Email",
                  name: "org_admin_email",
                  type: "email",
                },
                {
                  label: "Organization Admin Contact",
                  name: "org_admin_contact",
                  type: "number",
                },
                {
                  label: "Set Number Of HRs",
                  name: "total_hr_allocated",
                  type: "number",
                },
              ].map(({ label, name, type = "text" }) => (
                <div key={name} className="mb-4">
                  <label className="block text-gray-600">{label}</label>
                  <input
                    name={name}
                    type={type}
                    placeholder={editOrgData[name] || ""}
                    value={formData[name]}
                    onChange={handleFormChange}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                  />
                </div>
              ))}
              <div className="flex space-x-4">
                <button
                  type="button"
                  className="w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  onClick={handleFormUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={handleDeleteOrg}
                  className="w-full md:w-auto py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                >
                  Delete Organization
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {viewOrgDetails && orgData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-800">
                Organization Details
              </p>
              <CloseRounded
                className="text-red-500 cursor-pointer"
                onClick={hideOrgDetails}
              />
            </div>
            <hr className="border-gray-300 mb-4" />
            <div className="space-y-2 text-gray-700">
              {[
                {
                  label: "Organization Name",
                  value: orgData.organization_name,
                },
                {
                  label: "Organization Type",
                  value: orgData.organization_industry,
                },
                {
                  label: "Organization Email",
                  value: orgData.organization_email,
                },
                { label: "Admin Name", value: orgData.org_admin_name },
                { label: "Admin Email", value: orgData.org_admin_email },
                { label: "Admin Contact", value: orgData.org_admin_contact },
                { label: "HR Allocated", value: orgData.total_hr_allocated },
              ].map(({ label, value }) => (
                <p key={label}>
                  <strong>{label}:</strong> {value}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <h2 className="text-xl font-semibold">{adminName}</h2>
          <p className="text-sm text-gray-600">{adminEmail}</p>
          <p className="text-sm text-gray-600">{adminContact}</p>
          <p className="font-bold text-gray-500">Admin</p>
          <button
            onClick={handleAddCompany}
            className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg w-full hover:bg-green-700 transition"
          >
            Add Organization
          </button>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-blue-600">
            {data?.length || 0}
          </div>
          <p className="mt-2">Active Organizations</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-indigo-600">
            {TotalHRCount || 0}
          </div>
          <p className="mt-2">Active HRs</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
          <div className="text-4xl font-bold text-purple-600">
            {interviewCount}
          </div>
          <p className="mt-2">Total Interviews Conducted</p>
        </div>
      </div>

      <div className="overflow-x-auto mt-6 bg-white shadow-lg rounded-lg">
        <table className="min-w-full table-auto">
          <thead className="bg-blue-600 text-white">
            <tr>
              <th className="py-2 px-4 text-left">Sr</th>
              <th className="py-2 px-4 text-left">Admin Name</th>
              <th className="py-2 px-4 text-left">Admin Contact</th>
              <th className="py-2 px-4 text-left">HRs Allocated</th>
              <th className="py-2 px-4 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr
                key={index}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="py-2 px-4">{index + 1}</td>
                <td
                  className="py-2 px-4 text-blue-600 cursor-pointer hover:text-blue-800"
                  onClick={() => showOrgData(item.organization_id)}
                >
                  {item.org_admin_name}
                </td>
                <td className="py-2 px-4">{item.org_admin_contact}</td>
                <td className="py-2 px-4">{item.total_hr_allocated}</td>
                <td className="py-2 px-4">
                  <Edit
                    className="text-blue-500 cursor-pointer hover:text-blue-700 transition duration-200"
                    onClick={() => toggleEditForm(item.organization_id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Dashboard;
