import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Skeleton,
  Alert,
  Stack,
  Grid,
  Divider,
  Paper,
  useTheme
} from "@mui/material";
import {
  Briefcase,
  FileText,
  Building2,
  MapPin,
  Phone,
  Mail,
  User,
  DollarSign
} from "lucide-react";
import host from "../../global";

const NOTICE_PERIODS = ["Immediate", "1 Month", "2 Months", "3 Months"];

const JobInterestSurvey = () => {
  const theme = useTheme();
  const { job_id, candidate_id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [isScreeningPending, setIsScreeningPending] = useState(false);
  const [screeningData, setScreeningData] = useState(null);
  const [formData, setFormData] = useState({
    job_id:job_id,
    candidate_id:candidate_id,
    firstName: "",
    lastName:"",
    email: "",
    phoneNumber: "",
    noticePeriod: 0,
    expectedSalary: 0,
  });

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`${host}/job_details`, {
          params: { job_id, candidate_id }
        });
        setJobDetails(response.data.job_data);
  
        if (response.data.candidate_data) {
          setFormData(prev => ({
            ...prev,
            firstName: response.data.candidate_data.first_name || "",
            lastName: response.data.candidate_data.last_name || "",
            email: response.data.candidate_data.email || "",
            phoneNumber: response.data.candidate_data.phone_number || "",
          }));
        }
  
        if (
          response.data.matched_candidate &&
          response.data.matched_candidate.screeningStatus === "Pending"
        ) {
          setIsScreeningPending(true);
        } else {
          setIsScreeningPending(false);
          setScreeningData(response.data.matched_candidate);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [job_id, candidate_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    if (!formData.noticePeriod) {
      toast.error("Please select your notice period!");
      return;
    }
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch(`${host}/submit_job_survey`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error("Failed to submit the survey");
      }
  
      const result = await response.json();
      console.log("Form Submitted:", result);
      setSubmitted(true);
      toast.success("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };  

  if (!jobDetails) {
    return (
      <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Skeleton variant="text" height={60} />
              <Skeleton variant="rectangular" height={200} />
              <Skeleton variant="rectangular" height={400} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );
  }
  
  if (!isScreeningPending) {
    return (
      <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Thank you for your time!
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              We have collected the following information from you and will reach out soon.
            </Typography>

            {screeningData && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>Your Submitted Information:</strong>
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Name:</strong> {screeningData.name}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Expected CTC:</strong> ₹{screeningData.expected_ctc}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Notice Period:</strong> {screeningData.notice_period} Month(s)
                </Typography>
              </Box>
            )}
  
            <Button
              variant="contained"
              fullWidth
              href="/candidate-login"
              sx={{ mt: 4 }}
            >
              Create an Account for Updates
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <ToastContainer/>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom sx={{ mb: 4 }}>
            Job Interest Form
          </Typography>

          {/* Job Details Section */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 4, 
              bgcolor: theme.palette.grey[50],
              borderRadius: 2
            }}
          >
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Briefcase />
                <Typography variant="h5">Job Details</Typography>
              </Box>

              <Typography variant="h6">{jobDetails.jobrole}</Typography>

              <Stack direction="row" spacing={2} sx={{ color: 'text.secondary' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Building2 size={18} />
                  <Typography>{jobDetails.companyName}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <MapPin size={18} />
                  <Typography>{jobDetails.workLocations}</Typography>
                </Box>
              </Stack>

              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Company Overview
                </Typography>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 2,
                    bgcolor: 'background.paper',
                    '& pre': { 
                      fontFamily: 'inherit',
                      whiteSpace: 'pre-wrap',
                      margin: 0
                    }
                  }}
                >
                  <pre>{jobDetails.companyDescription}</pre>
                </Paper>
              </Box>
            </Stack>
          </Paper>

          {/* Application Form */}
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
              <FileText />
              <Typography variant="h5">Your Information</Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: <User size={18} style={{ marginRight: 8 }} />
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: <User size={18} style={{ marginRight: 8 }} />
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: <Mail size={18} style={{ marginRight: 8 }} />
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: <Phone size={18} style={{ marginRight: 8 }} />
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Expected Salary (CTC)"
                name="expectedSalary"
                type="number"
                value={formData.expectedSalary}
                onChange={(e) => setFormData({ ...formData, expectedSalary: parseInt(e.target.value, 10) || 0 })}
                required
                InputProps={{
                  startAdornment: <DollarSign size={18} style={{ marginRight: 8 }} />
                }}
              />
              </Grid>

              <Grid item xs={12} sm={12}>
              <FormControl>
                <FormLabel>Notice Period</FormLabel>
                <RadioGroup
                  row
                  name="noticePeriod"
                  value={formData.noticePeriod}
                  onChange={(e) => setFormData({ ...formData, noticePeriod: parseInt(e.target.value, 10) })}
                >
                  {["Immediate", "1 Month", "2 Months", "3 Months"].map((label, idx) => (
                    <FormControlLabel key={idx} value={idx} control={<Radio />} label={label} />
                  ))}
                </RadioGroup>
              </FormControl>
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={loading}
              sx={{ mt: 4, py: 1.5 }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    component="span"
                    sx={{
                      display: 'inline-block',
                      width: 20,
                      height: 20,
                      border: '2px solid',
                      borderColor: 'primary.contrastText',
                      borderTopColor: 'transparent',
                      borderRadius: '50%',
                      animation: 'spin 1s linear infinite',
                      '@keyframes spin': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                      }
                    }}
                  />
                  Processing...
                </Box>
              ) : (
                "Submit Application"
              )}
            </Button>
          </form>

          {submitted && !loading && (
            <Alert 
              severity="success" 
              sx={{ mt: 2 }}
            >
              Your application has been successfully submitted!
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default JobInterestSurvey;