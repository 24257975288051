import React from "react";

const Loader = () => {
  return (
    <div className="flex h-full w-full flex items-center justify-center">
      <div
        className="w-20 h-20 border-4 border-transparent text-blue-400 text-4xl animate-spin flex items-center justify-center border-t-blue-700 rounded-full"
        style={{ animationDuration: '0.5s' }} 
      >
        <div
          className="w-16 h-16 border-4 border-transparent text-red-700 text-2xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"
          style={{ animationDuration: '0.2s' }} 
        ></div>
      </div>
    </div>
  );
};

export default Loader;
