import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import Cookies from 'js-cookie';
import Loader from '../components/Loader';

function RoleRedirect() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectBasedOnRole() {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken?.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();
        
        if (accessToken) {
          Cookies.set('accessToken', accessToken.toString(), { 
            expires: 6 / 24,
            secure: true,
            sameSite: 'Strict'
          });
          await new Promise((resolve) => setTimeout(resolve, 0));
        }

        if (groups.includes('ConvAI_Admin')) {
          navigate(`/geniehireadmin/dashboard`);
        } else if (groups.includes('Company_Admin')) {
          navigate(`/org-admin/dashboard`);
        } else if (groups.includes('HR')) {
          navigate(`/hr/dashboard`);
        } else {
          await signOut();
          setTimeout(() => {
            navigate('/')
          }, 2000); 
        }
      } catch (err) {
        navigate('/not-found'); 
      } finally {
        setLoading(false);
      }
    }
    redirectBasedOnRole();
  }, [navigate]);

  return loading ? <Loader/> : null;
}

export default RoleRedirect;
