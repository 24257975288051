import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global";
import { Star, Send, ChevronRight, MessageSquare, ThumbsUp, Award } from 'lucide-react';

const ThankYou = () => {
  const { interview_id } = useParams();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState({
    experienceRating: 0,
    comments: "",
  });
  const audio = new Audio(
    `https://nsqrbucket.s3.ap-south-1.amazonaws.com/ThankYouAudio/speech_20240926124153122.mp3`
  );

  const handleStarClick = (rating) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      experienceRating: rating,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const feedbackToSend = {
      rating: feedback.experienceRating,
      comments: feedback.comments,
    };

    try {
      await axios.post(
        `${host}/submit_feedback?interview_id=${interview_id}`,
        feedbackToSend
      );
      setFormSubmitted(true);
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
        alert("Could not play the thank you audio.");
      });
    } catch (error) {
      console.error("Failed to submit feedback:", error);
      alert("There was an error submitting your feedback. Please try again.");
    }
  };

  const handleSkipFeedback = () => {
    setFormSubmitted(true);
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
      alert("Could not play the thank you audio.");
    });
  };

  const handleShowFeedbackForm = () => {
    setShowFeedbackForm(true);
  };

  if (formSubmitted) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] py-12 px-4 sm:px-6 lg:px-8 animate-gradient-x">
        <div className="max-w-3xl mx-auto">
          <div className="relative bg-white/10 rounded-xl shadow-2xl p-8 border border-white/20">
            <div className="text-center mb-8 animate-fade-in">
              <div className="flex justify-center mb-4">
                <Award className="w-16 h-16 text-yellow-400 animate-pulse" />
              </div>
              <h1 className="text-5xl font-bold tracking-tight bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
                Thank You!
              </h1>
              <h3 className="text-xl text-black/90 mb-4">Your interview is complete.</h3>
              <p className="text-black/80 mb-4">
                You can close this tab and leave the Teams meeting.
              </p>
              <p className="text-black/80">
                We will evaluate your interview, and if qualified, you will be contacted by HR.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }  

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] py-12 px-4 sm:px-6 lg:px-8 animate-gradient-x">
      <div className="max-w-3xl mx-auto">
        <div className="relative bg-white/30  rounded-xl shadow-2xl p-8 border border-white/20">
          <div className="text-center mb-8 animate-fade-in">
            <div className="flex justify-center mb-4">
              <Award className="w-16 h-16 text-yellow-400 animate-pulse" />
            </div>
            <h1 className="text-5xl font-bold tracking-tight bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
                Thank You!
              </h1>
            <h3 className="text-xl text-black/90 mb-4">Your interview is complete.</h3>
            <p className="text-black/80 flex items-center justify-center gap-2">
              <MessageSquare className="w-5 h-5" />
              Your feedback is valuable to us. Please take a moment to share your thoughts!
            </p>
          </div>

          {!showFeedbackForm ? (
            <div className="flex flex-col sm:flex-row gap-4 justify-center animate-fade-in">
              <button
                onClick={handleShowFeedbackForm}
                className="group px-6 py-3 bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] rounded-3xl font-medium shadow-lg hover:shadow-xl hover:shadow-blue-500/20 text-white transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex items-center justify-center gap-2"
              >
                <ThumbsUp className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                Give Feedback
                <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
              <button
                onClick={handleSkipFeedback}
                className="group px-6 py-3 bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] rounded-3xl font-medium shadow-lg hover:shadow-xl hover:shadow-blue-500/20 text-white transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex items-center justify-center gap-2"
              >
                Skip Feedback
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6 animate-fade-in">
              <div>
                <label className="block text-black mb-2 flex items-center gap-2">
                  <Star className="w-5 h-5 text-yellow-400" />
                  How would you rate your interview experience?
                </label>
                <div className="flex justify-center gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      className={`w-8 h-8 cursor-pointer transition-all duration-200 hover:scale-125 ${
                        feedback.experienceRating >= star 
                          ? 'text-yellow-400 fill-yellow-400 animate-bounce-once' 
                          : 'text-gray-400'
                      }`}
                      onClick={() => handleStarClick(star)}
                      role="button"
                      tabIndex={0}
                      aria-label={`Rate ${star} out of 5`}
                      onKeyDown={(e) => e.key === "Enter" && handleStarClick(star)}
                    />
                  ))}
                </div>
              </div>

              <div>
                <label htmlFor="comments" className="block text-black mb-2 flex items-center gap-2">
                  <MessageSquare className="w-5 h-5 text-blue-400" />
                  Additional Comments:
                </label>
                <textarea
                  id="comments"
                  name="comments"
                  value={feedback.comments}
                  onChange={(e) =>
                    setFeedback((prev) => ({ ...prev, comments: e.target.value }))
                  }
                  placeholder="Share your thoughts..."
                  rows="4"
                  className="w-full px-4 py-2 rounded-lg bg-white/20  text-black placeholder-gray-300 border border-white/30 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <button
                  type="submit"
                  className="group px-6 py-3 bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] rounded-3xl font-medium shadow-lg hover:shadow-xl hover:shadow-blue-500/20 text-white transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex items-center justify-center gap-2"
                  >
                  <Send className="w-5 h-5 group-hover:-translate-y-1 group-hover:translate-x-1 transition-transform" />
                  Submit Feedback
                </button>
                <button
                  type="button"
                  onClick={handleSkipFeedback}
                  className="group px-6 py-3 bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] rounded-3xl font-medium shadow-lg hover:shadow-xl hover:shadow-blue-500/20 text-white transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 flex items-center justify-center gap-2"
                >
                  Skip Feedback
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;