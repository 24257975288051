import React, { useState, useEffect } from 'react';
import axios from 'axios';
import host from '../../global';
import HostCodingProblem from '../HostCodingProblem';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

const CreateProblem = () => {
  const initialFormData = {
    title: '',
    description: '',
    input_format: '',
    output_format: '',
    constraints: '',
    examples: [{ input: '', output: '', explanation: '' }],
    test_cases: [{ input: '', output: '' }],
    final_test_cases: [{ input: '', output: '' }],
    boilerplate_code: {}
  };
  const accessToken = Cookies.get('accessToken');
  const [formData, setFormData] = useState(initialFormData);
  const [sets, setSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('javascript');
  const [code, setCode] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const [codingProblems, setCodingProblems] = useState([]);
  const [availableProblems, setAvailableProblems] = useState([]);
  const [selfHostedProblems, setSelfHostedProblems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [codingProblemOrigins, setCodingProblemOrigins] = useState({});
  const handleBoilerplateCodeChange = (language, code) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      boilerplate_code: {
        ...prevFormData.boilerplate_code,
        [language]: code
      }
    }));
  };

  useEffect(() => {
    fetchSets();        // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedSet) {
      fetchCodingProblems(selectedSet);
    } else {
      setCodingProblems([]);
      fetchAllAvailableProblems();
    }
  }, [selectedSet]);

  useEffect(() => {
    setCode(formData.boilerplate_code[selectedLanguage] || '');
  }, [selectedLanguage, formData.boilerplate_code]);

  const fetchSets = async () => {
    try {
      const response = await axios.get(`${host}/api/codingsets/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setSets(Object.keys(response.data));
    } catch (error) {
      toast.error('Error fetching sets:', error);
    }
  };

  const fetchAllAvailableProblems = async () => {
    try {
      const response = await axios.get(`${host}/api/available_problems`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log('Response received:', response.data);
      setAvailableProblems(response.data.available_problems);
      setSelfHostedProblems(response.data.self_hosted);
      setCodingProblems([]);
    } catch (error) {
      toast.error('Error fetching available problems:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await axios.post(`${host}/api/createproblems`, {
        ...formData,
        set_name: selectedSet || newSetName, 
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      
      toast.success('Problem created successfully!');
      setFormData(initialFormData);
      
      if (selectedSet) {
        await fetchCodingProblems(selectedSet);
      } else {
        await fetchAllAvailableProblems();
      }
    } catch (error) {
      toast.error('Error creating problem:', error);
    }
};

  const handleExamplesChange = (index, key, value) => {
    const newExamples = [...formData.examples];
    newExamples[index][key] = value;
    setFormData({ ...formData, examples: newExamples });
  };

  const handleTestCasesChange = (index, key, value) => {
    const newTestCases = [...formData.test_cases];
    newTestCases[index][key] = value;
    setFormData({ ...formData, test_cases: newTestCases });
  };

  const handleFinalTestCasesChange = (index, key, value) => {
    const newFinalTestCases = [...formData.final_test_cases];
    newFinalTestCases[index][key] = value;
    setFormData({ ...formData, final_test_cases: newFinalTestCases });
  };

  const handleCreateOrUpdateSet = async () => {
    if (!newSetName.trim() && !selectedSet) {
      toast.warn('Please enter a set name or select an existing set.');
      return;
    }

    const setName = selectedSet || newSetName;
    const problemIds = codingProblems.map(problem => problem.problem_id);

    try {
      await axios.post(`${host}/api/create-set`, {
        name: setName,
        problem_ids: problemIds
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}` 
        }
      });
      toast.success('Set saved successfully!');
      setNewSetName('');
      //setCreateSetVisible(false);
      fetchSets();
    } catch (error) {
      toast.error('Error saving set:', error);
    }
  };

const fetchCodingProblems = async (setName) => {
  if (!setName) {
    toast.error('Set name or admin ID is missing.');
    return;
  }

  try {
    const [codingResponse, availableResponse] = await Promise.all([
      axios.post(`${host}/api/coding-problems`, 
        { set_name: setName },
        { headers: { Authorization: `Bearer ${accessToken}` }}
      ),
      axios.get(`${host}/api/available_problems`, 
        { headers: { Authorization: `Bearer ${accessToken}` }}
      )
    ]);

    const setProblems = codingResponse.data;
    const availableProblems = availableResponse.data.available_problems.filter(
      problem => !setProblems.some(cp => cp.problem_id === problem.problem_id)
    );
    
    const selfHostedProblems = availableResponse.data.self_hosted.filter(
      problem => !setProblems.some(cp => cp.problem_id === problem.problem_id)
    );

    setCodingProblems(setProblems);
    setAvailableProblems(availableProblems);
    setSelfHostedProblems(selfHostedProblems);

    const newOrigins = {};
    setProblems.forEach(problem => {
      newOrigins[problem.problem_id] = availableResponse.data.self_hosted.some(
        p => p.problem_id === problem.problem_id
      ) ? 'selfHosted' : 'available';
    });
    setCodingProblemOrigins(newOrigins);

  } catch (error) {
    toast.error('Error fetching coding problems:', error);
  }
};

  const moveToCodingProblems = (problem) => {
    setCodingProblems([...codingProblems, problem]);
    setAvailableProblems(availableProblems.filter(p => p.problem_id !== problem.problem_id));
    setCodingProblemOrigins(prev => ({
      ...prev,
      [problem.problem_id]: 'available'
    }));
  };

  const moveToCodingProblemsFromSelfHosted = (problem) => {
    setCodingProblems([...codingProblems, problem]);
    setSelfHostedProblems(selfHostedProblems.filter(p => p.problem_id !== problem.problem_id));
    setCodingProblemOrigins(prev => ({
      ...prev,
      [problem.problem_id]: 'selfHosted'
    }));
  };

  const removeFromCodingProblems = (problem) => {
    const origin = codingProblemOrigins[problem.problem_id];
    
    if (origin === 'selfHosted') {
      setSelfHostedProblems([...selfHostedProblems, problem]);
    } else {
      setAvailableProblems([...availableProblems, problem]);
    }

    setCodingProblems(codingProblems.filter(p => p.problem_id !== problem.problem_id));
    setCodingProblemOrigins(prev => {
      const newOrigins = { ...prev };
      delete newOrigins[problem.problem_id];
      return newOrigins;
    });
  };

  return (
    <>
      <div className="company-admin-container min-h-screen bg-gray-100 flex flex-col items-center py-4 bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
        <div className={`content w-full max-w-5xl p-4 rounded-lg bg-white shadow-lg transition-all backdrop-blur-[10px] bg-white/30`}>
            <div className="create-problem-container space-y-6">
              <h1 className="text-2xl font-bold text-gray-800 text-center">Create Coding Problem</h1>
  
              <div className="form-container space-y-6">
                <div className="set-selection space-y-4">
                  <div className="select-set flex items-center space-x-4">
                    <label className="font-semibold text-gray-600">Select a Set :</label>
                    <select
                      value={selectedSet}
                      onChange={(e) => setSelectedSet(e.target.value)}
                      className="border border-gray-300 rounded px-3 py-2 focus:ring focus:ring-indigo-200 focus:border-indigo-400 backdrop-blur-[10px] bg-white/30"
                    >
                      <option value="">-- Select a Set --</option>
                      {sets.map((set, index) => (
                        <option key={index} value={set}>{set}</option>
                      ))}
                    </select>
                  </div>
  
                  <div className="new-set space-y-2">
                    <label className="font-semibold text-gray-600">Create New Set :</label>
                    <div className="flex space-x-4">
                      <input
                        type="text"
                        value={newSetName}
                        onChange={(e) => setNewSetName(e.target.value)}
                        placeholder="New Set Name"
                        className="border border-gray-300 rounded px-3 py-2 w-full focus:ring focus:ring-indigo-200 focus:border-indigo-400 backdrop-blur-[10px] bg-white/30"
                      />
                      <button
                        onClick={handleCreateOrUpdateSet}
                        className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition"
                      >
                        {selectedSet ? 'Update Set' : 'Create Set'}
                      </button>
                    </div>
                  </div>
                </div>
  
                <div className="problems-section grid grid-cols-1 md:grid-cols-2 gap-8 ">
                  <div className="coding-problems-section bg-gray-50 p-4 rounded-lg shadow-sm backdrop-blur-[10px] bg-white/30">
                    <h2 className="text-lg font-semibold text-gray-700">Coding Problems</h2>
                    <ul className="mt-4 space-y-2">
                      {codingProblems.length > 0 ? (
                        codingProblems.map((problem, index) => (
                          <li key={index} className="flex justify-between items-center bg-white p-2 rounded border">
                            <h3 className="font-medium text-gray-800">{problem.title}</h3>
                            <button
                              onClick={() => removeFromCodingProblems(problem)}
                              className="text-red-500 hover:text-red-500"
                            >
                              -
                            </button>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-500">No coding problems available.</p>
                      )}
                    </ul>
                  </div>
  
                  <div className="available-problems-section bg-gray-50 p-4 rounded-lg shadow-sm backdrop-blur-[10px] bg-white/30">
                    <h2 className="text-lg font-semibold text-gray-700">Available Problems</h2>
                    <ul className="mt-4 space-y-2">
                      {availableProblems.length > 0 ? (
                        availableProblems.map((problem) => (
                          <li key={problem.problem_id} className="flex justify-between items-center bg-white p-2 rounded border backdrop-blur-[10px] bg-white/30">
                            <h3 className="font-medium text-gray-800">{problem.title}</h3>
                            <button
                              onClick={() => moveToCodingProblems(problem)}
                              className="text-green-500 hover:bg-transparent! hover:text-green-500!"
                            >
                              +
                            </button>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-500">No available problems.</p>
                      )}
                    </ul>
                  </div>
                </div>

                {selfHostedProblems.length > 0 && (
                  <div className="available-problems-section bg-gray-50 p-4 rounded-lg shadow-sm backdrop-blur-[10px] bg-white/30">
                    <h2 className="text-lg font-semibold text-gray-700">Self Hosted Problems</h2>
                    <ul className="mt-4 space-y-2">
                      {selfHostedProblems.map((problem) => (
                        <li key={problem.problem_id} className="flex justify-between items-center bg-white p-2 rounded border backdrop-blur-[10px] bg-white/30">
                          <h3 className="font-medium text-gray-800">{problem.title}</h3>
                          <button
                            onClick={() => moveToCodingProblemsFromSelfHosted(problem)}
                            className="text-green-500 hover:bg-transparent! hover:text-green-500!"
                          >
                            +
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                
  
                {!formVisible ? (
                  <>
                    <button
                      onClick={() => setShowPopup(true)}
                      className="show-form-button w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                    >
                      Host My Problem
                    </button>
  
                    {showPopup && (
                      <div className="popup-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="popup-content bg-white p-6 rounded-lg shadow-lg relative w-full max-w-md space-y-4">
                          <button
                            className="popup-close absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={() => setShowPopup(false)}
                          >
                            ×
                          </button>
                          <h2 className="text-xl font-semibold text-center text-gray-800">What would you like to do?</h2>
                          <div className="popup-buttons flex flex-col space-y-4">
                            <button
                              onClick={() => toast.success('Raise a ticket with your problem description')}
                              className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                            >
                              Contact Us
                            </button>
                            <button
                              onClick={() => {
                                setFormVisible(true);
                                setShowPopup(false);
                              }}
                              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                            >
                              Try Myself
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <HostCodingProblem
                    formData={formData}
                    setFormData={setFormData}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    code={code}
                    setCode={setCode}
                    handleBoilerplateCodeChange={handleBoilerplateCodeChange}
                    handleSubmit={handleSubmit}
                    handleExamplesChange={handleExamplesChange}
                    handleTestCasesChange={handleTestCasesChange}
                    handleFinalTestCasesChange={handleFinalTestCasesChange}
                    setFormVisible={setFormVisible}
                  />
                )}
              </div>
            </div>
        </div>
      </div>
    </>
  );  
};

export default CreateProblem;
