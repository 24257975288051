import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, IconButton, Avatar } from "@mui/material";
import { Tooltip } from '@mui/material';
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { useNavigate } from "react-router-dom";
import "../../css/HRPortal.css";
import axios from "axios";
import host from "../../global";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Cookies from "js-cookie";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { toast } from "react-toastify";
import { signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { LogOut, UserPen } from "lucide-react";

const Header = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isMCQDropdownOpen, setIsMCQDropdownOpen] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const accessToken = Cookies.get("accessToken");
  const popupRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const availablePages = [
    { name: "Home", route: "/org-admin/dashboard" },
    { name: "Candidate Reports", route: "/org-admin/candidatereports" },
    { name: "Candidate Analytics", route: "/org-admin/candidateanalytics" },
    { name: "Profile", route: "/org-admin/org-admin-editprofile" },
    { name: "Resume Parser", route: "/org-admin/checkresume" },
    { name: "Create JD", route: "/org-admin/createaijd" },
    { name: "HR Profiles", route: "/org-admin/hrprofiles" },
    { name: "View JDs", route: "/org-admin/created-jds" },
  ];
  const fetchProfileData = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
      const response = await axios.get(`${host}/getadmindetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching Profile Data", error);
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, [accessToken]);
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      toast.error("Error signing out");
    }
  };
  const profileImageUrl = userData?.profile
    ? `${process.env.REACT_APP_PROFILE_PHOTOS_HOST}/OrganizationAdmins/${userId}_${userData.profile}`
    : "";
  const toggleTicketModal = () => setIsTicketModalOpen(!isTicketModalOpen);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${host}/raiseticket`,
        {},
        {
          params: { title: ticketTitle, description: ticketDescription },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Ticket Submitted Successfully");
        setTicketTitle("");
        setTicketDescription("");
        toggleTicketModal();
      } else {
        toast.error("Error submitting ticket:", response.statusText);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleMouseEnter = () => {
    setIsPopupVisible(true);
  };
  const handleMouseLeave = () => {
    setIsPopupVisible(false);
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery("");
    setSuggestions([]);
  };
  const handleNotificationBellClick = async (event) => {
    fetchPendingAccountRequest();
    setIsPopupVisible(!isPopupVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActionsDropdownOpen && !event.target.closest(".dropdown")) {
        setIsActionsDropdownOpen(false);
      }
      if (isMCQDropdownOpen && !event.target.closest(".mcqdropdown-menu")) {
        setIsMCQDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActionsDropdownOpen, isMCQDropdownOpen]);
  const fetchPendingAccountRequest = async () => {
    try {
      const response = await axios.get(`${host}/pendingaccounts`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setNotification(response.data.results);
    } catch (error) {
      console.error("Error fetching Profile Data", error);
    }
  };
  return (
    <>
      <header className="main-header bg-gradient-to-r from-[#3ECFDA]/10 to-[#0952DA]/10">
        <nav>
          <img src="../Favicon.png" alt="Logo" height={10}/>
          <Button onClick={() => navigate(`/org-admin/dashboard`)}>
            Dashboard
          </Button>
          <Button onClick={() => navigate(`/org-admin/hrprofiles`)}>
            HR Profiles
          </Button>
          <Button onClick={() => navigate(`/org-admin/candidatereports`)}>
            Candidate Reports
          </Button>
          {/* <Button
              onClick={() => navigate(`/org-admin/candidatesourcingdashboard`)}
            >
              Candidate Sourcing
            </Button> */}
          <div className="actions-container">
            <Button>Actions</Button>
            <div className="actions-dropdown">
              <Button
                className="hover:bg-[#3ECFDA]/10 w-full"
                onClick={() => navigate(`/org-admin/checkresume`)}
              >
                Resume Parser
              </Button>
              <Button
                className="hover:bg-[#3ECFDA]/10 w-full"
                onClick={() => navigate(`/org-admin/created-jds`)}
              >
                View Created JDs
              </Button>
              <div
                className="dropdown-item mcq-dropdown flex w-full"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0",
                  height: "40px",
                }}
              >
                <Button className="hover:bg-[#3ECFDA]/10 w-full">MCQ Sets</Button>
                <div className="mcq-submenu">
                  <Button
                    className="submenu-item hover:bg-[#3ECFDA]/10 w-full"
                    onClick={() => navigate(`/org-admin/fetchmcq`)}
                  >
                    - Get from GenieHire
                  </Button>
                  <Button
                    className="submenu-item hover:bg-[#3ECFDA]/10 w-full"
                    onClick={() => navigate(`/org-admin/createmcqset`)}
                  >
                    - Create Own Set
                  </Button>
                </div>
              </div>
              <Button
                className="hover:bg-[#3ECFDA]/10 w-full"
                onClick={() => navigate(`/org-admin/createcodingproblemset`)}
              >
                Coding Problem Sets
              </Button>
              <Button
                className="submenu-item hover:bg-[#3ECFDA]/10 w-full"
                onClick={() => navigate(`/org-admin/candidateanalytics`)}
              >
                Candidate Analytics
              </Button>
            </div>
          </div>
        </nav>
        <div className="main-header-actions flex items-center gap-4">
          {/* <TextField
            type="search"
            placeholder="Search..."
            variant="outlined"
            size="small"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {suggestions.length > 0 && (
            <div className="suggestions-dropdown">
              <ul>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.route)}
                  >
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            </div>
          )} */}
          {noResults && <p>No results found</p>}
          <IconButton className="hover:text-white" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}> <NotificationsActiveRoundedIcon fontSize='large' sx={{color: '#5286E8'}}/></IconButton>
          {isPopupVisible && (
  <div ref={popupRef} className="bg-gradient-to-r from-blue-400/20 to-purple-400/20 opacity-100 rounded-2xl shadow-2xl border-2 border-purple-200 fixed right-4 top-16 w-80 z-[100]">
    {Array.isArray(notification) && notification.length > 0 ? (
      <div className="max-h-64 overflow-y-auto">
        <ul className="divide-y divide-gray-300">
          {notification.map((name, index) => (
            <li
              key={index}
              className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 p-4"
            >
              <div className="flex items-center space-x-3">
                <div className="text-green-500">
                  <CheckCircleOutlineRoundedIcon
                    size={24}
                    className="flex-shrink-0"
                  />
                </div>
                <div className="flex-grow">
                  <p className="text-sm text-gray-700">
                    {index + 1}. {name}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <div className="p-4 text-center text-gray-500">
        No pending approvals.
      </div>
    )}
  </div>
)}

          <Tooltip title="Raise Ticket" arrow>
            <IconButton
              className="hover:text-white"
              onClick={toggleTicketModal}
            >
              {" "}
              <HelpCenterIcon fontSize="large" sx={{color: '#5286E8'}}/>
            </IconButton>
          </Tooltip>
          <div className="group relative ml-2">
            <Avatar
              alt={userData?.name ? userData.name : "User Profile"}
              src={profileImageUrl || ""}
              sx={{ width: 35, height: 35, background: '#5286E8',color:'#FFFFFF'}} 
            />
            <div className="hidden group-hover:flex group-hover:items-start absolute top-full right-0 bg-none z-50 p-1 flex-col">
              <div className="w-full sm:w-[350px] bg-gradient-to-br from-blue-50 to-purple-100 rounded-2xl shadow-2xl border-2 border-purple-200 overflow-hidden">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-purple-400/20 opacity-75"></div>
                  {userData ? (
                    <div className="relative z-10 flex flex-col sm:flex-row items-center p-2">
                      {profileImageUrl ? (
                        <img
                          className="w-[110px] h-[110px] rounded-full border-4 border-white shadow-lg object-cover"
                          src={profileImageUrl}
                          alt={userData?.name ? userData.name[0].toUpperCase() : "Profile Image"}
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 110, height: 110 }}
                          className="text-white text-2xl font-bold bg-gradient-to-br from-blue-300 to-purple-300"
                        >
                          {userData?.name ? userData.name[0].toUpperCase() : "?"}
                        </Avatar>
                      )}
                      <div className="mt-4 sm:mt-0 sm:ml-8 text-center sm:text-left w-full">
                        <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mt-2">
                          {userData?.name || "No Name Available"}
                        </h2>
                        <p className="text-lg text-gray-600 font-medium mt-1">
                          Organization Admin
                        </p>
                        <div className="flex justify-start sm:justify-start gap-6 mt-2">
                          <UserPen
                            className="text-blue-600 bg-blue-100 rounded-full p-2 w-10 h-10 hover:bg-blue-200 hover:text-blue-700 transition-all duration-300 cursor-pointer"
                            onClick={() => navigate(`/org-admin/editprofile`)}
                          />
                          <LogOut
                            className="text-red-600 bg-red-100 rounded-full p-2 w-10 h-10 hover:bg-red-200 hover:text-red-700 transition-all duration-300 cursor-pointer"
                            onClick={handleSignOut}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="relative flex w-64 animate-pulse gap-2 p-4">
                      <div class="h-12 w-12 rounded-full bg-slate-400"></div>
                      <div class="flex-1">
                        <div class="mb-1 h-5 w-3/5 rounded-lg bg-slate-400 text-lg"></div>
                        <div class="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
                      </div>
                      <div class="absolute bottom-5 right-0 h-4 w-4 rounded-full bg-slate-400"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isTicketModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[99999999]">
            <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-center">
                Raise a Ticket
              </h3>
              <select
                value={ticketTitle}
                onChange={(e) => setTicketTitle(e.target.value)}
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500"
              >
                <option value="">Select ticket title</option>
                {[
                  "Issue with Job Description",
                  "Technical Issue",
                  "HR Profile Issue",
                  "Host Coding problem",
                  "Other",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <textarea
                value={ticketDescription}
                onChange={(e) => setTicketDescription(e.target.value)}
                placeholder="Enter ticket description"
                rows="5"
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Submit
                </button>
                <button
                  onClick={toggleTicketModal}
                  className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};
export default Header;
