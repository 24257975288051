import React, { useState, useEffect, useRef } from "react";
import { Button, TextField, IconButton, Avatar } from "@mui/material";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { useNavigate } from "react-router-dom";
import "../../css/HRPortal.css";
import axios from "axios";
import host from "../../global";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { LogOut, UserPen } from "lucide-react";

const Header = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const accessToken = Cookies.get("accessToken");
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const availablePages = [
    { name: "Home", route: `/hr/dashboard` },
    { name: "Candidate Reports", route: `/hr/candidatereports` },
    { name: "Job Vacancies", route: `/hr/jobvacancies` },
    { name: "Profile", route: `/hr/hreditprofile` },
    { name: "Single Resume Parser", route: `/hr/checkresume` },
    { name: "Bulk Resume Parser", route: `/hr/BulkResume` },
  ];
  const fetchProfileData = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
      const response = await axios.get(`${host}/gethrdetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };
  useEffect(() => {
    fetchProfileData();
  }, [accessToken]);
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      toast.error("Error signing out");
    }
  };
  const profileImageUrl = userData?.profile
    ? `${process.env.REACT_APP_PROFILE_PHOTOS_HOST}/HRProfiles/${userId}_${userData.profile}`
    : "";

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery("");
    setSuggestions([]);
  };

  const handlenotificationClick = (upload_id) => {
    navigate(`/hr/BulkResume/Bulkresumemodel/${upload_id}`);
  };
  useEffect(() => {
    const fetchBulkResumeUploadResult = async () => {
      try {
        const response = await axios.get(`${host}/bulkuploadresult`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNotification(response.data.results);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchBulkResumeUploadResult();
  }, [accessToken]);
  return (
    <>
      <header className="main-header bg-gradient-to-r from-[#3ECFDA]/10 to-[#0952DA]/10">
        <nav>
        <img src="../Favicon.png" alt="Logo" height={10}/>
          <Button onClick={() => navigate(`/hr/dashboard`)}>Dashboard</Button>
          <Button onClick={() => navigate(`/hr/candidatereports`)}>
            Candidate Reports
          </Button>
          <Button onClick={() => navigate(`/hr/jobvacancies`)}>
            Job Vacancies
          </Button>
        </nav>
        <div className="main-header-actions">
          {/* <TextField
            type="search"
            placeholder="Search..."
            variant="outlined"
            size="small"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {suggestions.length > 0 && (
            <div className="suggestions-dropdown">
              <ul>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.route)}
                  >
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {noResults && (
            <div className="suggestions-dropdown">
              <ul>
                <li>No results found</li>
              </ul>
            </div>
          )}
          <div className="relative group">
            <IconButton className="custom-action-button">
              <NotificationsActiveRoundedIcon fontSize="large" sx={{color: '#5286E8'}}/>
            </IconButton>
            <div className="hidden group-hover:block absolute right-0 top-15 w-96 bg-white rounded-xl overflow-hidden z-[100]">
              <div className="bg-gradient-to-r from-blue-400/20 to-purple-400/20 opacity-100 rounded-2xl shadow-2xl border-2 border-purple-200">
                {notification.length > 0 ? (
                  <div className="max-h-64 overflow-y-auto">
                    <ul className="divide-y divide-gray-300">
                      {notification.map(({ upload_id, job_role }, index) => (
                        <li
                          key={index}
                          onClick={() => handlenotificationClick(upload_id)}
                          className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 p-4"
                        >
                          <div className="flex items-center space-x-3">
                            <div className="text-green-500">
                              <CheckCircleOutlineRoundedIcon size={24} />
                            </div>
                            <div className="flex-grow">
                              <p className="text-sm text-gray-700">
                                All resumes for the {job_role} position have
                                been successfully parsed.
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="p-4 text-center text-gray-500">
                    No New Notifications.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="group relative ml-2">
            <Avatar
              alt={userData?.name ? userData.name : "User Profile"}
              src={profileImageUrl || ""}
              sx={{ width: 35, height: 35, background: '#5286E8',color:'#FFFFFF'}} 
            />
            <div className="hidden group-hover:flex group-hover:items-start absolute top-full right-0 bg-none z-50 p-1 flex-col">
              <div className="w-full sm:w-[350px] bg-gradient-to-br from-blue-50 to-purple-100 rounded-2xl shadow-2xl border-2 border-purple-200 overflow-hidden">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-purple-400/20 opacity-75"></div>
                  {userData ? (
                    <div className="relative z-10 flex flex-col sm:flex-row items-center p-2">
                      {profileImageUrl ? (
                        <img
                          className="w-[110px] h-[110px] rounded-full border-4 border-white shadow-lg object-cover"
                          src={profileImageUrl}
                          alt={
                            userData?.name
                              ? userData.name[0].toUpperCase()
                              : "Profile Image"
                          }
                        />
                      ) : (
                        <Avatar
                          sx={{ width: 110, height: 110 }}
                          className="text-white text-2xl font-bold bg-gradient-to-br from-blue-300 to-purple-300"
                        >
                          {userData?.name
                            ? userData.name[0].toUpperCase()
                            : "?"}
                        </Avatar>
                      )}

                      <div className="mt-4 sm:mt-0 sm:ml-8 text-center sm:text-left w-full">
                        <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mt-2">
                          {userData?.name || "No Name Available"}
                        </h2>
                        <p className="text-lg text-gray-600 font-medium mt-1">
                          {userData?.department || "No Department"}
                        </p>
                        <div className="flex justify-start sm:justify-start gap-6 mt-2">
                          <UserPen
                            className="text-blue-600 bg-blue-100 rounded-full p-2 w-10 h-10 hover:bg-blue-200 hover:text-blue-700 transition-all duration-300 cursor-pointer"
                            onClick={() => navigate(`/hr/hreditprofile`)}
                          />
                          <LogOut
                            className="text-red-600 bg-red-100 rounded-full p-2 w-10 h-10 hover:bg-red-200 hover:text-red-700 transition-all duration-300 cursor-pointer"
                            onClick={handleSignOut}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="relative flex w-64 animate-pulse gap-2 p-4">
                      <div class="h-12 w-12 rounded-full bg-slate-400"></div>
                      <div class="flex-1">
                        <div class="mb-1 h-5 w-3/5 rounded-lg bg-slate-400 text-lg"></div>
                        <div class="h-5 w-[90%] rounded-lg bg-slate-400 text-sm"></div>
                      </div>
                      <div class="absolute bottom-5 right-0 h-4 w-4 rounded-full bg-slate-400"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
