import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Pagination, PaginationItem } from "@mui/material";
import "../../css/HRPortal.css";
import { Authenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import host from "../../global";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
const determineStatus = (status) => {
  if (status === "Completed") {
    return <div className="status-completed">Completed</div>;
  } else if (status === "In Progress") {
    return <div className="status-in-progress">In Progress</div>;
  } else {
    return <div className="status-pending">Pending</div>;
  }
};
export default function CandidateTable() {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [itemsPerPage] = useState(10);
  const [areCandidatesLoading, setAreCandidatesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const statusOptions = ["Completed", "In Progress", "Pending"];
  const isSmallScreen = useMediaQuery("(max-width: 750px)");
  const accessToken = Cookies.get("accessToken");
  const location = useLocation();
  const { page, job_position, combined_status } = location.state || {};
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [filters, setFilters] = useState({
    jobPosition: job_position || "All",
    combinedStatus: combined_status || "All",
  });
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  useEffect(() => {
    if (debouncedQuery.trim() === "") {
      setSearchResults([]);
      return;
    }
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(`${host}/search_candidates`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            search_query: debouncedQuery,
          },
        });
        setSearchResults(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchSearchResults();
  }, [debouncedQuery, accessToken]);
  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/jobroles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAreCandidatesLoading(true);
        const { jobPosition, combinedStatus } = filters;
        const response = await axios.get(`${host}/get_candidates_data`, {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            job_position: jobPosition,
            combined_status: combinedStatus,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setCandidates(response.data.candidates);
        setTotalCandidates(response.data.total_count);
        setAreCandidatesLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        if (error.message === "UserUnAuthenticatedException") {
          navigate("/");
        }
      }
    };
    fetchData();
  }, [filters, currentPage, accessToken, navigate, itemsPerPage]);
  const totalPages = Math.ceil(totalCandidates / itemsPerPage);
  const getPaginationRange = (currentPage, totalPages) => {
    const range = [];
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 9);
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };
  const totalPagesArray = getPaginationRange(currentPage, totalPages);
  const handleViewCandidate = (interviewId) => {
    navigate(`/hr/evaluationpage/${interviewId}`, {
      state: {
        page: currentPage,
        job_position: filters.jobPosition,
        combined_status: filters.combinedStatus,
      },
    });
  };
  if (areCandidatesLoading) {
    return (
      <Loader />
    );
  }
  return (
    <Authenticator>
      <div className="candidate-reports bg-gradient-to-br from-[#d1f0ff] to-[#ffe4ea] rounded-lg relative overflow-hidden">
        <div className="table-container backdrop-blur-[10px] bg-white/30 rounded-lg p-5 shadow-md">
          <h1>Candidate Reports</h1>
          <div className="search-option">
            <div className="search-input">
              <TextField
                type="search"
                placeholder="Search by name"
                variant="outlined"
                size="small"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="search-results" style={{ backdropFilter: "blur(10px)" }}>
                {searchResults.length > 0 ? (
                  <ul>
                    {searchResults.map((candidate, index) => (
                      <li
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewCandidate(candidate.interview_id)}
                      >
                        {candidate.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  searchQuery.trim() !== "" && (
                    <p className="no-results" class="backdrop-blur-[10px] bg-[rgba(255,_255,_255,_0.6)]">No results found</p>
                  )
                )}
              </div>
            </div>
            <FormControl
              variant="outlined"
              size="small"
              style={{
                marginRight: "10px",
                minWidth: 150,
                maxWidth: 150,
                borderRadius: "8px"
              }}
            >
              <InputLabel>Job Position</InputLabel>
              <Select
                value={filters.jobPosition}
                onChange={(e) => {
                  setCurrentPage(1);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    jobPosition: e.target.value,
                  }));
                }}
                name="jobPosition"
                label="Job Position"
              >
                <MenuItem value="All">All</MenuItem>
                {jobPositions.map((position) => (
                  <MenuItem key={position} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{
                minWidth: 150,
                maxWidth: 150,
                borderRadius: "8px"
              }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.combinedStatus}
                onChange={(e) => {
                  setCurrentPage(1);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    combinedStatus: e.target.value,
                  }));
                }}
                name="combinedStatus"
                label="Status"
              >
                <MenuItem value="All">All</MenuItem>
                {statusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Table>
            <Thead>
              <Tr>
                <Th className="custom-th" >Invited Date</Th>
                <Th className="custom-th">Candidate Name</Th>
                <Th className="custom-th">Job Role</Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>MCQ Score</Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>Coding Score</Th>
                <Th className="custom-th" style={{ width: "fit-content" }}>Interview Score</Th>
                <Th className="custom-th">Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {candidates.map((candidate) => {
                return (
                  <Tr
                    style={{ cursor: "pointer" }}
                    key={candidate.interview_id}
                    onClick={() => handleViewCandidate(candidate.interview_id)}
                  >
                    <Td>{candidate.invited_date}</Td>
                    <Td className="p-0 ">
                      {!isSmallScreen ? (
                        <div
                          className="flex items-center"
                          style={{ whiteSpace: "normal", textAlign: "left", fontWeight: 450 }}
                        >
                          <div style={{ whiteSpace: "normal" }}>
                            {candidate.candidate_name || "Unknown"}
                          </div>
                        </div>
                      ) : (
                        <div
                          class="text-base font-[450] text-[#1f2937] whitespace-normal"
                        >
                          {candidate.candidate_name || "Unknown"}
                        </div>
                      )}
                    </Td>
                    <Td class="whitespace-normal">
                      {candidate.jdposition || "Unknown Role"}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.mcq_percent) || candidate.mcq_percent === "NA"
                            ? "black"
                            : candidate.mcq_percent > 60
                              ? "green"
                              : candidate.mcq_percent >= 45
                                ? "orange"
                                : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.mcq_percent) || candidate.mcq_percent === "NA"
                        ? "NA"
                        : Number(candidate.mcq_percent).toFixed(2)}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.coding_percent) || candidate.coding_percent === "NA"
                            ? "black"
                            : candidate.coding_percent > 60
                              ? "green"
                              : candidate.coding_percent >= 45
                                ? "orange"
                                : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.coding_percent) || candidate.coding_percent === "NA"
                        ? "NA"
                        : Number(candidate.coding_percent).toFixed(2)}
                    </Td>
                    <Td
                      style={{
                        width: "fit-content",
                        color:
                          isNaN(candidate.interview_percent) || candidate.interview_percent === "NA"
                            ? "black"
                            : candidate.interview_percent > 60
                              ? "green"
                              : candidate.interview_percent >= 45
                                ? "orange"
                                : "red",
                        fontWeight: "400",
                      }}
                    >
                      {isNaN(candidate.interview_percent) || candidate.interview_percent === "NA"
                        ? "NA"
                        : Number(candidate.interview_percent).toFixed(2)}
                    </Td>
                    <Td className="report-status">
                      {determineStatus(candidate.combined_status)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className="table-footer">
            <div className="pagination-text">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * itemsPerPage, totalCandidates)}
              </span>{" "}
              of <span className="font-medium">{totalCandidates}</span> results
            </div>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              renderItem={(item) => {
                if (totalPagesArray.includes(item.page)) {
                  return (
                    <PaginationItem
                      component="button"
                      onClick={() => setCurrentPage(item.page)}
                      {...item}
                    />
                  );
                }
                return null;
              }}
            />
          </div>
        </div>
      </div>
    </Authenticator>
  );
}
