import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CheckResume from './components/hrportal/CheckResume.jsx';
import CheckResumeOrgAdmin from './components/orgadmin/CheckResumeOrgAdmin.jsx';
import AdminLogin from './pages/LoginPage.jsx';
import InterviewPortal from './pages/Candidate Pages/InterviewPortal.jsx';
import EvaluationPortal from './components/hrportal/EvaluationReport.jsx';
import EvatuationReportOrgAdmin from './components/orgadmin/EvatuationReportOrgAdmin.jsx';
import ThankYou from './pages/Candidate Pages/ThanksPage.jsx';
import BulkResumeUpload from './components/hrportal/BulkResumeParser.jsx';
import MCQTest from './pages/Candidate Pages/MCQTestPage.jsx';
import TestHomePage from './pages/Candidate Pages/testHomePage.jsx';
import MCQInstructions from './components/Instructions/MCQInstructions.jsx'
import CodingInstructions from './components/Instructions/CodingInstructions.jsx'
import InterviewInstructions from './components/Instructions/InterviewInstructions.jsx'
import CodingProblemsPage from './pages/Candidate Pages/CodingProblemsPage.jsx';
import NotFound from './pages/NotFound.jsx';
import OrgAdminPortal from './components/orgadmin/OrgAdminPortal.jsx';
import HRPortal from './components/hrportal/HRPortal.jsx';
import RoleRedirect from './pages/RoleRedirect.jsx';
import HrProfile from './components/orgadmin/HRProfiles.jsx';
import CreatedJDs from './components/orgadmin/ViewCreatedJDs.jsx';
import CreateMCQSet from './components/orgadmin/CreateMCQSet.jsx';
import Fetch from './components/orgadmin/fetchmcq.jsx';
import GenieHirePortal from './components/geniehireadmin/GenieHireAdminDash.jsx';
import CreateCodingProblemSet from './components/orgadmin/CreateCodingProblemSet.jsx';
import BulkResumeSummary from './components/hrportal/BulkResumeSummary.jsx';
import OrgAdminEditProfile from './components/orgadmin/OrgAdminEditProfile.jsx';
import HREditProfile from './components/hrportal/HREditProfile.jsx';
import ScheduleTest from './pages/Candidate Pages/ScheduleTest.jsx';
import HRDashBoard from './components/hrportal/Dashboard.jsx'
import JobVacancies from './components/hrportal/JobVacancies.jsx'
import CandidateReports from './components/hrportal/CandidateReports.jsx'
import OrgAdminDashBoard from './components/orgadmin/Dashboard.jsx'
import OrgAdminCandidateReports from './components/orgadmin/CandidateReports.jsx'
import CreateAIJD from './components/orgadmin/CreateJD.jsx'
import CandidatePortal from './pages/Candidate Pages/CandidatePortal.jsx'
import CandidateAnalytics from './components/orgadmin/CandidateAnalytics.jsx'
import Feedback from './components/geniehireadmin/Feedback.jsx'
import GenieHireDashboard from './components/geniehireadmin/Dashboard.jsx'
import JobInterestSurvey from './pages/Candidate Pages/JobInterestSurvey.jsx';
import CandidateList  from './components/orgadmin/Candidatelist.jsx';
import CandidateDashboard from './pages/CandidateDashboard.jsx';
import CandidateProfileEdit from './pages/CandidateProfileEdit.jsx';
import CandidateLogin from './pages/CandidateLogin.jsx';
import ResetPassword from './pages/ResetPassword.jsx';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/hr/*" element={<HRPortal />}>
          <Route path="dashboard" element={<HRDashBoard />} />
          <Route path="candidatereports" element={<CandidateReports />} />
          <Route path="jobvacancies" element={<JobVacancies />} />
          <Route path="hreditprofile" element={<HREditProfile />} />
          <Route path="checkresume" element={<CheckResume />} />
          <Route path="BulkResume" element={<BulkResumeUpload />} />
          <Route path="BulkResume/Bulkresumemodel/:upload_id" element={<BulkResumeSummary/>} />
          <Route path="evaluationpage/:interview_id" element={<EvaluationPortal />} />
      </Route>

      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<NotFound />} />

        {/* Landing Page Routes  */}
        <Route path="/" element={<AdminLogin />} />
        <Route path="/role-redirect" element={<RoleRedirect />} />

        {/* GenieHire Admin Routes  */}
        
        <Route path="/geniehireadmin/*" element={<GenieHirePortal/>} >
          <Route path="dashboard" element={<GenieHireDashboard />}/>
          <Route path="feedback" element={<Feedback />}/>
        </Route>

        {/* Organization Admin Routes  */}
        <Route path="/org-admin/*" element={<OrgAdminPortal />}>
          <Route path="dashboard" element={<OrgAdminDashBoard />} />
          <Route path="hrprofiles" element={<HrProfile />} />
          <Route path="candidatereports" element={<OrgAdminCandidateReports />} />
        <Route path="checkresume" element={<CheckResumeOrgAdmin />} />
        <Route path="candidateanalytics" element={<CandidateAnalytics />} />
        <Route path="created-jds" element={<CreatedJDs />} />
        <Route path="createaijd" element={<CreateAIJD />} />
          <Route path="jobvacancies" element={<JobVacancies />} />
          <Route path="checkresume" element={<CheckResume />} />
          <Route path="BulkResume" element={<BulkResumeUpload />} />
          <Route path="BulkResume/Bulkresumemodel/:upload_id" element={<BulkResumeSummary/>} />
          <Route path="editprofile" element={<OrgAdminEditProfile />} />
          <Route path="fetchmcq" element={<Fetch />} />
          <Route path="evaluationpage/:interview_id" element={<EvatuationReportOrgAdmin />} />
          <Route path="createmcqset" element={<CreateMCQSet />} />
          <Route path='createcodingproblemset' element={<CreateCodingProblemSet />} />
          <Route path="candidatelist" element={<CandidateList />} />


        </Route>

        <Route path="/candidate/*" element={<CandidatePortal />}>
        <Route path=":interview_id/scheduletest" element={<ScheduleTest />} />
          <Route path=":interview_id/testhomepage" element={<TestHomePage />} />
          <Route path=":interview_id/mcqinstructions" element={<MCQInstructions />} />
          <Route path="mcqtest/:interview_id" element={<MCQTest />} />
          <Route path=":interview_id/codinginstructions" element={<CodingInstructions />} />
          <Route path="codingtest/:interview_id" element={<CodingProblemsPage />} />
          <Route path=":interview_id/interviewinstructions" element={<InterviewInstructions />} />
          <Route path="interview/:interview_id" element={<InterviewPortal />} />
        </Route>
        {/* User Routes  */}
        <Route path="/thankyou/:interview_id" element={<ThankYou />} />
        <Route path="/jobinterestsurvey/:job_id/:candidate_id" element={<JobInterestSurvey />} />
        <Route path="/candidate-login" element={<CandidateLogin />}></Route>
        <Route path="/candidate-dashboard" element={<CandidateDashboard/>}></Route>
        <Route path="/candidate-edit-profile" element={<CandidateProfileEdit/>}></Route>
        <Route path="/candidate-reset-password" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

export default App;