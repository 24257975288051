import React, { useState, useEffect } from 'react';
import host from '../../global';
import Cookies from 'js-cookie';
import axios from 'axios';
import {toast } from 'react-toastify';
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { BriefcaseBusiness,UserRoundPlus,ChartNoAxesCombined,LibraryBig } from 'lucide-react';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);  
  const [jobPositions, setJobPositions] = useState([]);
  const accessToken = Cookies.get('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setJobPositions(Object.values(response.data));
        setIsLoading(false); 
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            Cookies.remove('accessToken');
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error('An unexpected error occurred. Please try again.');
        }
      }
    };
    fetchJobPositions();
  }, [accessToken]);

  const Card = ({ icon, text, onClick }) => {
    return (
      <div 
        className="border border-gray-300 p-4 rounded-2xl w-full max-w-[250px] h-[160px] flex flex-col items-center justify-center cursor-pointer 
        transition-all duration-300 hover:scale-105 hover:shadow-lg hover:border-blue-300 backdrop-blur-lg bg-white/30 border border-white/20"
        onClick={onClick}
      >
        <div className="text-4xl text-black text-center">{icon}</div>
        <p className="mt-4 text-lg text-black text-center">{text}</p>
      </div>
    );
  };

  const JobVacancyCard = ({ icon, text, posting_date, experience, dateIcon, experienceIcon }) => {
    return (
        <div 
          className="border border-gray-300 rounded-2xl w-full max-w-[190px] h-[75px] flex gap-2 items-center justify-center 
            transition-all duration-300 hover:scale-105 hover:shadow-md hover:border-blue-300 cursor-pointer"
            onClick={() => navigate('/org-admin/created-jds')}
        >
          <div className="text-2xl text-gray-600">{icon}</div>
          <div className="flex flex-col gap-1">
            <div className="relative w-full">
              <div 
                className="text-gray-600 text-sm font-semibold 
                w-full max-w-[140px] overflow-hidden whitespace-nowrap text-ellipsis"
                title={text} 
              >
                {text}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex gap-[2px] justify-center items-center">
                <div className="text-gray-600 text-sm">{dateIcon}</div>
                <div className="text-gray-600 text-[12px]">{posting_date}</div>
              </div>
              <div className="flex gap-1 justify-center items-center">
                <div className="text-gray-600 text-sm">{experienceIcon}</div>
                <div className="text-gray-600 text-[12px]">{experience}</div>
              </div>
            </div>
          </div>
        </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb]">
    <div className="p-8 responsive-container ">
      <div className="flex flex-wrap gap-10 justify-start mb-10">
        <Card icon={<BriefcaseBusiness size={32}/>} text="Create Job Vacancy" onClick={() => navigate(`/org-admin/createaijd`)}/>
        <Card icon={<UserRoundPlus size={32}/>} text="Add HR" onClick={() => navigate(`/org-admin/hrprofiles`)}/>
        <Card icon={<ChartNoAxesCombined size={32} />} text="Candidate Analytics" onClick={() => navigate(`/org-admin/candidateanalytics`)} />
      </div>
      
      {/* Job Vacancy Library */}
      <div className="mt-10 flex flex-col gap-4 bg-gray-100 p-10 w-[100%] justify-center rounded-2xl backdrop-blur-md bg-white/30 border border-white/20">
        <h3 className="font-semibold text-gray-600 text-lg">Job Vacancy Library</h3>
        <div className="flex flex-wrap gap-4">
          {isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="border border-gray-300 rounded-2xl w-full max-w-[190px] h-[75px] flex gap-2 items-center justify-center bg-gray-200 animate-pulse p-1">
                <div className="w-10 h-10 bg-gray-300 rounded-md"></div>
                <div className="flex flex-col gap-2">
                  <div className="h-4 w-32 bg-gray-300 rounded"></div>
                  <div className="flex gap-2">
                    <div className="h-3 w-16 bg-gray-300 rounded"></div>
                    <div className="h-3 w-16 bg-gray-300 rounded"></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            jobPositions.slice(0, 10).map((job, index) => (
              <JobVacancyCard
                key={index}                       
                icon={<LibraryBig size={26} />}
                text={job.job_role
                  .toLowerCase() 
                  .replace(/\b\w/g, (char) => char.toUpperCase())
                }
                posting_date={job.posting_date}
                experience={job.experience_required + " Y"}
                dateIcon={<CiCalendarDate />}
                experienceIcon={<IoBagRemoveOutline />}
              />
            ))
          )}
        </div>
        {!isLoading  && (
          <div className="mt-4 text-center">
            <button 
              className="px-4 py-2 text-xs text-gray-600 border-2 rounded-xl transition-all duration-300"
              onClick={() => navigate('/org-admin/created-jds')}
            >
              View More
            </button>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Dashboard;