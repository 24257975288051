import { ArrowLeft, Download, Trash2, CheckCircle } from 'lucide-react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
} from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { SquareMousePointer } from 'lucide-react';
import {useLocation, useNavigate, useParams } from "react-router-dom";
import MCQReport from "../Reports/MCQResult";
import { useState, useEffect} from "react";
import axios from "axios";
import host from "../../global";
import CodingTestReport from "../Reports/CodingResult";
import InterviewEvaluationReport from "../Reports/InterviewResult";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import PDFGenerator from "../Reports/PrintReport";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'
import Loader from '../Loader';

export default function CandidateAssessment() {
  const { interview_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState({
    mcq: false,
    coding: false,
    interview: false,
  });
  const toggleModal = (modal) => {
    setOpenModal((prev) => ({ ...prev, [modal]: !prev[modal] }));
  };
  const [candidateData, setCandidateData] = useState({
    mcq_status: null,
    coding_status: null,
    name: "",
    job_role: "",
    invited_date: null,
  });
  const [stages, setStages] = useState([]);
  const [completedStages, setCompleteStages] = useState(0);
  const [scores, setScores] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const accessToken = Cookies.get('accessToken');
  const navigate = useNavigate();
  const location = useLocation();
  const { candidateanalytics,selectedJobPosition, page, job_position, combined_status } = location.state || {};

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${host}/deletecandidate_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          interview_id: interview_id,
        }
      });
      toast.success(response.data.message);
      setTimeout(() => {
        navigate(`/org-admin/candidatereports`, {
          state: {
            page,
            job_position,
            combined_status,
          },
        });      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
        } else {
            toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
    }
    }
  };

  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        setCandidateData(response.data);
          const fetchedStages = [
            { name: "Invited for Test", status: "Complete" },
            { name: "MCQ", status: response.data.mcq_status },
            { name: "Coding", status: response.data.coding_status },
            { name: "AI Interview", status: response.data.interview_status },
            { name: "Test Complete", status: "Incomplete" },
          ];
          const validStages = fetchedStages.filter(
            (stage) =>
              stage.status !== "NA" &&
              stage.status !== undefined &&
              stage.status !== null
          );
          const allOthersComplete = validStages
          .filter(stage => stage.name !== 'Test Complete')
          .every(stage => stage.status === 'Complete' || stage.status === 'Passed');
            if (allOthersComplete) {
          const testCompleteStage = validStages.find(stage => stage.name === 'Test Complete');
          if (testCompleteStage) {
            testCompleteStage.status = 'Complete';
          }
        }
          setStages(validStages)
          console.log(validStages)
          const completedStages = validStages.reduce((acc, stage) => {
            return acc + (stage.status !== "Incomplete" ? 1 : 0);
          }, 0);
          const adjustedCompletedStages =
            completedStages === validStages.length - 1
              ? completedStages + 1
              : completedStages;
          setCompleteStages(adjustedCompletedStages);
          setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    const fetchScores = async () => {
      try {
        const response = await axios.get(
          `${host}/get_testscores`, 
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          });
          console.log(response)
        const formattedScores = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .reduce((acc, key) => {
            const value = parseFloat(response.data[key]);
            acc[key] = value.toFixed(2);
            return acc;
          }, {});

        setScores(formattedScores);
        const validValues = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .map((key) => parseFloat(response.data[key]));

        const total = validValues.reduce((acc, value) => acc + value, 0);
        const average = validValues.length > 0 ? total / validValues.length : 0;

        setAverageScore(average);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    fetchCandidateData();
    fetchScores();
  }, [interview_id,accessToken]);

  const handleNavigate = () => {
    if (candidateanalytics) {
      navigate("/org-admin/candidateanalytics", {
        state: {
          selectedJobPosition,
        },
      });
    } else {
      navigate("/org-admin/candidatereports", {
        state: {
          page,
          job_position,
          combined_status,
        },
      });
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme, statuses, index }) => {
    const status = statuses[index];
    return {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient(95deg, rgb(34 197 94), rgb(34 197 94), rgb(34 197 94))',
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: (status === 'Complete' || status === 'Passed') ? 'blue' : 'gray',
        borderRadius: 1,
      },
    };
  });

  if (isLoading) {
    return (
    <Loader/>
  )}

  return (
    <div className='min-h-screen bg-gradient-to-br from-[#bce9ff] to-[#ffe7eb] p-4'>
<div className="flex justify-between items-center mb-6">
  <div className="flex items-center gap-2 sm:gap-1">
    <button 
      className="no-print p-2"
      onClick={() => navigate(`/org-admin/candidatereports`, {
        state: {
          page,
          job_position,
          combined_status,
        },
      })}
    >
      <ArrowLeft />
    </button>
    <h1 className="text-base md:text-2xl lg:text-3xl font-bold font-poppins">
      {candidateData.name}
    </h1>
    <div className="border-l h-6 mx-2 border-gray-400"></div>
    <div className="flex flex-col">
      <p className="text-sm md:text-base text-gray-500 font-poppins">Job Role</p>
      <p className="text-base md:text-xl font-bold text-black font-poppins">
        {candidateData.job_role}
      </p>
    </div>
    {candidateData.invited_date && (
      <>
        <div className="border-l h-6 mx-2 border-gray-400"></div>
        <div className="flex flex-col">
          <p className="text-sm md:text-base text-gray-500 font-poppins">Invited Date</p>
          <p className="text-base md:text-xl font-bold text-black font-poppins">
            {candidateData.invited_date}
          </p>
        </div>
      </>
    )}
  </div>
  <div className="flex-col items-center gap-2 md:flex-col lg:flex-col">
  <button 
    className="p-2"
    onClick={() => toggleModal("PDF")}
  >
    <Download />
  </button>
  {openModal.PDF && (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-[100]">
      <div className="bg-white w-full max-w-4xl p-4 rounded-lg">
        <button 
          onClick={() => toggleModal("PDF")}
        >
          <ArrowLeft />
        </button>
        <PDFGenerator
          name={candidateData.name}
          role={candidateData.job_role}
          scores={scores}
          stages={stages}
          interview_id={interview_id}
        />
      </div>
    </div>
  )}
  <button className="p-2" onClick={handleDelete}>
    <Trash2 />
  </button>
</div>
</div>
      <div className='mb-8'>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector statuses={completedStages} />}>
            {stages.map((stage, index) => (
              <Step key={stage.name} completed={stage.status === 'Complete' || stage.status === 'Passed'}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <VerifiedRoundedIcon style={{ color: (stage.status === 'Complete' || stage.status === 'Passed') ? 'rgb(34 197 94)' : 'gray' }} {...props} />
                  )}
                >
                  {stage.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <Card sx={{border: "solid 1px #bce9ff",backgroundColor: 'rgb(255,255,255,0.3)' }}>
            <CardHeader title="Test Score & Reports" sx={{ fontFamily: "Poppins" }} />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                <Card sx={{backgroundColor: 'rgb(255,255,255,0.5)',height:'100%'}}>
                    <CardContent>
                      <Typography style={{ fontFamily: "Poppins" }} color="black" mb={2}>
                        Total Average Score
                      </Typography>
                      <p className="custom-h3">
                        {averageScore.toFixed(2)}%
                      </p>
                    </CardContent>
                  </Card>
                </Grid>
                {candidateData.mcq_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card sx={{backgroundColor: 'rgb(255,255,255,0.5)',height:'100%',
                        cursor: (candidateData.mcq_status !== "Complete" || candidateData.mcq_status !== "Passed")? "not-allowed" : "pointer",
                        opacity: (candidateData.mcq_status !== "Complete" || candidateData.mcq_status !== "Passed") ? 0.5 : 1,}}>
                      <CardContent sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                      <Box mb={2}
                        onClick={() => {
                          if (candidateData.mcq_status === "Complete" || candidateData.mcq_status === "Passed") {
                            toggleModal("mcq");
                          }
                        }}>
                          <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                            Multiple Choice Questions
                          </Typography>
                          <p className="custom-h3">
                            {scores.mcq_percent ? (
                              <>
                                <span>{`${scores.mcq_percent}%`}</span>
                              </>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog open={openModal.mcq} onClose={() => toggleModal("mcq")} fullWidth maxWidth="md">
                          <DialogTitle>
                            <IconButton onClick={() => toggleModal("mcq")} edge="start" color="inherit" className="no-print">
                              <ArrowLeft />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <MCQReport name={candidateData.name} mcq_percent={scores.mcq_percent} />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {candidateData.coding_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card sx={{backgroundColor: 'rgb(255,255,255,0.5)',height:'100%',
                        cursor: candidateData.coding_status !== "Complete" ? "not-allowed" : "pointer",
                        opacity: candidateData.coding_status !== "Complete" ? 0.5 : 1,}}>
                      <CardContent sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                        <Box mb={2}
                        onClick={() => {
                          if (candidateData.coding_status === "Complete") {
                            toggleModal("coding");
                          }
                        }}>
                          <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                            Coding Test
                          </Typography>
                          <p className="custom-h3">
                            {scores.coding_percent > 0 ? (
                              `${scores.coding_percent}%`
                            ) : candidateData.coding_status === "Complete" ? (
                              <span className="text-[20px] text-green-700">Completed</span>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog open={openModal.coding} onClose={() => toggleModal("coding")} fullWidth maxWidth="md">
                          <DialogTitle>
                            <IconButton onClick={() => toggleModal("coding")} edge="start" color="inherit" className="no-print">
                              <ArrowLeft />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <CodingTestReport name={candidateData.name} coding_percent={scores.coding_percent} />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {candidateData.interview_status !== "NA" && (
                  <Grid item xs={6}>
                    <Card sx={{backgroundColor: 'rgb(255,255,255,0.5)',height:'100%',
                      cursor: candidateData.interview_status !== "Complete" ? "not-allowed" : "pointer",
                      opacity: candidateData.interview_status !== "Complete" ? 0.5 : 1,}}>
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box
                          mb={2}
                          onClick={() => {
                            if (candidateData.interview_status === "Complete") {
                              toggleModal("interview");
                            }
                          }}
                        >
                          <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                            AI Interview
                          </Typography>
                          <p className="custom-h3">
                            {scores.interview_percent ? (
                              <>
                                <span>{`${scores.interview_percent}%`}</span>
                              </>
                            ) : (
                              "NA"
                            )}
                          </p>
                        </Box>
                        <Dialog open={openModal.interview} onClose={() => toggleModal("interview")} fullWidth maxWidth="md">
                          <DialogTitle>
                            <IconButton onClick={() => toggleModal("interview")} edge="start" color="inherit" className="no-print">
                              <ArrowLeft />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <InterviewEvaluationReport name={candidateData.name} interview_percent={scores.interview_percent} />
                          </DialogContent>
                        </Dialog>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <Card sx={{border: "solid 1px #bce9ff",backgroundColor: 'rgb(255,255,255,0.3)' }}>
            <CardHeader
              title="Honesty Monitor"
              sx={{ fontFamily: "Poppins" }}
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Avatar style={{ width: "100px", height: "100px",background:'white',color:'black' }}>{candidateData.name[0]}</Avatar>
            </CardContent>
            <CardContent style={{ fontFamily: "Poppins" }}>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Device Used
                </Typography>
                <Typography>Desktop</Typography>
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Webcam Enabled?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Full Screen mode always active?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Mouse always in Test Window?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Filled out only once from IP address
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}